import { useEffect, useState } from 'react'
import { SystemSetting } from 'types/SystemSetting'
import {
    InputNumericFormRow,
    InputSelectFormRow,
    InputTextFormRow,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'

interface Props {
    items: SystemSetting[]
    onChange?: (e: FormControlChangeEvent) => void
    testId?: string
}
const Settings = ({ items, onChange, testId }: Props) => {
    const [state, setState] = useState<SystemSetting[]>([])
    useEffect(() => {
        setState(items)
    }, [items])

    function handlInputChange(name: string, value: string) {
        setState(
            state.map((element) => {
                if (element.name === name) {
                    element.value = value
                    const event: FormControlChangeEvent = {
                        target: { name: element.name, value: element.value },
                    }
                    onChange?.(event)
                    return element
                }
                return element
            }),
        )
    }
    const displayItem = (item: SystemSetting) => {
        let result = <></>
        switch (item.settingDefinitionType) {
            case 'Text':
                result = (
                    <InputTextFormRow
                        value={item.value}
                        required={item.required}
                        subText={item.description}
                        labelText={item.displayName}
                        fieldId={item.name}
                        key={item.name}
                        onChange={(e) => handlInputChange(item.name, e.target.value)}
                    />
                )
                break
            case 'Number':
                result = (
                    <InputNumericFormRow
                        value={Number(item.value)}
                        subText={item.description}
                        labelText={item.displayName}
                        fieldId={item.name}
                        key={item.name}
                        onChange={(e) =>
                            handlInputChange(item.name, e.target.value === null ? '0' : e.target.value.toString())
                        }
                    />
                )
                break
            case 'Boolean':
                result = (
                    <SwitchFormRow
                        value={item.value.toLocaleLowerCase() === 'true'}
                        subText={item.description}
                        labelText={item.displayName}
                        fieldId={item.name}
                        key={item.name}
                        onChange={(e) => handlInputChange(item.name, e.value.toString())}
                    />
                )
                break
            case 'Select':
                result = (
                    <InputSelectFormRow
                        value={item.value}
                        subText={item.description}
                        options={item.options!}
                        labelText={item.displayName}
                        key={item.name}
                        fieldId={item.name}
                        onChange={(e) => handlInputChange(item.name, e.target.value)}
                    />
                )
                break
            default:
                break
        }
        return result
    }
    return (
        <>
            {state.map((item) => (
                <div key={item.name} data-testid={testId ? testId + item.name : undefined}>
                    {displayItem(item)}
                </div>
            ))}
        </>
    )
}

export default Settings
