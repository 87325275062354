import { FormEvent, useState } from 'react'
import globals from 'services/global/globals'
import { UserEditable, defaultUserEditable } from 'types/interfaces'
import {
    FormControlChangeEvent,
    updateObjectWithFormEvent,
    OperationMode,
} from 'views/Common/Form/FormControls/FormControlBase'
import { handleApiError } from 'services/utilities/toastrUtils'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputSelectFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'

interface UserDetailProps {
    setEditUser: (user: UserEditable) => void
    onCloseComponent: (result: DialogResultEnum, updatedUser: UserEditable) => void
    user: UserEditable
    operationMode: OperationMode
    userCustomFields: string
}

const UserDetails = (props: UserDetailProps) => {
    const [validatedForm, setValidatedForm] = useState(false)
    const { setEditUser, onCloseComponent, userCustomFields } = props
    const [user, setUser] = useState(props.user)
    const [userModified, setUserModified] = useState(false)
    const api = globals.getApi()

    const onCancelClick = () => {
        setEditUser(defaultUserEditable)
        onCloseComponent(DialogResultEnum.Cancelled, defaultUserEditable)
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        // Custom validations

        if (invalid) {
            return
        }

        try {
            const updatedUser = user.id === 0 ? await api.createUser(user) : await api.updateUser(user)
            props.onCloseComponent(DialogResultEnum.Completed, updatedUser)
        } catch (err: any) {
            setValidatedForm(false)
            handleApiError(err)
            invalid = true
        }
    }

    const updateUser = (e: FormControlChangeEvent) => {
        setUser(updateObjectWithFormEvent(user, e))
        setUserModified(true)
    }

    // sample userCustomFields: "field1:value1,field2:value2,field3:value3"
    const customFields = userCustomFields.split(',').map((value) => value.split(':')[1]) ?? []

    const title = `${props.operationMode} User`
    const footer = <FormFooter disabledSave={!userModified} onCancel={() => onCancelClick()} />

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            <FormSection title="">
                <InputTextFormRow
                    labelText="Full Name"
                    subText="The common first and last names"
                    invalidText="Enter the name of the User"
                    value={user.name}
                    fieldId="name"
                    validated={validatedForm}
                    onChange={updateUser}
                    required
                />

                <InputTextFormRow
                    labelText="Email Address"
                    subText="The unique email address"
                    invalidText="Enter the email address"
                    value={user.loginId}
                    fieldId="loginId"
                    validated={validatedForm}
                    onChange={updateUser}
                    required
                />

                <InputSelectFormRow
                    labelText="Role"
                    value={user.isAdministrator ? 'Admin' : 'General'}
                    subText="The role of the User"
                    options={['Admin', 'General']}
                    fieldId="isAdministrator"
                    onChange={(e: FormControlChangeEvent) => {
                        const evt = { target: { name: 'isAdministrator', value: e.target.value === 'Admin' } }
                        updateUser(evt)
                    }}
                />

                {customFields[0] && (
                    <InputTextFormRow
                        labelText={customFields[0]}
                        value={user.field1}
                        fieldId="field1"
                        onChange={updateUser}
                    />
                )}

                {customFields[1] && (
                    <InputTextFormRow
                        labelText={customFields[1]}
                        value={user.field2}
                        fieldId="field2"
                        onChange={updateUser}
                    />
                )}

                {customFields[2] && (
                    <InputTextFormRow
                        labelText={customFields[2]}
                        value={user.field3}
                        fieldId="field3"
                        onChange={updateUser}
                    />
                )}
            </FormSection>
        </FormPage>
    )
}

export default UserDetails
