import { GridItemChangeEvent } from '@progress/kendo-react-grid'
import { useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { PriorWorkTypeNames } from 'types/EnumTypes'
import { KendoGridColumn } from 'types/GridLayout'
import { SleepNapRule } from 'types/ProfileInterfaces'
import { getDefaultSleepNapRule } from 'types/ProfileInterfacesDefaults'
import FormFooter from 'views/Common/Form/FormFooter'
import InformationDialog from 'views/Common/GenericDialogs/InformationDialog'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import {
    getCustomCellRenderer,
    getCustomRowRenderer,
    getEditableGridDropdownCellRenderer,
    getItemEditedHandler,
} from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

interface NapRulesDialogProps {
    onClose: () => void
}
const NapRulesDialog = (props: NapRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileAutoSleep)
    const [state, setState] = useState<SleepNapRule[]>(profile?.napRules ?? [])
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    // operations
    const addNewRecord = (existRecord?: SleepNapRule) => {
        const newRecord = getDefaultSleepNapRule(existRecord, state)
        setState([...state, newRecord])
        setSelectedRowsState({ [newRecord.id]: true })
    }
    function handleCopyClick(): void {
        if (selectedIds.length !== 1) return

        const item = state.find((i) => i.id === selectedIds[0])
        if (item) {
            addNewRecord(item)
        }
    }
    const handleDeleteClick = () => {
        setShowDialog(deleteDialog)
    }

    function changeOrder(direction: MoveDirection) {
        setState(reorderItemsList(selectedIds, state, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'
    const getColumns = useMemo((): KendoGridColumn[] => {
        return [
            { title: 'Prior Rest Min', field: 'priorRestMin', editor: 'numeric' },
            { title: 'Prior Rest Max', field: 'priorRestMax', editor: 'numeric' },
            { title: 'Nap Duration', field: 'napDuration', editor: 'numeric' },
            {
                title: 'Prior to Work Type',
                field: 'priorWorkType',
                cell: (c) =>
                    getEditableGridDropdownCellRenderer(
                        EDIT_FIELD,
                        state,
                        setState,
                        setSelectedRowsState,
                    )(c, PriorWorkTypeNames),
            },
        ]
    }, [state])

    const isInvalidEditInPlace = (event: GridItemChangeEvent) => {
        const field = event.field || ''
        return (
            ['priorRestMin', 'priorRestMax', 'napDuration'].indexOf(field) >= 0 && event.value !== -1 && event.value < 1
        )
    }

    const headerContent = 'Workload Rules'

    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={state}
                                selectedIds={selectedIds}
                                onAddClick={addNewRecord}
                                onCopyClick={handleCopyClick}
                                onDeleteClick={handleDeleteClick}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={state}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={getCustomCellRenderer(EDIT_FIELD, state, setState, setSelectedRowsState)}
                        rowRender={getCustomRowRenderer(EDIT_FIELD, state, setState)}
                        onItemChange={getItemEditedHandler(state, setState, isInvalidEditInPlace)}
                    />
                </Col>
            </Row>
        </Container>
    )

    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Nap Rule"
                setTempItems={setState}
                tempItems={state}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, state],
    )

    if (!profile) return <></>

    const footerContent = (
        <FormFooter
            onCancel={props.onClose}
            onOk={() => {
                // validate and save
                const notValidItem = state.find((x) => x.priorRestMax <= x.priorRestMin && x.priorRestMax !== -1)
                if (notValidItem) {
                    const newList = state.map((item) => ({
                        ...item,
                        [EDIT_FIELD]: item.id === notValidItem.id ? 'priorRestMax' : undefined,
                    }))
                    setState(newList)
                    showMessage('Warning', 'Prior Rest Max must greater than Prior Rest Min')
                } else {
                    const updated = { ...profile, napRules: state }
                    dispatch(profileActions.setProfileByType({ profile: updated, type: 'Sleep', isModified: true }))
                    props.onClose()
                }
            }}
        />
    )

    // dialogs
    const showMessage = (header: string, message: string) => {
        setShowDialog(
            <InformationDialog
                headerText={header}
                message={message}
                onClose={() => {
                    setShowDialog(<></>)
                }}
            />,
        )
    }

    return (
        <>
            <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                {gridContent}
            </DialogLayout>
            {showDialog}
        </>
    )
}

export default NapRulesDialog
