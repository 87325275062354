import { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { getItemCountLabel } from 'services/utilities/stringUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { AutoSleepModeTypeNames, getRecordKeyValues, SleepQualityTypeNames } from 'types/EnumTypes'
import {
    FormControlChangeEvent,
    OperationMode,
    updateObjectWithFormEvent,
} from 'views/Common/Form/FormControls/FormControlBase'
import {
    DrillDownFormRow,
    InputNumericFormRow,
    InputSelectFormRow,
    InputTimePickerFormRow,
    MultiColumnFormRow,
    SwitchFormRow,
} from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import ProfileCommonFields from '../ProfileCommonFields'
import NapRulesDialog from './NapRulesDialog'
import PWSEventRulesDialog from './PWSEventRulesDialog'
import PWSSleepRulesDialog from './PWSSleepRulesDialog'
import SleepQualityRulesDialog from './SleepQualityRulesDialog'

interface AutoSleepParametersProps {
    validatedForm: boolean
    operationMode: OperationMode
    enableCommonFields: boolean
}

const AutoSleepParametersPanel = (props: AutoSleepParametersProps) => {
    const [showSleepQualityDialog, setShowSleepQualityDialog] = useState(false)
    const [showNapRulesDialog, setShowNapRulesDialog] = useState(false)
    const [showPWSSleepDialog, setShowPWSSleepDialog] = useState(false)
    const [showPWSEventsDialog, setShowPWSEventsDialog] = useState(false)
    const profile = useAppSelector((state) => state.profile.profileAutoSleep)
    const dispatch = useAppDispatch()
    if (!profile) return null

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'Sleep', isModified: true }))
    }

    const sleepQualityMultiColumn = {
        labelText: 'Sleep Quality',
        subText: 'The quality of sleep by location',
        labelId: 'sleepQuality',
        columns: [
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: profile.sleepQualityHome,
                    onChange,
                    id: 'sleepQualityHome',
                    options: getRecordKeyValues(SleepQualityTypeNames),
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: profile.sleepQualityHotel,
                    onChange,
                    id: 'sleepQualityHotel',
                    options: getRecordKeyValues(SleepQualityTypeNames),
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: profile.sleepQualityRestFacility,
                    onChange,
                    id: 'sleepQualityRestFacility',
                    options: getRecordKeyValues(SleepQualityTypeNames),
                },
            },
        ],
    }

    const awakeZoneMultiColumn = {
        labelText: 'Awake Zone',
        subText: 'Prevent sleep during this period',
        labelId: 'awakeZone',
        labelColWidth: 5,
        columns: [
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: profile.awakeZoneStart,
                    id: 'awakeZoneStart',
                    name: 'awakeZoneStart',
                    onChange,
                },
            },
            {
                colWidth: 1,
                value: 'to',
            },
            {
                colWidth: 3,
                colType: 'timePickerInput',
                timePickerProps: {
                    value: profile.awakeZoneEnd,
                    id: 'awakeZoneEnd',
                    name: 'awakeZoneEnd',
                    onChange,
                },
            },
        ],
    }

    const explicitSleepBufferMultiColumn = {
        labelText: 'Explicit Sleep Buffer',
        subText: 'The duration to exclude auto-sleep from around explicit sleep events',
        labelId: 'explicitSleepBuffer',
        columns: [
            {
                colWidth: 1,
                colType: 'text',
                value: 'Before',
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: profile.explicitSleepBufferBefore,
                    onChange,
                    isInvalid: props.validatedForm,
                    id: 'explicitSleepBufferBefore',
                    minValue: 0,
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                    step: 0.1,
                },
            },
            {
                colWidth: 1,
                colType: 'text',
                value: 'After',
            },
            {
                colType: 'numericInput',
                inputNumericProps: {
                    value: profile.explicitSleepBufferAfter,
                    onChange,
                    isInvalid: props.validatedForm,
                    id: 'explicitSleepBufferAfter',
                    invalidText: 'Enter a value of 0 or greater',
                    required: true,
                    step: 0.1,
                },
            },
        ],
    }

    let eventRulesLabel = getItemCountLabel(profile.eventRules, 'Rule')
    if (profile.sleepRules.length === 0) {
        eventRulesLabel = `${eventRulesLabel} (Sleep Rules must be added first)`
    }
    return (
        <>
            {props.enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields
                        nameField={profile.name}
                        descriptionField={profile.description}
                        onChange={onChange}
                    />
                </FormSection>
            )}

            <FormSection title={props.enableCommonFields ? 'Parameters' : null}>
                <InputTimePickerFormRow
                    labelText="Normal Bedtime"
                    subText="Start time of daily sleep"
                    value={profile.normalBedtime}
                    onChange={onChange}
                    fieldId="normalBedtime"
                />

                <InputNumericFormRow
                    labelText="Minimum sleep duration"
                    subText="The shortest sleep event to add"
                    value={profile.minSleepDuration}
                    onChange={onChange}
                    fieldId="minSleepDuration"
                    minValue={0}
                    required={true}
                    invalidText="Enter a value of 0 or greater"
                />

                <MultiColumnFormRow {...awakeZoneMultiColumn} />

                <Form.Group>
                    <Row>
                        <Col xs="5"> </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>Home</h6>
                        </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>Hotel</h6>
                        </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>Rest Facility</h6>
                        </Col>
                    </Row>
                </Form.Group>
                <MultiColumnFormRow {...sleepQualityMultiColumn} />

                <DrillDownFormRow
                    labelText="Sleep Quality Override Rules"
                    subText="Define sleep event timing and duration"
                    data={profile.sleepQualityRules}
                    operationMode={props.operationMode}
                    onClick={() => setShowSleepQualityDialog(true)}
                    value={getItemCountLabel(profile.sleepQualityRules, 'Rule')}
                />

                <InputSelectFormRow
                    labelText="Transmeridian Sleep Mode"
                    subText="How to adjust sleep timing when travelling across timezones"
                    value={profile.transmeridianSleepMode}
                    onChange={onChange}
                    fieldId="transmeridianSleepMode"
                    options={getRecordKeyValues(AutoSleepModeTypeNames)}
                />

                <MultiColumnFormRow {...explicitSleepBufferMultiColumn} />

                <InputNumericFormRow
                    labelText="Explicit Sleep Buffer Maximum"
                    subText="The maximum duration of an explicit sleep buffer"
                    value={profile.explicitSleepBufferMax}
                    onChange={onChange}
                    fieldId="explicitSleepBufferMax"
                    minValue={0}
                    step={1}
                    required={true}
                    invalidText="Enter a value of 0 or greater"
                />
                <SwitchFormRow
                    labelText="Regular Sleep"
                    subText="Daily sleep events starting at Normal Bedtime"
                    value={profile.regularSleepEnable}
                    onChange={onChange}
                    fieldId="regularSleepEnable"
                />

                {profile.regularSleepEnable && (
                    <IndentedBlock headingText="">
                        <InputNumericFormRow
                            labelText="Maximum Work Day Sleep Duration"
                            subText="The maximum duration of sleep on a work day"
                            onChange={onChange}
                            minValue={0}
                            value={profile.maxWorkDaySleep}
                            fieldId="maxWorkDaySleep"
                            invalidText="Enter a value of 0 or greater"
                            required={true}
                        />

                        <InputNumericFormRow
                            labelText="Maximum Rest Day Sleep Duration"
                            subText="The maximum duration of sleep on a rest day"
                            onChange={onChange}
                            minValue={0}
                            value={profile.maxRestDaySleep}
                            fieldId="maxRestDaySleep"
                            invalidText="Enter a value of 0 or greater"
                            required={true}
                        />

                        <SwitchFormRow
                            labelText="Advanced Bedtime Enable"
                            subText="Apply an early bedtime when necessary"
                            value={profile.advancedBedtimeEnable}
                            onChange={onChange}
                            fieldId="advancedBedtimeEnable"
                        />
                    </IndentedBlock>
                )}

                <SwitchFormRow
                    labelText="Post-work Sleep (Recovery)"
                    subText="Sleep events that immediately follow duties"
                    value={profile.postWorkSleepEnable}
                    onChange={onChange}
                    fieldId="postWorkSleepEnable"
                />

                {profile.postWorkSleepEnable && (
                    <IndentedBlock headingText="">
                        <InputNumericFormRow
                            labelText="Maximum Recovery Sleep"
                            subText="The total duration of sleep on a work day"
                            onChange={onChange}
                            minValue={0}
                            value={profile.maxRecoverySleep}
                            fieldId="maxRecoverySleep"
                            invalidText="Enter a value of 0 or greater"
                            required={true}
                        />
                    </IndentedBlock>
                )}

                <SwitchFormRow
                    labelText="Pre-work Sleep (Nap)"
                    subText="Enable sleep events prior to duties"
                    value={profile.preWorkSleepEnable}
                    onChange={onChange}
                    fieldId="preWorkSleepEnable"
                />

                {profile.preWorkSleepEnable && (
                    <IndentedBlock headingText="">
                        <DrillDownFormRow
                            labelText="Nap Rules"
                            subText="Define nap requirements and durations"
                            data={profile.napRules}
                            operationMode={props.operationMode}
                            onClick={() => setShowNapRulesDialog(true)}
                            value={getItemCountLabel(profile.napRules, 'Rule')}
                        />
                        <InputSelectFormRow
                            labelText="Nap Sleep Quality"
                            subText="The quality of nap sleep"
                            value={profile.napSleepQuality}
                            onChange={onChange}
                            fieldId="napSleepQuality"
                            options={getRecordKeyValues(SleepQualityTypeNames)}
                        />
                    </IndentedBlock>
                )}

                <SwitchFormRow
                    labelText="Planned Work Sleep"
                    subText="Enable sleep events during work events"
                    value={profile.plannedWorkSleepEnable}
                    onChange={onChange}
                    fieldId="plannedWorkSleepEnable"
                />

                {profile.plannedWorkSleepEnable && (
                    <IndentedBlock headingText="">
                        <DrillDownFormRow
                            labelText="Sleep Rules"
                            subText="Define sleep event timing and duration"
                            data={profile.sleepRules}
                            operationMode={props.operationMode}
                            onClick={() => setShowPWSSleepDialog(true)}
                            value={getItemCountLabel(profile.sleepRules, 'Rule')}
                        />

                        <DrillDownFormRow
                            labelText="Event Rules"
                            subText="Define work event requirements"
                            data={profile.eventRules}
                            operationMode={props.operationMode}
                            onClick={() => setShowPWSEventsDialog(true)}
                            value={eventRulesLabel}
                        />
                    </IndentedBlock>
                )}
            </FormSection>

            {showSleepQualityDialog && (
                <SleepQualityRulesDialog profileId={profile.id} onClose={() => setShowSleepQualityDialog(false)} />
            )}
            {showNapRulesDialog && <NapRulesDialog onClose={() => setShowNapRulesDialog(false)} />}
            {showPWSSleepDialog && (
                <PWSSleepRulesDialog profile={profile} onClose={() => setShowPWSSleepDialog(false)} />
            )}
            {showPWSEventsDialog && (
                <PWSEventRulesDialog profile={profile} onClose={() => setShowPWSEventsDialog(false)} />
            )}
        </>
    )
}

export default AutoSleepParametersPanel
