interface FormSectionProps {
    title?: any
    padding?: number
    children: React.ReactNode
}

const FormSection = (props: FormSectionProps) => {
    return (
        <>
            {props.title != null && <h5 style={{ marginTop: '10px' }}>{props.title}</h5>}
            <div
                style={{
                    backgroundColor: '#FAFAFA',
                    padding: props.padding !== undefined ? `${props.padding}px` : '15px',
                }}
            >
                {props.children}
            </div>
        </>
    )
}

export default FormSection
