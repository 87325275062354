import { Grid, GridDataStateChangeEvent, GridPageChangeEvent } from '@progress/kendo-react-grid'
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs'
import useGridLayout from 'hooks/useGridLayout'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { getColumnsFromGridLayout } from 'services/utilities/kendoGridColumnsHelpers'
import { getFilterFields } from 'services/utilities/kendoGridUtils'
import { handleApiError } from 'services/utilities/toastrUtils'
import gridLayoutActions from 'store/actions/gridLayoutActions'
import { RootState, useAppDispatch } from 'store/store'
import { Station, StationEditable } from 'types/interfaces'
import MetaData from 'types/Metadata'
import { PagedSearchRequest } from 'types/PagedSearchRequest'
import EllipsisDropdown, { EllipsisDropdownItem, ItemWithIcon } from 'views/Common/Buttons/EllipsisDropdown'
import IconButton from 'views/Common/Buttons/IconButton'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import { OperationMode } from 'views/Common/Form/FormControls/FormControlBase'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import GridPageLayout from 'views/Common/Layout/PageLayout'
import StationDetails from '../StationDetails/StationDetails'
import DeleteStationDialog from './Dialogs/DeleteStationDialog'

type DialogMode = 'None' | 'ShowHideColumns' | 'Copy' | 'Delete'
const LocalStorageKeyForDataState = 'StationsGridDataState'

interface PageState {
    skip: number
    take: number
}

const initialDataState: PageState = { skip: 0, take: 100 }

const StationsPage = () => {
    const [editStation, setEditStation] = useState<StationEditable | undefined>(undefined)
    const [operationMode, setOperationMode] = useState<OperationMode>('Create')
    const [addEditStationComponent, setAddEditStationComponent] = useState(false)
    const [gridHeight, setGridHeight] = useState(100)
    const [showGridColumnPicker, setShowGridColumnPicker] = useState<boolean>(false)
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const [stations, setStations] = useState<Station[]>([])
    const [reloadDataFlag, setReloadDataFlag] = useState<Date | null>(null)
    const [dialogMode, setDialogMode] = useState<DialogMode>('None')
    const [totalItemsCount, setTotalItemsCount] = useState<number>(0)
    const [page, setPage] = useState<PageState>(initialDataState)
    const [activeGridLayout] = useGridLayout('stations')
    const enableFiltering = activeGridLayout?.configurationJson.filtersEnabled || false
    const filteredFields = getFilterFields(activeGridLayout?.configurationJson?.dataState?.filter?.filters || [])
    const getColumnsHelper = useCallback(
        () =>
            getColumnsFromGridLayout({
                gridLayout: activeGridLayout,
                stationsGridOptions: {
                    stationClicked: (station: Station) => {
                        setEditStation(station)
                        setAddEditStationComponent(true)
                        setOperationMode('Edit')
                    },
                },
            }),
        [activeGridLayout],
    )

    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(getColumnsHelper())
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds(selectedRowsState)
    const [pagedRequest, setPagedRequest] = useState<PagedSearchRequest>({
        skip: initialDataState.skip,
        take: initialDataState.take,
    })
    const [searchParameter, setSearchParameter] = useState('')
    const gridRef = useRef<Grid>(null)
    const dispatch = useAppDispatch()
    const api = globals.getApi()

    useEffect(() => {
        document.title = 'SAFTE-FAST - Stations'
    })

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await api.getStationsApi().search(pagedRequest)
                setStations(data[0])
                setTotalItemsCount(data[1])
            } catch (err: any) {
                handleApiError(err)
            }
        }
        loadData()
    }, [api, reloadDataFlag, pagedRequest])

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page)
        setPagedRequest((prev) => ({
            ...prev,
            skip: event.page.skip,
            take: event.page.take,
        }))
    }

    const handleDataStateChanged = (event: GridDataStateChangeEvent) => {
        const newSort = event.dataState.sort
        if (newSort) {
            setPagedRequest((prev) => ({
                ...prev,
                sorting: newSort && newSort.length ? `${newSort[0].field} ${newSort[0].dir}` : '',
                skip: initialDataState.skip,
                take: initialDataState.take,
            }))
            setPage(initialDataState)
        }
    }
    // When change search parameter, reset the page to initial
    // Update Search Parameter will triger webapi call
    // This search logic could be moved to any event we would like to triger search
    function handleSearch(paramter?: string) {
        setPagedRequest((prev) => ({
            ...prev,
            searchParameter: paramter ?? '',
            skip: initialDataState.skip,
            take: initialDataState.take,
        }))
        setPage(initialDataState)
    }
    const handleSearchParameterChange = (event: InputChangeEvent) => {
        setSearchParameter(event.value)
        handleSearch(event.value)
    }

    useEffect(() => {
        const columns = getColumnsHelper()
        setStateColumns(columns)
        document.title = 'SAFTE-FAST - Stations'
    }, [setStateColumns, metadata, enableFiltering, getColumnsHelper])

    const copyStation = () => {
        const stn = stations.find((x) => x.id === Number(selectedIds[0]))
        if (stn) {
            const codeICAO = stn.codeICAO === '' ? '' : `${stn.codeICAO} - Copy`
            const codeIATA = stn.codeIATA === '' ? '' : `${stn.codeIATA} - Copy`
            const stationCopy: StationEditable = {
                id: 0,
                codeICAO,
                codeIATA,
                codeAliases: stn.codeAliases,
                cityName: stn.cityName,
                codeCountry: stn.codeCountry,
                codeRegion: stn.codeRegion,
                latitude: stn.latitude || 0,
                longitude: stn.longitude || 0,
            }
            return stationCopy
        }
        return undefined
    }
    const stationsGrid = stations && (
        <KendoGridCustom
            gridRef={gridRef}
            gridLayoutName="stations"
            localStorageKeyForGridDataState={LocalStorageKeyForDataState}
            height={`${gridHeight}px`}
            resizable={true}
            onPageChange={pageChange}
            data={stations}
            dataState={activeGridLayout?.configurationJson?.dataState}
            columnMenuFiltering={enableFiltering}
            columns={stateColumns}
            selectedRowsState={selectedRowsState}
            onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
            setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
            pageSize={initialDataState.take}
            skip={page.skip}
            take={page.take}
            total={totalItemsCount}
            showColumnPicker={showGridColumnPicker}
            onColumnPickerHide={() => setShowGridColumnPicker(false)}
            onDataStateChanged={handleDataStateChanged}
        />
    )

    const toolbarButtons = (
        <>
            <Input
                value={searchParameter}
                // value={filterValue}
                onChange={handleSearchParameterChange}
                // onChange={onFilterChange}
                style={{
                    border: '2px solid #ccc',
                    boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)',
                    margin: '0em 75em -2.5em 0em',
                    width: '30%',
                }}
                placeholder="Search"
            />
            <IconButton
                tooltip="Add Station"
                onClick={() => {
                    setEditStation({
                        id: 0,
                        codeICAO: '',
                        codeIATA: '',
                        codeAliases: '',
                        cityName: '',
                        codeCountry: '',
                        codeRegion: '',
                        latitude: 0,
                        longitude: 0,
                    })
                    setOperationMode('Create')
                    setAddEditStationComponent(true)
                }}
                icon="bi-file-plus"
                toolbarLeftMargin
            />
            <IconButton
                tooltip="Copy Station"
                disabled={selectedIds.length !== 1}
                onClick={() => {
                    setOperationMode('Copy')
                    setEditStation(copyStation())
                    setAddEditStationComponent(true)
                }}
                style={{ fontSize: '20px' }}
                icon="bi-files"
                toolbarLeftMargin
            />

            <IconButton
                tooltip="Delete Station(s)"
                disabled={selectedIds.length === 0}
                onClick={() => setDialogMode('Delete')}
                style={{ fontSize: '20px' }}
                icon="bi-trash"
                toolbarLeftMargin
            />

            <SeperatorVertical />
            <IconButtonColumns onClick={() => setShowGridColumnPicker(true)} />

            <IconButtonFilter
                filterEnabled={enableFiltering}
                filterIsActive={enableFiltering && filteredFields.length > 0}
                onClick={() => dispatch(gridLayoutActions.setGridFilteringEnabled('stations', !enableFiltering))}
            />

            <EllipsisDropdown>
                <EllipsisDropdownItem
                    onClick={() => {
                        setEditStation({
                            id: 0,
                            codeICAO: '',
                            codeIATA: '',
                            codeAliases: '',
                            cityName: '',
                            codeCountry: '',
                            codeRegion: '',
                            latitude: 0,
                            longitude: 0,
                        })
                        setOperationMode('Create')
                        setAddEditStationComponent(true)
                    }}
                >
                    <ItemWithIcon bootstrapIconClass="bi-file-plus">Add</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    tooltip="Copy Station"
                    disabled={selectedIds.length !== 1}
                    onClick={() => {
                        setOperationMode('Copy')
                        setEditStation(copyStation())
                        setAddEditStationComponent(true)
                    }}
                >
                    <ItemWithIcon bootstrapIconClass="bi-files">Copy</ItemWithIcon>
                </EllipsisDropdownItem>
                <EllipsisDropdownItem
                    tooltip="Delete Station(s)"
                    disabled={selectedIds.length === 0}
                    onClick={() => setDialogMode('Delete')}
                >
                    <ItemWithIcon bootstrapIconClass="bi-trash">Delete</ItemWithIcon>
                </EllipsisDropdownItem>

                <Dropdown.Divider />

                <EllipsisDropdownItem onClick={() => setShowGridColumnPicker(true)}>
                    <ItemWithIcon bootstrapIconClass="bi-layout-three-columns">Show/Hide Columns</ItemWithIcon>
                </EllipsisDropdownItem>

                <EllipsisDropdownItem
                    onClick={() => dispatch(gridLayoutActions.setGridFilteringEnabled('stations', !enableFiltering))}
                >
                    <ItemWithIcon bootstrapIconClass="bi-funnel">
                        {enableFiltering ? 'Disable Column Filters' : 'Enable Column Filters'}
                    </ItemWithIcon>
                </EllipsisDropdownItem>
            </EllipsisDropdown>
        </>
    )

    return (
        <>
            {addEditStationComponent ? (
                <StationDetails
                    setEditStation={setEditStation}
                    station={editStation}
                    operationMode={operationMode}
                    onCloseComponent={(result: DialogResultEnum) => {
                        if (result === DialogResultEnum.Completed) {
                            setEditStation(undefined)
                            let successMessage
                            switch (operationMode) {
                                case 'Edit':
                                    successMessage = 'The station has been edited'
                                    break
                                case 'Copy':
                                    successMessage = 'The station has been copied'
                                    break
                                default:
                                    successMessage = 'The station has been created'
                                    break
                            }
                            toast.success(successMessage, {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                            setReloadDataFlag(new Date())
                        }
                        setAddEditStationComponent(false)
                    }}
                />
            ) : (
                <>
                    <GridPageLayout
                        headingContent="Stations"
                        buttons={toolbarButtons}
                        onMainContentHeightChange={setGridHeight}
                    >
                        {stationsGrid}
                    </GridPageLayout>

                    {dialogMode === 'Delete' && selectedIds.length > 0 && (
                        <DeleteStationDialog
                            stationIds={selectedIds}
                            onCloseDialog={(result: DialogResultEnum) => {
                                if (result === DialogResultEnum.Completed) {
                                    const successMessage = `${selectedIds.length} station${
                                        selectedIds.length === 1 ? '' : 's'
                                    } deleted`

                                    toast.success(successMessage, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    })

                                    // clear the grid selection
                                    setSelectedRowsState((previous) => {
                                        const updated = { ...previous }
                                        selectedIds.forEach((id) => {
                                            updated[id] = false
                                        })
                                        return updated
                                    })

                                    setReloadDataFlag(new Date())
                                }
                                setDialogMode('None')
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default StationsPage
