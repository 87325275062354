import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import getDescriptionFromDashboardConfig from 'types/ReportDashboardConfig'
import { ScenarioDetail } from 'types/Scenario'
import { ScenarioSelection } from 'types/ScenarioSelection'
import { EllipsisDropdownHeader, EllipsisDropdownItem, IndentedDropdownItem } from './EllipsisDropdown'
import classes from './ScenarioSelectorDropdown.module.css'

const singleScenarioIsSelected = (scenarioId: number, dashboardConfig: ScenarioSelection) =>
    dashboardConfig.includeAllMyScenarios === false &&
    dashboardConfig.includeAllScenariosSharedwMe === false &&
    dashboardConfig.selectedScenarioIds === scenarioId.toString()

const allMyScenariosSelected = (dashboardConfig: ScenarioSelection) =>
    dashboardConfig.includeAllMyScenarios === true && dashboardConfig.includeAllScenariosSharedwMe !== true

const ScenarioSelectorDropdown = (props: {
    disabled?: boolean
    dashboardConfig: ScenarioSelection
    recentScenarios: ScenarioDetail[]
    showMoreClicked: () => void
    onSelectionUpdated: (selection: ScenarioSelection) => void
}) => {
    const selectionText = getDescriptionFromDashboardConfig(props.dashboardConfig)
    const otherIsSelected =
        allMyScenariosSelected(props.dashboardConfig) === false &&
        props.recentScenarios.some((scenario) => singleScenarioIsSelected(scenario.id, props.dashboardConfig)) === false

    return (
        <span title="Select Scenario(s) for this Dashboard" style={{ marginLeft: 6 }}>
            <DropdownButton
                as={ButtonGroup}
                alignRight
                size="sm"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                }}
                title={selectionText}
                variant="white"
                className={classes.dropdown}
                style={{ borderRadius: 4, height: 30 }}
                disabled={props.disabled}
            >
                <EllipsisDropdownItem
                    onClick={() => {
                        const selection: ScenarioSelection = {
                            includeAllMyScenarios: true,
                            includeAllScenariosSharedwMe: false,
                            selectedScenarioIds: '',
                        }
                        props.onSelectionUpdated(selection)
                    }}
                    checked={allMyScenariosSelected(props.dashboardConfig)}
                >
                    All Scenarios
                </EllipsisDropdownItem>
                <Dropdown.Divider />
                <EllipsisDropdownHeader>Recent Scenarios</EllipsisDropdownHeader>
                <>
                    {props.recentScenarios.map((scenario) => {
                        return (
                            <EllipsisDropdownItem
                                key={scenario.id.toString()}
                                onClick={() => {
                                    const selection: ScenarioSelection = {
                                        includeAllMyScenarios: false,
                                        includeAllScenariosSharedwMe: false,
                                        selectedScenarioIds: scenario.id.toString(),
                                        selectedScenarioName: scenario.name,
                                    }
                                    props.onSelectionUpdated(selection)
                                }}
                            >
                                <IndentedDropdownItem
                                    checked={singleScenarioIsSelected(scenario.id, props.dashboardConfig)}
                                >
                                    {scenario.name}
                                </IndentedDropdownItem>
                            </EllipsisDropdownItem>
                        )
                    })}
                </>
                <Dropdown.Divider />
                <EllipsisDropdownItem onClick={props.showMoreClicked} checked={otherIsSelected}>
                    More...
                </EllipsisDropdownItem>
            </DropdownButton>
        </span>
    )
}

export default ScenarioSelectorDropdown
