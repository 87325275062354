import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import { ProfileModuleTypes } from 'types/EnumTypes'
import IconButton from 'views/Common/Buttons/IconButton'
import { getProfileTitleByType, Profile } from 'types/ProfileInterfaces'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import PageLayout from 'views/Common/Layout/PageLayout'
import DeleteConfirmationDialog from '../Components/DeleteConfirmationDialog'
import GridToolbarButton from '../Components/GridToolbarButton'
import getProfileListGridColumns from './ProfileGridConfig'

interface Props {
    displayName: string
    profileType: ProfileModuleTypes
}

const ProfileList = ({ displayName, profileType }: Props) => {
    const navigate = useNavigate()
    const [configHeight, setConfigHeight] = React.useState(780)
    const [selectedRowsState, setSelectedRowsState] = React.useState<SelectionState>({})
    const [showDialog, setShowDialog] = React.useState(<></>)
    const [gridData, setGridData] = React.useState<Profile[]>([])
    const profileApi = useCallback(() => globals.getApi().getProfileApi(), [])
    const baseUrl = useMemo(() => `/settings/profiles/${profileType}`, [profileType])
    const latestRequestIdRef = useRef<number | null>(null)
    useEffect(() => {
        document.title = 'SAFTE-FAST - Profiles - ' + getProfileTitleByType(profileType)
    })

    useEffect(() => {
        const requestId = Date.now() // Unique ID for the current request
        latestRequestIdRef.current = requestId

        const getDataAndSetLinks = async () => {
            try {
                const data: Profile[] = await profileApi().getProfileList(profileType)

                if (requestId === latestRequestIdRef.current) {
                    setGridData(data)
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }
        getDataAndSetLinks()

        // Cleanup function to invalidate previous requests on dependency change
        return () => {
            latestRequestIdRef.current = null
        }
    }, [profileApi, profileType])

    const handleAddClick = () => {
        navigate(`${baseUrl}/new`)
    }
    const deleteData = async () => {
        await profileApi().deleteProfile(profileType, selectedIds)
        const data = await profileApi().getProfileList(profileType)
        setGridData(data)
        setSelectedRowsState({})
    }

    const refresh = async () => {
        try {
            const data: Profile[] = await profileApi().getProfileList(profileType)
            setGridData(data)
        } catch (err: any) {
            handleApiError(err)
        }
        setSelectedRowsState({})
    }

    const handleCopyClick = () => {
        navigate(`${baseUrl}/${selectedIds[0]}/copy`)
    }

    const handleDeleteClick = () => {
        setShowDialog(
            <DeleteConfirmationDialog
                topic={displayName}
                numRows={selectedIds.length}
                onCloseDialog={async (dialogResult: DialogResultEnum) => {
                    if (dialogResult === DialogResultEnum.Completed) {
                        await deleteData()
                    }
                    setShowDialog(<></>)
                }}
            />,
        )
    }

    const handleSetDefaultClick = async () => {
        if (selectedIds.length === 1) {
            const selectedProfile = gridData.find((i) => i.id === selectedIds[0])
            if (selectedProfile && !selectedProfile.isDefault) {
                selectedProfile.isDefault = true
                await profileApi().setDefaultProfile(selectedProfile.id, profileType)
                toast.success(selectedProfile.name + ` is now the default ${profileType} profile`)
                await refresh()
            }
        }
    }

    const selectedIds = getSelectedIds<string>(selectedRowsState)

    return (
        <>
            <PageLayout
                headingContent={`Profiles - ${displayName}`}
                buttons={
                    <>
                        <IconButton
                            tooltip="Set Default"
                            aria-label="set-default-template"
                            toolbarLeftMargin
                            icon="bi-check-square-fill"
                            disabled={selectedIds.length !== 1}
                            onClick={handleSetDefaultClick}
                        />
                        <GridToolbarButton
                            items={gridData}
                            selectedIds={selectedIds}
                            onAddClick={handleAddClick}
                            onCopyClick={handleCopyClick}
                            onDeleteClick={handleDeleteClick}
                        />
                    </>
                }
                onMainContentHeightChange={setConfigHeight}
            >
                <KendoGridCustom
                    localStorageKeyForColumnState="ProfilesGrid"
                    localStorageKeyForGridDataState="ProfilesGridDataState"
                    height={`${configHeight}px`}
                    data={gridData}
                    columns={getProfileListGridColumns(baseUrl)}
                    setColumnVisibility={(newColumnState: KendoGridColumn[]) => newColumnState}
                    selectedRowsState={selectedRowsState}
                    onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                />
            </PageLayout>
            {showDialog}
        </>
    )
}

export default ProfileList
