/* eslint-disable no-case-declarations */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileModuleTypes } from 'types/EnumTypes'
import {
    Profile,
    ProfileAutoMarker,
    ProfileAutoSleep,
    ProfileDataSource,
    ProfileMetricsConfiguration,
    ProfileWork,
    ProfileWorkload,
} from 'types/ProfileInterfaces'
import TemplateDetail from 'types/TemplateDetail'

type ProfileDataSourceState = {
    profileDataSource: ProfileDataSource
    modified: boolean
}

export type ProfileStoreState = {
    profileWork: ProfileWork | null
    profileWorkModified: boolean
    profileAutoSleep: ProfileAutoSleep | null
    profileAutoSleepModified: boolean
    profileMetricsConfigurations: ProfileMetricsConfiguration | null
    profileMetricsConfigurationsModified: boolean
    profileWorkload: ProfileWorkload | null
    profileWorkloadModified: boolean
    profileAutoMarkers: ProfileAutoMarker | null
    profileAutoMarkersModified: boolean
    profileDataSources: ProfileDataSourceState[]
    template: TemplateDetail | null
    templateModified: boolean
    scenarioMode: boolean
}

const initialProfileState: ProfileStoreState = {
    profileWork: null,
    profileWorkModified: false,
    profileAutoSleep: null,
    profileAutoSleepModified: false,
    profileMetricsConfigurations: null,
    profileMetricsConfigurationsModified: false,
    profileWorkload: null,
    profileWorkloadModified: false,
    profileAutoMarkers: null,
    profileAutoMarkersModified: false,
    profileDataSources: [],
    template: null,
    templateModified: false,
    scenarioMode: false,
}

const profileReducer = createSlice({
    name: 'profile',
    initialState: initialProfileState,
    reducers: {
        clearProfilesAndTemplate(state) {
            state.profileWork = null
            state.profileWorkModified = false

            state.profileAutoSleep = null
            state.profileAutoSleepModified = false

            state.profileMetricsConfigurations = null
            state.profileMetricsConfigurationsModified = false

            state.profileWorkload = null
            state.profileWorkloadModified = false

            state.profileAutoMarkers = null
            state.profileAutoMarkersModified = false

            state.profileDataSources = []

            state.template = null
            state.templateModified = false
        },

        setProfileByType(
            state,
            action: PayloadAction<{ type: ProfileModuleTypes; profile: Profile | null; isModified?: boolean }>,
        ) {
            let newProfile = action.payload.profile
            const isModified = action.payload.isModified ?? false

            // If the profile does not already have a library id and is modified, create a new profile object
            if (state.template && isModified && newProfile && !newProfile.libraryId) {
                newProfile = {
                    ...newProfile,
                    libraryId: newProfile.id,
                }
                // Set template library id
                assignTemplateLibraryId(state.scenarioMode, state.template, isModified)
            }

            // Update state based on profile type
            switch (action.payload.type) {
                case 'Work':
                    state.profileWork = newProfile as ProfileWork
                    state.profileWorkModified = isModified
                    break
                case 'Sleep':
                    state.profileAutoSleep = newProfile as ProfileAutoSleep
                    state.profileAutoSleepModified = isModified
                    break
                case 'Metrics':
                    state.profileMetricsConfigurations = newProfile as ProfileMetricsConfiguration
                    state.profileMetricsConfigurationsModified = isModified
                    break
                case 'Workload':
                    state.profileWorkload = newProfile as ProfileWorkload
                    state.profileWorkloadModified = isModified
                    break
                case 'Markers':
                    state.profileAutoMarkers = newProfile as ProfileAutoMarker
                    state.profileAutoMarkersModified = isModified
                    break
                case 'DataSource':
                    const profileDatasource = newProfile as ProfileDataSource
                    const index = state.profileDataSources.findIndex(
                        (i) => i.profileDataSource.id === profileDatasource.id,
                    )
                    if (index >= 0) {
                        state.profileDataSources[index] = {
                            profileDataSource: profileDatasource,
                            modified: isModified,
                        }
                    } else {
                        state.profileDataSources.push({ profileDataSource: profileDatasource, modified: false })
                    }
                    break
                default:
                    break
            }

            // Only update the modified flag if there's a template
            if (state.template && isModified) {
                state.templateModified = isModified
            }
        },

        setTemplate(state, action: PayloadAction<{ template: TemplateDetail | null; modified?: boolean }>) {
            if (!action.payload.template) {
                state.template = null
                return
            }

            assignTemplateLibraryId(state.scenarioMode, action.payload.template, action.payload.modified)

            state.template = action.payload.template
            state.templateModified = action.payload.modified ?? false
        },
        addNewDataSourceToTemplate(state, action: PayloadAction<ProfileDataSource>) {
            if (!state.template) {
                throw new Error('Template is not set')
            }

            assignTemplateLibraryId(state.scenarioMode, state.template, true)

            const newDataSource = action.payload
            state.profileDataSources.push({ profileDataSource: newDataSource, modified: false })
            if (!state.template.profileDataSourceIds) {
                state.template.profileDataSourceIds = []
            }
            state.template.profileDataSourceIds.push(newDataSource.id)
            state.templateModified = true
        },
        removeDataSourceFromTemplate(state, action: PayloadAction<string>) {
            if (!state.template) {
                throw new Error('Template is not set')
            }

            assignTemplateLibraryId(state.scenarioMode, state.template, true)

            const id = action.payload
            const index = state.profileDataSources.findIndex((i) => i.profileDataSource.id === id)
            if (index < 0) {
                throw new Error('Profile Data Source not found')
            }
            state.profileDataSources.splice(index, 1)
            state.template.profileDataSourceIds = state.template.profileDataSourceIds!.filter((i) => i !== id)
            state.templateModified = true
        },
        setScenarioMode(state, action: PayloadAction<boolean>) {
            state.scenarioMode = action.payload
        },
    },
})

function assignTemplateLibraryId(scenarioMode: boolean, template: TemplateDetail | null, modified?: boolean) {
    if (scenarioMode && template && modified && !template.libraryId) {
        template.libraryId = template.id
    }
}

export const profileActions = profileReducer.actions

export default profileReducer.reducer
