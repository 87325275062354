import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle } from '@progress/kendo-react-charts'
import { GridDetailRowProps } from '@progress/kendo-react-grid'
import { ScenarioDetail } from 'types/Scenario'

const ScenariosGridPieCharts = (props: GridDetailRowProps) => {
    const { hasCrewingData, hasCriticalData, allPieGraphData } = props.dataItem as ScenarioDetail

    if (!hasCrewingData) {
        return <div>Scenario contains no crewing time</div>
    }

    if (!hasCriticalData) {
        return <div>Scenario contains no critical time</div>
    }

    return (
        <>
            {allPieGraphData?.map((pieData, i) => {
                return (
                    <Chart key={i} style={{ display: 'inline-block', height: 250, width: 300 }}>
                        <ChartArea background="transparent" width={350} />
                        <ChartTitle
                            text={pieData[0].chartTitle + '\n&nbsp;\n&nbsp;'}
                            font="14px 'Segoe UI'"
                            position="top"
                        />
                        <ChartLegend visible={false} />
                        <ChartSeries>
                            <ChartSeriesItem
                                startAngle={90}
                                type="pie"
                                data={pieData}
                                field="value"
                                categoryField="category"
                                labels={{
                                    content: (e) => `${e.category} (${e.value})`,
                                    visible: true,
                                    font: "12px 'Segoe UI'",
                                    position: 'outsideEnd',
                                }}
                            />
                        </ChartSeries>
                    </Chart>
                )
            })}
        </>
    )
}

export default ScenariosGridPieCharts
