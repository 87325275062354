import React from 'react'
import { fetchAndSetTemplateFromId } from 'store/actions/templateActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import TemplateProfileExpansionPanel from './TemplateProfileExpansionPanel'
import TemplateSelectorTool from './TemplateSelectorTool'

interface Props {
    onChange: (e: FormControlChangeEvent) => void
    children: React.ReactNode
}

const TemplateExpansionPanel = ({ children, onChange }: Props) => {
    const dispatch = useAppDispatch()
    const template = useAppSelector((rs) => rs.profile.template)

    const custom = Boolean(template && template.libraryId)

    // todo: onSave
    const onSave = () => {}

    const onReset = () => {
        if (template && template.libraryId) {
            dispatch(fetchAndSetTemplateFromId(template.libraryId!))
        }
    }

    const selectorTool = (
        <TemplateSelectorTool
            onSave={onSave}
            onSelected={(selectedTemplateId) => {
                onChange({
                    target: { name: 'templateId', value: selectedTemplateId },
                })
                dispatch(fetchAndSetTemplateFromId(selectedTemplateId))
            }}
        />
    )

    return (
        <TemplateProfileExpansionPanel selectorTool={selectorTool} custom={custom} onSave={onSave} onReset={onReset}>
            {children}
        </TemplateProfileExpansionPanel>
    )
}

export default TemplateExpansionPanel
