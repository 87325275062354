import { GridCellProps } from '@progress/kendo-react-grid'
import { Profile } from 'types/ProfileInterfaces'
import { ColumnMenuWithFilter, KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell, getLink } from 'views/Common/Kendo/FormattedNameCell'

const getProfileListGridColumns = (baseUrl: string): KendoGridColumn[] => [
    {
        field: 'name',
        title: 'Name',
        filter: 'text',
        columnMenu: ColumnMenuWithFilter,
        cell: (props: GridCellProps) => {
            const profile = props.dataItem as Profile
            const url = `${baseUrl}/${profile.id}`
            const cellContent: JSX.Element = (
                <>
                    {getLink(url, profile.name)}
                    {profile.isDefault && (
                        <i className="grid-button bi-check-square-fill" style={{ marginLeft: '8px' }} />
                    )}
                </>
            )
            return FormattedNameCell(props, cellContent)
        },
    },
    {
        field: 'templatesUsing',
        title: 'Templates Using',
        filter: 'numeric',
        columnMenu: ColumnMenuWithFilter,
    },
    {
        field: 'scenariosUsing',
        title: 'Scenarios Using',
        filter: 'numeric',
        columnMenu: ColumnMenuWithFilter,
    },
    {
        field: 'owner',
        title: 'Owner',
        filter: 'text',
        columnMenu: ColumnMenuWithFilter,
    },
]

export default getProfileListGridColumns
