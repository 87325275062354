import useSettingValue from 'hooks/useSettingValue'
import { FormEvent, memo, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import Schedule from 'types/Schedule'
import { SettingConsts } from 'types/SystemSetting'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

export type CopyScheduleDialogProps = {
    schedule: Schedule
    closeCallback: (dialogResult: DialogResultEnum) => void
}

const CopyScheduleDialogContent = (props: CopyScheduleDialogProps) => {
    const api = globals.getApi()
    const [scheduleName, setScheduleName] = useState(`${props.schedule.name}-copy`)
    const [validated, setValidated] = useState(false)
    const scheduleNameCaption = useSettingValue(SettingConsts.general.account_Schedule_Name)
    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        if (form.checkValidity() === false) {
            setValidated(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            await api.duplicateSchedule(
                props.schedule.id,
                false,
                scheduleName,
                props.schedule.scenarioId,
                props.schedule.scenarioName,
            )
            props.closeCallback(DialogResultEnum.Completed)
        } catch (err: any) {
            handleApiError(err)
        }
    }
    const scheduleNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleName(e.target.value)
    }

    return (
        <Form noValidate validated={validated} onSubmit={submitHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Copy Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Make a copy of <strong>{props.schedule.name}</strong>
                </p>
                <Form.Control
                    id="txtScheduleName"
                    name="scheduleName"
                    autoFocus
                    autoComplete="off"
                    type="text"
                    placeholder="Enter a name for the copied schedule"
                    value={scheduleName}
                    onChange={scheduleNameChangeHandler}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a name for the copied schedule
                </Form.Control.Feedback>
                {scheduleNameCaption && <Form.Text className="text-muted">{scheduleNameCaption}</Form.Text>}
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom isLarge type="submit" variant="primary">
                    Copy
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const CopyScheduleDialog = (props: CopyScheduleDialogProps) => {
    return (
        <ModalWrapper closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}>
            <CopyScheduleDialogContent {...props} />
        </ModalWrapper>
    )
}

export default memo(CopyScheduleDialog)
