import { Col, Form, Row } from 'react-bootstrap'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { CriticalTimeTypeNames, getRecordKeyValues, ZoneTypeNames } from 'types/EnumTypes'
import {
    buildEventFromNameAndValue,
    FormControlChangeEvent,
    updateObjectWithFormEvent,
} from 'views/Common/Form/FormControls/FormControlBase'
import { InputNumericFormRow, InputSelectFormRow, MultiColumnFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import IndentedBlock from 'views/Common/Layout/IndentedBlock'
import ProfileCommonFields from '../ProfileCommonFields'

interface MetricsConfigParametersProps {
    validatedForm: boolean
    enableCommonFields: boolean
}

const ProfileMetricsConfigurationDetailsPanel = ({ enableCommonFields }: MetricsConfigParametersProps) => {
    const profile = useAppSelector((state) => state.profile.profileMetricsConfigurations)
    const dispatch = useAppDispatch()
    if (!profile) return null

    const {
        name,
        description,
        effectivenessCriterionLevel,
        belowCriterionThreshold,
        criticalTimeDetermination,
        dutyDurationBrief,
        dutyDurationDebrief,
        wocl,
        night,
        redeye,
        daySleep,
        earlyStart,
        lateEnd,
    } = profile

    const onChange = (e: FormControlChangeEvent) => {
        const updated = updateObjectWithFormEvent(profile, e)
        dispatch(profileActions.setProfileByType({ profile: updated, type: 'Metrics', isModified: true }))
    }

    const handleTimeRangeChange = (e: FormControlChangeEvent, property: keyof typeof profile) => {
        onChange(buildEventFromNameAndValue(property, updateObjectWithFormEvent(profile[property], e)))
    }

    const handleWoclTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'wocl')
    }

    const handleNightTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'night')
    }

    const handleRedeyeTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'redeye')
    }

    const handleDaySleepTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'daySleep')
    }

    const handleEarlyStartTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'earlyStart')
    }

    const handleLateEndTimeRangeChange = (e: FormControlChangeEvent) => {
        handleTimeRangeChange(e, 'lateEnd')
    }

    const dutyDurationMultiColumn = {
        labelText: 'Duty Duration',
        subText: 'Which events to include in the Duty Duration metric',
        labelId: 'dutyDuration',
        invalidText: '',
        columns: [
            {
                colType: 'text',
                value: 'Brief',
            },
            {
                colType: 'switchInput',
                switchProps: {
                    value: dutyDurationBrief,
                    onChange,
                    id: 'dutyDurationBrief',
                },
            },
            {
                colType: 'text',
                value: 'Debrief',
            },
            {
                colType: 'switchInput',
                switchProps: {
                    value: dutyDurationDebrief,
                    onChange,
                    id: 'dutyDurationDebrief',
                },
            },
        ],
    }

    const woclTimeRangeMultiColumn = {
        labelText: 'WOCL',
        subText: 'Window of Circadian Low',
        labelId: 'wocl',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: wocl.start,
                    onChange: handleWoclTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: wocl.end,
                    onChange: handleWoclTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: wocl.timeReference,
                    onChange: handleWoclTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }

    const nightTimeRangeMultiColumn = {
        labelText: 'Night',
        subText: 'Time considered as Night',
        labelId: 'night',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: night.start,
                    onChange: handleNightTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: night.end,
                    onChange: handleNightTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: night.timeReference,
                    onChange: handleNightTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }

    const redeyeTimeRangeMultiColumn = {
        labelText: 'Redeye',
        subText: 'Time considered as Redeye',
        labelId: 'redeye',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: redeye.start,
                    onChange: handleRedeyeTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: redeye.end,
                    onChange: handleRedeyeTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: redeye.timeReference,
                    onChange: handleRedeyeTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }

    const daySleepTimeRangeMultiColumn = {
        labelText: 'Day Sleep',
        subText: 'Time considered as Day Sleeping',
        labelId: 'daySleep',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: daySleep.start,
                    onChange: handleDaySleepTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: daySleep.end,
                    onChange: handleDaySleepTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: daySleep.timeReference,
                    onChange: handleDaySleepTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }

    const earlyStartTimeRangeMultiColumn = {
        labelText: 'Early Start',
        subText: 'Time considered as Early Start',
        labelId: 'earlyStart',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: earlyStart.start,
                    onChange: handleEarlyStartTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: earlyStart.end,
                    onChange: handleEarlyStartTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: earlyStart.timeReference,
                    onChange: handleEarlyStartTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }

    const lateEndTimeRangeMultiColumn = {
        labelText: 'Late End',
        subText: 'Time considered as Late End',
        labelId: 'lateEnd',
        invalidText: '',
        columns: [
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: lateEnd.start,
                    onChange: handleLateEndTimeRangeChange,
                    id: 'start',
                },
            },
            {
                colType: 'timePickerInput',
                timePickerProps: {
                    value: lateEnd.end,
                    onChange: handleLateEndTimeRangeChange,
                    id: 'end',
                },
            },
            {
                colType: 'selectInput',
                inputSelectProps: {
                    value: lateEnd.timeReference,
                    onChange: handleLateEndTimeRangeChange,
                    id: 'timeReference',
                    options: getRecordKeyValues(ZoneTypeNames),
                },
            },
        ],
    }
    return (
        <>
            {enableCommonFields && (
                <FormSection title="Details">
                    <ProfileCommonFields nameField={name} descriptionField={description} onChange={onChange} />
                </FormSection>
            )}

            <FormSection title={enableCommonFields ? 'Parameters' : null}>
                <InputNumericFormRow
                    labelText="Effectiveness Criterion Level"
                    subText="The threshold for calculating Below Criterion metrics "
                    value={effectivenessCriterionLevel}
                    onChange={onChange}
                    fieldId="effectivenessCriterionLevel"
                    minValue={0}
                    maxValue={100}
                    invalidText="Enter a value of 0 or greater"
                />

                <InputNumericFormRow
                    labelText="% Below Criterion Threshold"
                    subText="The threshold for calculating Excessive %BC events"
                    value={belowCriterionThreshold}
                    onChange={onChange}
                    minValue={0}
                    maxValue={100}
                    fieldId="belowCriterionThreshold"
                    invalidText="Enter a value of 0 or greater"
                />

                <InputSelectFormRow
                    labelText="Critical Time Determination"
                    subText="How critical time is determined"
                    value={criticalTimeDetermination}
                    onChange={onChange}
                    fieldId="criticalTimeDetermination"
                    options={getRecordKeyValues(CriticalTimeTypeNames)}
                />

                <MultiColumnFormRow {...dutyDurationMultiColumn} />
                <br></br>
                <Form.Group>
                    <Row>
                        <Col xs="5">
                            <h6>Time Ranges</h6>
                        </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>Start</h6>
                        </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>End</h6>
                        </Col>
                        <Col>
                            <h6 style={{ textAlign: 'center' }}>Time Reference</h6>
                        </Col>
                    </Row>
                </Form.Group>

                <IndentedBlock headingText="">
                    <MultiColumnFormRow {...woclTimeRangeMultiColumn} />
                    <MultiColumnFormRow {...nightTimeRangeMultiColumn} />
                    <MultiColumnFormRow {...redeyeTimeRangeMultiColumn} />
                    <MultiColumnFormRow {...daySleepTimeRangeMultiColumn} />
                    <MultiColumnFormRow {...earlyStartTimeRangeMultiColumn} />
                    <MultiColumnFormRow {...lateEndTimeRangeMultiColumn} />
                </IndentedBlock>
            </FormSection>
        </>
    )
}

export default ProfileMetricsConfigurationDetailsPanel
