import FormSection from 'views/Common/Form/FormSection'
import ProfileAutoMarkersDetailsPanel from '../ParameterPanels/ProfileAutoMarkersDetails/ProfileAutoMarkersDetailsPanel'
import ProfileAutoSleepDetailsPanel from '../ParameterPanels/ProfileAutoSleepDetails/ProfileAutoSleepDetailsPanel'
import ProfileMetricsConfigurationDetailsPanel from '../ParameterPanels/ProfileMetricsConfigurationDetails/ProfileMetricsConfigurationDetailsPanel'
import ProfileWorkDetailsPanel from '../ParameterPanels/ProfileWorkDetails/ProfileWorkDetailsPanel'
import ProfileWorkloadDetailsPanel from '../ParameterPanels/ProfileWorkloadDetails/ProfileWorkloadDetailsPanel'
import ProfileExpansionPanel from './ProfileExpansionPanel'

const ProfilesSelectorPanel = () => {
    return (
        <>
            <FormSection title="Profiles" padding={0}>
                <ProfileExpansionPanel type="Work">
                    <ProfileWorkDetailsPanel enableCommonFields={false} validatedForm={true} operationMode="Edit" />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Sleep">
                    <ProfileAutoSleepDetailsPanel
                        enableCommonFields={false}
                        validatedForm={true}
                        operationMode="Edit"
                    />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Markers">
                    <ProfileAutoMarkersDetailsPanel enableCommonFields={false} operationMode="Edit" />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Metrics">
                    <ProfileMetricsConfigurationDetailsPanel enableCommonFields={false} validatedForm={true} />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Workload">
                    <ProfileWorkloadDetailsPanel enableCommonFields={false} operationMode="Edit" />
                </ProfileExpansionPanel>
            </FormSection>
        </>
    )
}

export default ProfilesSelectorPanel
