import Modal from 'react-bootstrap/Modal'
import { ScenarioSaveOptions } from 'types/Scenario'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'

interface Props {
    closeCallback: (dialogResult: DialogResultEnum, mode?: ScenarioSaveOptions) => void
}
const ScenarioSaveConfirmationDialog = ({ closeCallback }: Props) => {
    return (
        <ModalWrapper closeCallback={() => closeCallback(DialogResultEnum.Cancelled)} size="lg">
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Updating Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to save changes to this Scenario? Schedules will be reanalyzed.</p>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonCustom
                        isLarge
                        type="submit"
                        variant="primary"
                        onClick={() => closeCallback(DialogResultEnum.Completed, 'save')}
                    >
                        OK
                    </ButtonCustom>
                    <ButtonCustom isLarge variant="secondary" onClick={() => closeCallback(DialogResultEnum.Cancelled)}>
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </>
        </ModalWrapper>
    )
}

export default ScenarioSaveConfirmationDialog
