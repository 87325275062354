import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { ChangeEvent, FormEvent, memo, useContext, useState } from 'react'
import { Alert, Col, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import globals from 'services/global/globals'
import { showResponseMessage } from 'services/utilities/toastrUtils'
import { globalActions } from 'store/globalStore'
import ScheduleDetailsContext from 'store/scheduleDetailsContext'
import { RootState } from 'store/store'
import { SleepQualityEnum, TimeModeEnum } from 'types/interfaces'
import MetaData from 'types/Metadata'
import { PlannedWorkSleepRule } from 'types/Scenario'
import Schedule from 'types/Schedule'
import ScheduleEvent, { ScheduleEventRecord } from 'types/ScheduleEvent'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import simpleDialogClasses from 'views/Common/GenericDialogs/SimpleDialog.module.css'
import StationAutoSuggestInput from 'views/Common/Inputs/StationAutoSuggestInput'
import DateTimePickerCustom from 'views/Common/Kendo/DateTimePickerCustom'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import TagsTable from 'views/Common/Widget/TagsTable'

export interface EventDialogProps {
    scheduleEvent: ScheduleEvent
    allEventTagNames: string[]
    pwsRules: PlannedWorkSleepRule[]
    pwsEnabled: boolean
    timeMode: TimeModeEnum
    deleteCallback: () => void
    closeCallback: (status: DialogResultEnum, mode?: 'Add' | 'Edit', schedule?: Schedule) => void
}

const EventEditingDialogContent = (props: EventDialogProps) => {
    const dispatch = useDispatch()
    const scheduleDetailsCtx = useContext(ScheduleDetailsContext)

    const [validatedForm, setValidatedForm] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const scheduleEvent = props.scheduleEvent
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const api = globals.getApi()

    let defaultFormState: ScheduleEventRecord = {
        ...scheduleEvent,
    }

    if (defaultFormState.plannedWorkSleepOwner === 'System') {
        defaultFormState.plannedWorkSleepQuality = null
        defaultFormState.plannedWorkSleepRuleId = null
    }

    const previousFormState = scheduleDetailsCtx.getFormState()
    if (previousFormState) {
        defaultFormState = previousFormState
    }

    const [formValues, setFormValues] = useState<ScheduleEventRecord>(defaultFormState)
    const [startUtcOffsetMinutes, setStartUtcOffsetMinutes] = useState(
        scheduleEvent.getUtcOffset(props.timeMode, 'start'),
    )
    const [endUtcOffsetMinutes, setEndUtcOffsetMinutes] = useState(scheduleEvent.getUtcOffset(props.timeMode, 'end'))

    // calculate the "display" time for showing in the kendo input due to the user selected time mode (UTC, local, base)
    const startDisplay = dateTimeFormatting.getLocalizedTime(formValues.start.getTime(), startUtcOffsetMinutes)

    const endTimeUtc = formValues.start.getTime() + formValues.duration * 60000
    const endDisplay = dateTimeFormatting.getLocalizedTime(endTimeUtc, endUtcOffsetMinutes)

    // calculate pws rule
    const pwsRuleString =
        formValues.plannedWorkSleepOwner === 'System' ? 'Auto' : formValues.plannedWorkSleepRuleId?.toString() || 'None'

    const pwsQuality = formValues.plannedWorkSleepQuality
        ? SleepQualityEnum[formValues.plannedWorkSleepQuality].toString()
        : ''

    const pwsRuleIds: string[] = [...props.pwsRules.map((x) => x.id.toString())]
    pwsRuleIds.unshift('None')
    pwsRuleIds.unshift('Auto')

    const pwsVisible: boolean =
        props.pwsEnabled && (props.scheduleEvent.isAnyWork() || props.scheduleEvent.isDutyRollup())

    const showPwsQuality = formValues.plannedWorkSleepOwner !== 'System' && formValues.plannedWorkSleepRuleId !== null

    const areTagsInValid = () => {
        const anyNoNameTags = formValues.eventTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = formValues.eventTags.length !== new Set(formValues.eventTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    const isAutoEvent = scheduleEvent.isPreFlightAutoDutyEvent() || scheduleEvent.isPostFlightAutoDutyEvent()

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement

        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (areTagsInValid()) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            // keep the form open, let the user fix the issues
            return
        }

        if (form.checkValidity() === false) {
            setValidatedForm(true)
            // keep the form open, let the user fix the issues
            return
        }

        try {
            // the showing of loading modal causes a re-render, so save the form state first
            scheduleDetailsCtx.setFormState(formValues)
            dispatch(globalActions.showLoadingModal())

            const mode = props.scheduleEvent.id === 0 ? 'Add' : 'Edit'
            const [updatedSchedule, responseMessage] =
                mode === 'Add' ? await api.addEvent(formValues) : await api.editEvent(formValues)
            showResponseMessage(responseMessage)
            props.closeCallback(DialogResultEnum.Completed, mode, updatedSchedule)
        } catch (err: any) {
            setErrorMessage(err.message)
        } finally {
            dispatch(globalActions.hideLoadingModal())
        }
    }

    const dateTimePickerFormat = dateTimeFormatting.getKendoDateTimeFormat()
    let dialogTitle = `Edit ${scheduleEvent.getFundamentalType()} "${scheduleEvent.label}"`
    if (scheduleEvent.id === 0) {
        // new event
        const eventType = scheduleEvent.getFundamentalType()
        switch (eventType) {
            case 'Marker':
                dialogTitle = 'Add Marker'
                break
            case 'Sleep':
                dialogTitle = 'Add Sleep'
                break
            case 'Duty':
                dialogTitle = 'Add Work in a New Duty'
                break
            case 'Work':
                if (scheduleEvent.dutyLabel) {
                    dialogTitle = `Add Work to "${scheduleEvent.dutyLabel}"`
                } else {
                    dialogTitle = 'Add Work in a New Duty'
                }
                break
            default:
                throw new Error(`Unexpected event type:${eventType}`)
        }
    }

    return (
        <>
            <Form id="editEventDialog" noValidate validated={validatedForm} onSubmit={submitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>{dialogTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={9}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtEventLabel">Label</FormLabelCustom>
                                <span title={isAutoEvent ? 'Labels cannot be edited for Auto-Events' : ''}>
                                    <Form.Control
                                        id="txtEventLabel"
                                        autoComplete="off"
                                        name="label"
                                        autoFocus
                                        type="text"
                                        placeholder="Provide a label for the event"
                                        value={formValues.label}
                                        disabled={isAutoEvent}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                return { ...previousState, label: e.target.value }
                                            })
                                        }}
                                        required
                                    />
                                </span>
                                <Form.Control.Feedback type="invalid">
                                    Please provide an Event Label
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={3}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtStartStation">Start Station</FormLabelCustom>
                                <span title={isAutoEvent ? 'Stations cannot be edited for Auto-Events' : ''}>
                                    <StationAutoSuggestInput
                                        id="txtStartStation"
                                        name="startStation"
                                        themeOverrides={{
                                            suggestionsContainerOpen: 'suggestionsContainerOpen_EventDialog',
                                        }}
                                        placeholder={
                                            scheduleEvent.isLocationSetAutomatically()
                                                ? 'Auto'
                                                : 'Start typing a code or city'
                                        }
                                        value={scheduleEvent.isLocationSetAutomatically() ? '' : formValues.from}
                                        includeUtcOffsetForTime={
                                            props.timeMode === TimeModeEnum.Local ? formValues.start : undefined
                                        }
                                        isInvalidMessage="Please provide a valid start station"
                                        disabled={scheduleEvent.isLocationSetAutomatically() || isAutoEvent}
                                        onChange={(newValue, utcOffset) => {
                                            if (props.timeMode === TimeModeEnum.Local && utcOffset) {
                                                setStartUtcOffsetMinutes(utcOffset)
                                            }
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                return { ...previousState, from: newValue }
                                            })
                                        }}
                                    />
                                </span>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtStartTime">
                                    Start Time ({TimeModeEnum[props.timeMode]})
                                </FormLabelCustom>
                                <DateTimePickerCustom
                                    id="txtStartTime"
                                    value={startDisplay}
                                    format={dateTimePickerFormat}
                                    disabled={scheduleEvent.isPostFlightAutoDutyEvent()}
                                    onChange={(e: DateTimePickerChangeEvent) => {
                                        let newDate = e.value
                                        if (newDate === null) {
                                            // user removed the date, just put it back
                                            newDate = formValues.start
                                        }

                                        setFormValues((previous): ScheduleEventRecord => {
                                            const startDiff = newDate!.getTime() - startDisplay.getTime()
                                            return {
                                                ...previous,
                                                start: new Date(previous.start.getTime() + startDiff),
                                            }
                                        })
                                    }}
                                    width="100%"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={3}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtEndStation">End Station</FormLabelCustom>
                                <span title={isAutoEvent ? 'Stations cannot be edited for Auto-Events' : ''}>
                                    <StationAutoSuggestInput
                                        id="txtEndStation"
                                        name="endStation"
                                        themeOverrides={{
                                            suggestionsContainerOpen: 'suggestionsContainerOpen_EventDialog',
                                        }}
                                        placeholder={
                                            scheduleEvent.isLocationSetAutomatically()
                                                ? 'Auto'
                                                : 'Start typing a code or city'
                                        }
                                        value={scheduleEvent.isLocationSetAutomatically() ? '' : formValues.to}
                                        includeUtcOffsetForTime={
                                            props.timeMode === TimeModeEnum.Local ? new Date(endTimeUtc) : undefined
                                        }
                                        isInvalidMessage="Please provide a valid end station"
                                        disabled={scheduleEvent.isLocationSetAutomatically() || isAutoEvent}
                                        onChange={(newValue, utcOffset) => {
                                            if (props.timeMode === TimeModeEnum.Local && utcOffset) {
                                                setEndUtcOffsetMinutes(utcOffset)
                                            }
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                return { ...previousState, to: newValue }
                                            })
                                        }}
                                    />
                                </span>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtEndTime">
                                    End Time ({TimeModeEnum[props.timeMode]})
                                </FormLabelCustom>

                                <DateTimePickerCustom
                                    id="txtEndTime"
                                    value={endDisplay}
                                    format={dateTimePickerFormat}
                                    disabled={scheduleEvent.isPreFlightAutoDutyEvent()}
                                    onChange={(e: DateTimePickerChangeEvent) => {
                                        const calculateDuration = (oldDate: Date, newDate: Date) => {
                                            const durationChange = (oldDate.getTime() - newDate.getTime()) / 60000
                                            return formValues.duration - durationChange
                                        }
                                        let newDate = e.value
                                        if (!newDate) {
                                            // user removed the date, just put it back
                                            newDate = endDisplay
                                        }

                                        const newDuration = calculateDuration(endDisplay, newDate)
                                        setFormValues((previous): ScheduleEventRecord => {
                                            return { ...previous, duration: newDuration }
                                        })
                                    }}
                                    width="100%"
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group>
                                <FormLabelCustom htmlFor="txtDuration">Duration</FormLabelCustom>
                                <Form.Control
                                    id="txtDuration"
                                    autoComplete="off"
                                    name="label"
                                    role="textbox"
                                    type="number"
                                    placeholder="Duration"
                                    value={formValues.duration}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFormValues((previousState): ScheduleEventRecord => {
                                            let newDuration = parseInt(e.target.value)
                                            if (Number.isNaN(newDuration) || newDuration < 1) {
                                                newDuration = 1
                                            }
                                            return { ...previousState, duration: newDuration }
                                        })
                                    }}
                                    required
                                    min={1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid duration
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {!scheduleEvent.isAnySleep() && !scheduleEvent.isAnyMarker() && !isAutoEvent && (
                        <Row>
                            <Col sm={12} className="mb-3">
                                <FormLabelCustom>Crewing</FormLabelCustom>
                                <div className={simpleDialogClasses.checkBoxContainer}>
                                    <Form.Check
                                        type="radio"
                                        id="rdoIsCrewing"
                                        name="isCrewing"
                                        label="Is a Crewing Event"
                                        disabled={isAutoEvent}
                                        checked={formValues.crewing}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                const crewing = e.target.checked
                                                return { ...previousState, crewing }
                                            })
                                        }}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="rdoIsNotCrewing"
                                        name="isCrewing"
                                        label="Is Not a Crewing Event"
                                        disabled={isAutoEvent}
                                        checked={!formValues.crewing}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                const crewing = !e.target.checked
                                                return { ...previousState, crewing }
                                            })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                    {scheduleEvent.isAnyMarker() && !isAutoEvent && (
                        <Row>
                            <Col sm={12} className="mb-3">
                                <FormLabelCustom>Critical</FormLabelCustom>
                                <div className={simpleDialogClasses.checkBoxContainer}>
                                    <Form.Check
                                        type="radio"
                                        id="rdoIsCritical"
                                        name="isCritical"
                                        label="Is a Critical Event"
                                        checked={formValues.critical}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                const critical = e.target.checked
                                                return { ...previousState, critical }
                                            })
                                        }}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="rdoIsNotCritical"
                                        name="isCritical"
                                        label="Is Not a Critical Event"
                                        checked={!formValues.critical}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFormValues((previousState): ScheduleEventRecord => {
                                                const critical = !e.target.checked
                                                return { ...previousState, critical }
                                            })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}

                    {scheduleEvent.isAnySleep() && !isAutoEvent && (
                        <Row>
                            <Col sm={9} className="mb-3">
                                <FormLabelCustom htmlFor="sleepQuality">Sleep Quality</FormLabelCustom>
                                <Form.Select
                                    id="sleepQuality"
                                    name="sleepQuality"
                                    value={SleepQualityEnum[formValues.quality!]}
                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setFormValues((previousState): ScheduleEventRecord => {
                                            const sleepQuality =
                                                SleepQualityEnum[e.target.value as keyof typeof SleepQualityEnum]
                                            return { ...previousState, quality: sleepQuality }
                                        })
                                    }}
                                >
                                    <option value="Excellent">Excellent</option>
                                    <option value="Good">Good</option>
                                    <option value="Fair">Fair</option>
                                    <option value="Poor">Poor</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    )}

                    {pwsVisible && !isAutoEvent && (
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <FormLabelCustom htmlFor="pwsRule">Planned Work Sleep Rule</FormLabelCustom>
                                    <Form.Select
                                        id="pwsRule"
                                        name="plannedWorkSleepRule"
                                        value={pwsRuleString}
                                        disabled={isAutoEvent}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setFormValues((previous): ScheduleEventRecord => {
                                                const selectedRule = e.target.value
                                                const plannedWorkSleepOwner =
                                                    e.target.value === 'Auto' ? 'System' : 'User'
                                                let plannedWorkQuality = previous.plannedWorkSleepQuality
                                                let plannedWorkSleepRuleId: number | null = null
                                                if (selectedRule === 'Auto' || selectedRule === 'None') {
                                                    plannedWorkQuality = null
                                                } else {
                                                    plannedWorkSleepRuleId = parseInt(selectedRule)
                                                    plannedWorkQuality =
                                                        props.pwsRules.find((x) => x.id === plannedWorkSleepRuleId)
                                                            ?.quality || null
                                                }
                                                return {
                                                    ...previous,
                                                    plannedWorkSleepOwner,
                                                    plannedWorkSleepRuleId,
                                                    plannedWorkSleepQuality: plannedWorkQuality,
                                                }
                                            })
                                        }}
                                    >
                                        {pwsRuleIds.map((rule) => {
                                            return (
                                                <option key={rule} value={rule}>
                                                    {rule}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <FormLabelCustom htmlFor="pwsQuality">Planned Work Sleep Quality</FormLabelCustom>
                                    {showPwsQuality ? (
                                        <Form.Select
                                            id="pwsQuality"
                                            name="plannedWorkSleepQuality"
                                            value={pwsQuality}
                                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                setFormValues((previous): ScheduleEventRecord => {
                                                    const newQuality =
                                                        SleepQualityEnum[
                                                            e.target.value as keyof typeof SleepQualityEnum
                                                        ]
                                                    return { ...previous, plannedWorkSleepQuality: newQuality }
                                                })
                                            }}
                                        >
                                            {metadata.plannedWorkSleepQualities.map((x) => {
                                                return (
                                                    <option key={x} value={x}>
                                                        {x}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    ) : (
                                        <div>
                                            <i>N/A</i>
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    {!isAutoEvent && (
                        <Row>
                            <Col sm={12}>
                                <FormLabelCustom>Tags</FormLabelCustom>
                                <TagsTable
                                    allTagNames={props.allEventTagNames}
                                    showValidationErrors={validatedForm}
                                    tags={formValues.eventTags}
                                    updateTags={(tags: ScheduleEventRecord['eventTags']) => {
                                        setFormValues((previousState): ScheduleEventRecord => {
                                            return { ...previousState, eventTags: tags }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    )}

                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Modal.Body>

                <Modal.Footer>
                    {scheduleEvent.id > 0 && (
                        <div className="mr-auto">
                            <ButtonCustom
                                variant="danger"
                                isLarge
                                onClick={() => {
                                    scheduleDetailsCtx.setFormState(formValues)
                                    props.deleteCallback()
                                }}
                            >
                                Delete
                            </ButtonCustom>
                        </div>
                    )}
                    <ButtonCustom isLarge variant="primary" type="submit">
                        OK
                    </ButtonCustom>
                    <ButtonCustom
                        isLarge
                        variant="secondary"
                        onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                    >
                        Cancel
                    </ButtonCustom>
                </Modal.Footer>
            </Form>
        </>
    )
}

const EventEditingDialog = (props: EventDialogProps) => {
    return (
        <ModalWrapper
            className="DialogSmallWidth"
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}
        >
            <>
                <EventEditingDialogContent {...props} />
            </>
        </ModalWrapper>
    )
}

export default memo(EventEditingDialog)
