import { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import { ColorPalettes, PaletteType } from 'types/interfaces'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report } from 'types/Reports'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedReportDrilldownMetricCell } from 'views/Common/Kendo/FormattedMetricCell'
import { createFormattedMetricHeaderCell, FormattedNameCell, getLink } from 'views/Common/Kendo/FormattedNameCell'

const CrewingSuffix = '(Crewing)'
const CriticalSuffix = '(Critical)'

/**
 * Get columns for the reports drilldown grid
 */
const getColumns = (
    report: Report,
    metadata: ReportingMetadata,
    drilldownData: any[],
    palettes: ColorPalettes,
): KendoGridColumn[] => {
    const reportDataType = report.configOptions!.dataType
    const allColumnsMetadata = metadata.drilldownGridColumns[reportDataType]

    const requiredColumnOrder = ['Scenario Name', 'Schedule ID']
    if (reportDataType === 'events') {
        requiredColumnOrder.push('Label')
    }

    let orderedColumns = requiredColumnOrder.map<KendoGridColumn>((columnTitle) => {
        const metaColumn = allColumnsMetadata.find((x) => x.title === columnTitle)!
        const kendoColumn: KendoGridColumn = {
            field: metaColumn.field,
            title: columnTitle,
            locked: true,
            width: 150,
            cellClassName: 'left-aligned',
            headerClassName: 'left-aligned',
        }

        // need to hyperlink to the schedule details page
        if (metaColumn.title === 'Schedule ID') {
            kendoColumn.cell = (props: GridCellProps) => {
                const record = props.dataItem as any
                const scheduleIdField = reportDataType === 'events' ? 'ScheduleID' : 'ID'
                const scheduleName = record[scheduleIdField]
                const link = getLink(`/schedule/${record.dbId}/view`, scheduleName)
                return FormattedNameCell(props, link)
            }
        }

        return kendoColumn
    })

    // some columns need to be filtered out of the list in reportMetadata
    const filteredMetaColumns = allColumnsMetadata.filter((metaColumn) => {
        if (requiredColumnOrder.includes(metaColumn.title)) {
            // already got this column
            return false
        }

        const notTagColumn = metaColumn.title.indexOf('Tags.') !== 0
        if (notTagColumn) {
            // not a tag, so don't filter it out.
            return true
        }

        // keep this tag column if any of the drilldown data rows actually have a value for it.
        return drilldownData.filter((dd) => dd[metaColumn.field] !== undefined).length > 0
    })

    orderedColumns = orderedColumns.concat(
        filteredMetaColumns.map((metaColumn) => {
            // use the c# attribute, and apply some factor to make it work in the web.
            // The c# attribute is targetted at setting the width of Telerik winform
            // grid columns, so it doesn't fit exactly with the kendo grid.
            // We are aiming to have only 2 rows of header text
            const column: KendoGridColumn = {
                ...metaColumn,
                width: metaColumn.width && metaColumn.width > 0 ? metaColumn.width * 1.2 : 100,
                hide: metaColumn.hidden,
            }

            // custom header display to reduce the size of the "(Crewing)" or "(Critical)" text
            if (column.title) {
                if (column.title.endsWith(CrewingSuffix) || column.title.endsWith(CriticalSuffix)) {
                    const suffix = column.title.endsWith(CrewingSuffix) ? 'Crewing' : 'Critical'
                    column.headerCell = (props: GridHeaderCellProps) =>
                        createFormattedMetricHeaderCell(
                            props,
                            column.title!.replace(CrewingSuffix, '').replace(CriticalSuffix, ''),
                            suffix,
                            true,
                        )
                }
            }

            // metric needs to be colored?
            if (metaColumn.palette !== 'none') {
                const paletteType: PaletteType = metaColumn.palette as PaletteType
                column.cell = (props: GridCellProps) => FormattedReportDrilldownMetricCell(props, paletteType, palettes)
            }

            return column
        }),
    )
    return orderedColumns
}

export default getColumns
