import { Dispatch, ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import globals from 'services/global/globals'
import { globalActions } from 'store/globalStore'
import { RootState } from 'store/store'

export interface GetReportMetadataArgs {
    forceRefresh: boolean
}

/**
 * fetch reporting meta data (used by query builder tool, and other places) into redux
 * @returns
 */
const fetchReportMetaData = ({
    forceRefresh,
}: GetReportMetadataArgs): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        if (state.app.isFetchingReportingMetadata || (state.app.reportingMetadata && !forceRefresh)) {
            // don't fetch if a) currently fetching, b) is already loaded and we don't want to force a refresh
            return
        }
        dispatch(globalActions.setIsFetchingReportingMetadata())
        const metadata = await globals.getApi().getReportingApi().getReportsConfigMetadata()
        dispatch(globalActions.setReportingMetadata(metadata))
    }
}

export default { fetchReportMetaData }
