export interface SystemSetting {
    name: string
    displayName: string
    description: string
    value: string
    settingDefinitionType: string
    options?: string[]
    required?: boolean
}

export interface SettingValue {
    name: string
    value: string
}

export const PaletteSettingsDefault: SystemSetting[] = [
    {
        name: 'Setting.Palettes.EffectivenessAbove0',
        displayName: '',
        description: '',
        value: '#F2BFBE',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.EffectivenessAbove1',
        displayName: '',
        description: '',
        value: '#FFDA96',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.EffectivenessAboveThreshold',
        displayName: '',
        description: '',
        value: '#FFFBC1',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.EffectivenessAbove2',
        displayName: '',
        description: '',
        value: '#D3FABB',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.EffectivenessPercent1',
        displayName: '',
        description: '',
        value: '65',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.EffectivenessPercent2',
        displayName: '',
        description: '',
        value: '90',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirAbove0',
        displayName: '',
        description: '',
        value: '#FF4500',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirAbove1',
        displayName: '',
        description: '',
        value: '#FFA500',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirAbove2',
        displayName: '',
        description: '',
        value: '#FFFF80',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirAbove3',
        displayName: '',
        description: '',
        value: '#90EE90',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirPercent1',
        displayName: '',
        description: '',
        value: '65',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.ReservoirPercent2',
        displayName: '',
        description: '',
        value: '90',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentAbove0',
        displayName: '',
        description: '',
        value: '#90EE90',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentAbove1',
        displayName: '',
        description: '',
        value: '#FFFF80',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentAbove2',
        displayName: '',
        description: '',
        value: '#FFA500',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentAbove3',
        displayName: '',
        description: '',
        value: '#FF4500',
        settingDefinitionType: 'Text',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentBreak1',
        displayName: '',
        description: '',
        value: '1',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
    {
        name: 'Setting.Palettes.PercentBreak2',
        displayName: '',
        description: '',
        value: '10',
        settingDefinitionType: 'Number',
        options: undefined,
        required: false,
    },
]
export const SettingConsts = {
    general: {
        account_Announcement: 'Setting.General.Account.Announcement',
        account_Project_Name: 'Setting.General.Account.ProjectName',
        account_Schedule_Name: 'Setting.General.Account.ScheduleName',
        account_Default_Base_Station: 'Setting.General.Account.DefaultBaseStation',
        features_Reporting: 'Setting.General.Features.Reporting',
        features_Insights: 'Setting.General.Features.Insights',
        features_Detail_CSV_Export: 'Setting.General.Features.DetailCSVExport',
        features_Data_Imports: 'Setting.General.Features.DataImports',
        features_Data_Exports: 'Setting.General.Features.DataExports',
        help_Support_Portal: 'Setting.General.Help.SupportPortal',
        help_Training_Academy: 'Setting.General.Help.TrainingAcademy',
        help_Training_Academy_URL: 'Setting.General.Help.TrainingAcademyURL',
        schedules_Default_Builder_Type: 'Setting.General.Schedules.DefaultBuilderType',
        schedules_Input_Types: 'Setting.General.Schedules.InputTypes',
        schedules_Dashboard_Orientation: 'Setting.General.Schedules.DashboardOrientation',
    },
    palettes: {
        effectiveness_Above_0: 'Setting.Palettes.EffectivenessAbove0',
        effectiveness_Above_1: 'Setting.Palettes.EffectivenessAbove1',
        effectiveness_Above_Threshold: 'Setting.Palettes.EffectivenessAboveThreshold',
        effectiveness_Above_2: 'Setting.Palettes.EffectivenessAbove2',
        effectivenes_Percent_1: 'Setting.Palettes.EffectivenessPercent1',
        effectivenes_Percent_2: 'Setting.Palettes.EffectivenessPercent2',
        reservoir_Above_0: 'Setting.Palettes.ReservoirAbove0',
        reservoir_Above_1: 'Setting.Palettes.ReservoirAbove1',
        reservoir_Above_2: 'Setting.Palettes.ReservoirAbove2',
        reservoir_Above_3: 'Setting.Palettes.ReservoirAbove3',
        reservoir_Percent_1: 'Setting.Palettes.ReservoirPercent1',
        reservoir_Percent_2: 'Setting.Palettes.ReservoirPercent2',
        percent_Above_0: 'Setting.Palettes.PercentAbove0',
        percent_Above_1: 'Setting.Palettes.PercentAbove1',
        percent_Above_2: 'Setting.Palettes.PercentAbove2',
        percent_Above_3: 'Setting.Palettes.PercentAbove3',
        percent_Break_1: 'Setting.Palettes.PercentBreak1',
        percent_Break_2: 'Setting.Palettes.PercentBreak2',
    },
}
