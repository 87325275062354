import { ChangeEvent } from 'react'

export interface BaseInputProps {
    id?: string
    required?: boolean
    invalidText?: string
}

export interface FormControlChangeEvent {
    target: {
        name: string
        value: any
    }
}

export const buildEvent = (e: ChangeEvent<HTMLInputElement>): FormControlChangeEvent => {
    return buildEventFromNameAndValue(e.target.name, e.target.value)
}

export const buildEventNumeric = (e: ChangeEvent<HTMLInputElement>): FormControlChangeEvent => {
    const parsedValue = /-/.test(e.target.value) ? e.target.value : parseFloat(e.target.value)
    return buildEventFromNameAndValue(
        e.target.name,
        parsedValue !== '-' && Number.isNaN(parsedValue) ? null : parsedValue,
    )
}

export const buildEventFromNameAndValue = (name: string, value: any): FormControlChangeEvent => {
    return {
        target: {
            name,
            value,
        },
    }
}

export const updateObjectWithFormEvent = (prev: any, e: FormControlChangeEvent) => ({
    ...prev,
    [e.target.name]: e.target.value,
})

export type OperationMode = 'Create' | 'Edit' | 'Copy'
