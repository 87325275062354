const GeneralSettingList = [
    {
        section: 'Account',
        items: [
            'Setting.General.Account.Announcement',
            'Setting.General.Account.ProjectName',
            'Setting.General.Account.ScheduleName',
            'Setting.General.Account.DefaultBaseStation',
        ],
    },
    {
        section: 'Features',
        items: [
            'Setting.General.Features.Reporting',
            'Setting.General.Features.Insights',
            'Setting.General.Features.DetailCSVExport',
            'Setting.General.Features.DataImports',
            'Setting.General.Features.DataExports',
        ],
    },
    {
        section: 'Users',
        items: [
            'Setting.General.Users.ActiveUserLimit',
            'Setting.General.Users.MaximumConcurrentSessions',
            'Setting.General.Users.ValidDomain',
            'Setting.General.Users.CustomFields',
        ],
    },
    {
        section: 'Help',
        items: [
            'Setting.General.Help.SupportPortal',
            'Setting.General.Help.TrainingAcademy',
            'Setting.General.Help.TrainingAcademyURL',
        ],
    },
    {
        section: 'Stations',
        items: ['Setting.General.Stations.DisplayCode'],
    },
    {
        section: 'Schedules',
        items: [
            'Setting.General.Schedules.InputTypes',
            'Setting.General.Schedules.DefaultBuilderType',
            'Setting.General.Schedules.DefaultRightAxis',
            'Setting.General.Schedules.DefaultTimeReference',
            'Setting.General.Schedules.DashboardOrientation',
        ],
    },
]

export default GeneralSettingList
