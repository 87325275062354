import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import ConfirmationDialog from 'views/Common/GenericDialogs/ConfirmationDialog'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'

const getDeleteMessage = (numRows: number) =>
    `Are you sure you want to delete the ${numRows} selected scenario${numRows > 1 ? 's' : ''}?`

const DeleteScenarioConfirmation = (props: {
    scenarioIds: number[]
    onCloseDialog: (result: DialogResultEnum) => void
}) => {
    const api = globals.getApi().getScenarioApi()
    return (
        <ConfirmationDialog
            headerText="Delete Scenario"
            closeCallback={() => props.onCloseDialog(DialogResultEnum.Cancelled)}
            confirmedCallback={() => {
                const doDelete = async () => {
                    try {
                        await api.deleteScenarios(props.scenarioIds)
                        props.onCloseDialog(DialogResultEnum.Completed)
                    } catch (err: any) {
                        handleApiError(err)
                    }
                }
                doDelete()
            }}
        >
            {getDeleteMessage(props.scenarioIds.length)}
        </ConfirmationDialog>
    )
}

export default DeleteScenarioConfirmation
