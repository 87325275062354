import { Navigate } from 'react-router-dom'

const ScenarioRedirectPage = () => {
    const lastViewedScenarioId = window.localStorage.getItem('lastViewedScenarioId')
    if (lastViewedScenarioId) {
        return <Navigate to={`/scenario/${lastViewedScenarioId}/schedules`} replace />
    }
    return <Navigate to="/scenarios/" replace />
}

export default ScenarioRedirectPage
