import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { WeightTypeNames, WorkloadFactorTypeNames } from 'types/EnumTypes'
import { KendoGridColumn } from 'types/GridLayout'
import { WorkloadFactor, WorkloadRuleFactor } from 'types/ProfileInterfaces'
import { getDefaulWorkloadFactor } from 'types/ProfileInterfacesDefaults'
import FormFooter from 'views/Common/Form/FormFooter'
import InformationDialog from 'views/Common/GenericDialogs/InformationDialog'
import GridDropDownCell from 'views/Common/Kendo/GridCellComponents/GridDropDownCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

interface WorkloadFactorsDialogProps {
    profileId: string
    onClose: () => void
}

const WorkloadFactorsDialog = (props: WorkloadFactorsDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileWorkload)
    const [state, setState] = useState<WorkloadFactor[]>(profile?.workloadFactors || [])

    // ui state
    const [showDialog, setShowDialog] = useState(<></>)

    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    // operations
    const addNewRecord = (existRecord?: WorkloadFactor) => {
        const newRecord = getDefaulWorkloadFactor(existRecord, state)

        setState([...state, newRecord])
        setSelectedRowsState({ [newRecord.id]: true })
    }
    function handleCopyClick(): void {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = state.find((i) => i.id === selectedIds[0])
        if (item) {
            addNewRecord(item)
        }
    }
    const handleDeleteClick = () => {
        setShowDialog(deleteDialog)
    }

    function changeOrder(direction: MoveDirection) {
        setState(reorderItemsList(selectedIds, state, direction))
    }

    // Delete or Add Factors from WorkloadRule WorkloadRuleFactor List
    const getWorkloadFactors = (factors: WorkloadFactor[], ruleFactors: WorkloadRuleFactor[]): WorkloadRuleFactor[] => {
        return factors.map((x) => ({
            worloadFactorId: x.id,
            enabled: ruleFactors.find((y) => y.worloadFactorId === x.id)?.enabled || false,
            factorName: x.name,
            workloadFactorType: x.workloadFactorType,
        }))
    }
    const isNameUnique = () => {
        return new Set(state.map((x) => x.name)).size === state.length
    }

    const onSave = () => {
        if (!profile) return
        const emptyNameItem = state.find((x) => x.name.length === 0 || !x.name.trim())
        if (emptyNameItem) {
            const newList = state.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === emptyNameItem.id ? 'name' : undefined,
            }))
            setState(newList)
            showMessage('Warning', 'Workload Factor name cannot be empty')
        } else if (!isNameUnique()) {
            showMessage('Warning', 'Workload Factor name must be unique')
        } else {
            const updatedRules = profile.workloadRules.map((rule) => ({
                ...rule,
                workloadFactors: getWorkloadFactors(state, rule.workloadFactors),
            }))
            const updated = { ...profile, workloadFactors: state, workloadRules: updatedRules }
            dispatch(profileActions.setProfileByType({ profile: updated, type: 'Workload', isModified: true }))
            props.onClose()
        }
    }

    // grid
    const EDIT_FIELD = 'inEdit'
    const enterEditHandler = useCallback(
        (dataItem: WorkloadFactor, field: string | undefined) => {
            const newList = state.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
            }))
            setState(newList)
            setSelectedRowsState({ [dataItem.id]: true })
        },
        [state],
    )
    const exitEditHandler = () => {
        setState(state.map((item) => ({ ...item, [EDIT_FIELD]: undefined })))
    }
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field || ''
        const newList = state.map((item) => {
            if (item.id === event.dataItem.id) {
                return { ...item, [field]: event.value }
            }
            return item
        })
        setState(newList)
    }
    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
    )
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={EDIT_FIELD} />
    )

    const getColumns = useMemo((): KendoGridColumn[] => {
        const getDropdownCell = (
            gridCellProps: GridCellProps,
            type: {
                [key in string]: string
            },
        ) => <GridDropDownCell {...gridCellProps} type={type} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
        const columns: KendoGridColumn[] = [
            { title: 'Name', field: 'name' },
            {
                title: 'Type',
                field: 'workloadFactorType',
                cell: (c) => getDropdownCell(c, WorkloadFactorTypeNames),
            },
            {
                title: 'Weight',
                field: 'weight',
                cell: (c) => getDropdownCell(c, WeightTypeNames),
            },
        ]
        return columns
    }, [enterEditHandler])

    const headerContent = 'Workload Factors'

    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={state}
                                selectedIds={selectedIds}
                                onAddClick={addNewRecord}
                                onCopyClick={handleCopyClick}
                                onDeleteClick={handleDeleteClick}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={state}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemEditedHandler}
                    />
                </Col>
            </Row>
        </Container>
    )

    const footerContent = <FormFooter onCancel={props.onClose} onOk={onSave} />

    // dialogs
    const showMessage = (header: string, message: string) => {
        setShowDialog(
            <InformationDialog
                headerText={header}
                message={message}
                onClose={() => {
                    setShowDialog(<></>)
                }}
            />,
        )
    }

    const deleteDialog = useMemo(() => {
        if (!profile) return <></>
        const isFactorUsed = (factorIds: string[]) => {
            const val =
                profile!.workloadRules.find(
                    (x) =>
                        x.workloadFactors.find((y) => factorIds.includes(y.worloadFactorId) && y.enabled) !== undefined,
                ) !== undefined
            return val
        }

        return (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Workload Factor"
                message={isFactorUsed(selectedIds) ? 'The Workload Factor has been used in Workload Rules.' : undefined}
                setTempItems={setState}
                tempItems={state}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        )
    }, [selectedIds, state, profile])

    return (
        <>
            <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                {gridContent}
            </DialogLayout>
            {showDialog}
        </>
    )
}

export default WorkloadFactorsDialog
