import { ChangeEvent, useRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { To } from 'react-router-dom'
import IconButton from 'views/Common/Buttons/IconButton'
import classes from 'views/Common/Form/Form.module.css'
import FormControlTextInput, { FormControlTextInputProps } from 'views/Common/Form/FormControls/FormControlTextInput'
import FormRow, { FormRowProps } from 'views/Common/Form/FormRows/FormRow'
import { FormControlChangeEvent, OperationMode } from '../FormControls/FormControlBase'
import FormControlDatePicker, { FormControlDatePickerProps } from '../FormControls/FormControlDatePicker'
import FormControlGearIconLink, { FormControlGearIconLinkProps } from '../FormControls/FormControlGearIconLink'
import FormControlNumericInput, { FormControlNumericInputProps } from '../FormControls/FormControlNumericInput'
import FormControlSelectInput, { FormControlSelectInputProps } from '../FormControls/FormControlSelectInput'
import FormControlSwitch, { FormControlSwitchProps } from '../FormControls/FormControlSwitch'
import FormControlTextAreaInput, { FormControlTextAreaInputProps } from '../FormControls/FormControlTextAreaInput'
import FormControlTimePicker, { FormControlTimePickerProps } from '../FormControls/FormControlTimePicker'

export type ColumnTypes =
    | 'textInput'
    | 'textAreaInput'
    | 'numericInput'
    | 'selectInput'
    | 'timePickerInput'
    | 'switchInput'
    | 'gearIcon'
    | 'text'

interface InputTextFormRowProps extends FormRowProps {
    value?: string
    required?: boolean
    validated?: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onValidation?: () => boolean
}
export const InputTextFormRow = (props: InputTextFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlTextInput
                value={props.value}
                onChange={props.onChange}
                id={props.fieldId}
                required={props.required}
                validated={props.validated}
                onValidation={props.onValidation}
            />
        </FormRow>
    )
}

interface InputFileFormRowProps extends FormRowProps {
    value?: string
    required?: boolean
    validated?: boolean
    disabled?: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
export const InputFileFormRow = (props: InputFileFormRowProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        // Trigger the file input's "browse" dialog programmatically
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    return (
        // cannot set an existing value on a file input
        // hide an actual file input, but display a custon button and text
        <>
            <FormRow {...props}>
                <div style={{ display: 'none' }}>
                    <Form.Control
                        ref={fileInputRef}
                        type="file"
                        onChange={props.onChange}
                        id={props.fieldId}
                        name={props.fieldId}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                        <IconButton style={{ height: '38px' }} text="Select File..." icon="" onClick={handleClick} />
                    </span>
                    <span style={{ flex: 1 }}>
                        <Form.Control
                            type="text"
                            value={props.value}
                            required={props.required}
                            onClick={handleClick}
                            readOnly
                            isInvalid={props.validated && props.required && !props.value} // Indicate that there's an error
                        />
                        <Form.Control.Feedback type="invalid">Select a file</Form.Control.Feedback>
                    </span>
                </div>
            </FormRow>
        </>
    )
}

interface InputTextAreaFormRowProps extends FormRowProps {
    value?: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
export const InputTextAreaFormRow = (props: InputTextAreaFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlTextAreaInput value={props.value} onChange={props.onChange} id={props.fieldId} />
        </FormRow>
    )
}
interface InputNumericFormRowProps extends FormRowProps {
    value?: number
    minValue?: number
    maxValue?: number
    onChange: (e: FormControlChangeEvent) => void
    required?: boolean
    validated?: boolean
    step?: number
}
export const InputNumericFormRow = (props: InputNumericFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlNumericInput
                value={props.value}
                onChange={props.onChange}
                id={props.fieldId}
                min={props.minValue}
                max={props.maxValue}
                required={props.required}
                validated={props.validated}
                {...(props.step && { step: props.step })}
            />
        </FormRow>
    )
}

interface InputSelectFormRowProps extends FormRowProps {
    value?: string
    required?: boolean
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void
    options: string[] | { key: string; value: string }[]
}
export const InputSelectFormRow = (props: InputSelectFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlSelectInput
                value={props.value}
                onChange={props.onChange}
                id={props.fieldId}
                options={props.options}
                required={props.required}
            />
        </FormRow>
    )
}

interface InputTimePickerFormRowProps extends FormRowProps {
    value?: Date
    onChange: (e: any) => void
}
export const InputTimePickerFormRow = (props: InputTimePickerFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlTimePicker value={props.value} onChange={props.onChange} id={props.fieldId} />
        </FormRow>
    )
}

interface InputDatePickerFormRowProps extends FormRowProps {
    value: Date
    onChange: (e: any) => void
}
export const InputDatePickerFormRow = (props: InputDatePickerFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlDatePicker value={props.value} onChange={props.onChange} id={props.fieldId} />
        </FormRow>
    )
}

interface SwitchFormRowProps extends FormRowProps {
    value: boolean
    onChange: (e: any) => void
}
export const SwitchFormRow = (props: SwitchFormRowProps) => {
    return (
        <FormRow {...props}>
            <FormControlSwitch value={props.value} onChange={props.onChange} id={props.fieldId} />
        </FormRow>
    )
}

interface ColumnProps {
    colWidth?: number
    colType?: string
    value?: any
    inputTextProps?: FormControlTextInputProps
    inputTextAreaProps?: FormControlTextAreaInputProps
    inputNumericProps?: FormControlNumericInputProps
    inputSelectProps?: FormControlSelectInputProps
    timePickerProps?: FormControlTimePickerProps
    datePickerProps?: FormControlDatePickerProps
    gearIconLinkProps?: FormControlGearIconLinkProps
    switchProps?: FormControlSwitchProps
    genericProps?: any
}

export interface MultiColumnFormRowProps extends FormRowProps {
    columns: ColumnProps[]
}
export const MultiColumnFormRow = (props: MultiColumnFormRowProps) => {
    const renderColumns = props.columns?.map((col, idx) => {
        let res = <></>
        switch (col.colType) {
            case 'textInput':
                res = <FormControlTextInput {...col.inputTextProps!} />
                break
            case 'textAreaInput':
                res = <FormControlTextAreaInput {...col.inputTextAreaProps!} />
                break
            case 'numericInput':
                res = <FormControlNumericInput {...col.inputNumericProps!} />
                break
            case 'selectInput':
                res = <FormControlSelectInput {...col.inputSelectProps!} />
                break
            case 'timePickerInput':
                res = <FormControlTimePicker {...col.timePickerProps!} />
                break
            case 'datePickerInput':
                res = <FormControlDatePicker {...col.datePickerProps!} />
                break
            case 'switchInput':
                res = <FormControlSwitch {...col.switchProps!} />
                break
            case 'gearIcon':
                res = <FormControlGearIconLink {...col.gearIconLinkProps!} />
                break
            case 'text':
                res = <h6 style={{ textAlign: 'center', marginTop: '8px' }}>{col.value}</h6> // This is for text between input columns such as timepickers
                break
            default:
                res = <p style={{ textAlign: 'center', marginTop: '8px' }}>{col.value}</p> // Edge case in case we need to insert something without any formatting
        }

        return (
            <Col key={idx} xs={col.colWidth}>
                {res}
            </Col>
        )
    })
    return (
        <Form.Group className="mb-3">
            <Row>
                <Col xs="5">
                    <Form.Label className={classes.label} id={props.fieldId}>
                        {props.labelText}
                    </Form.Label>
                    <br />
                    <small>{props.subText}</small>
                </Col>
                {renderColumns}
            </Row>
        </Form.Group>
    )
}

interface DrillDownFormRowProps {
    labelText: string
    subText?: string
    fieldId?: string
    value?: string
    onClick?: React.MouseEventHandler<HTMLOrSVGElement>
    link?: To
    data?: any
    operationMode?: OperationMode
}
export const DrillDownFormRow = (props: DrillDownFormRowProps) => {
    return (
        <Form.Group className="mb-3">
            <Row>
                <Col xs="5">
                    <Form.Label className={classes.label} id={props.fieldId}>
                        {props.labelText}
                    </Form.Label>
                    <br />
                    <small>{props.subText}</small>
                </Col>
                <Col xs="7">
                    <FormControlTextInput
                        id={props.fieldId ?? props.labelText + '_input'}
                        value={props.value}
                        readOnly
                        onClick={props.onClick}
                    />
                </Col>
                <Col style={{ marginLeft: '-60px', marginTop: '5px' }}>
                    <FormControlGearIconLink
                        onClick={props.onClick}
                        link={props.link}
                        data={props.data}
                        operationMode={props.operationMode}
                    />
                </Col>
            </Row>
        </Form.Group>
    )
}
