import useUser from 'hooks/useUser'
import { FormEvent, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { getLowestId } from 'services/utilities/arrayUtils'
import { handleApiError } from 'services/utilities/toastrUtils'
import { reportsActions } from 'store/reportingStore'
import { AddReportFromLibraryRequest } from 'types/AddFromLibraryRequest'
import ReportingMetadata from 'types/ReportingMetadata'
import { Report, ReportDialogConfig } from 'types/Reports'
import { ScenarioDetail } from 'types/Scenario'
import { ScenarioSelection } from 'types/ScenarioSelection'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import IconButton from 'views/Common/Buttons/IconButton'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ModalWrapper from 'views/Common/GenericDialogs/ModalWrapper'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import SharingDialog from 'views/Common/SharingDialog/SharingDialog'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import ScenarioSelectorButton, { ScenarioSelectionOptions } from 'views/Common/Widget/ScenarioSelectorButton'
import ScenarioSelectorDialog from 'views/Scenarios/Dialogs/ScenarioSelectionDialog'
import getColumns, { DialogMode } from './ReportsLibraryGridColumns'

const ReportsLibraryDialogContent = (props: {
    dialogMode: DialogMode
    addReportModeOptions: ScenarioSelectionOptions
    setAddReportModeOptions: (options: ScenarioSelectionOptions) => void
    setShowScenarioSelectionDialog: () => void
    setShowShareReportDialogForReportIds: (reportIds: number[]) => void
    closeCallback: (status: DialogResultEnum, updatedReports?: Report[]) => void
    setEditReport: (report: ReportDialogConfig) => void
    libraryDefaults: ReportDialogConfig[]
    libraryReports: ReportDialogConfig[]
    setLibraryReports: (reports: ReportDialogConfig[]) => void
}) => {
    const reportingApi = globals.getApi().getReportingApi()

    const user = useUser()
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const [selectedReportIds, setSelectedReportIds] = useState<number[]>([])

    const clickedReportHandler = (report: ReportDialogConfig) => props.setEditReport(report)
    const clickedSharingIconHandler = (report: ReportDialogConfig) =>
        props.setShowShareReportDialogForReportIds([report.id])

    const updateReportIncludeAsDefault = (reportId: number, checked: boolean) => {
        const updatedReports = props.libraryReports.map((report) => {
            if (report.id === reportId) {
                return { ...report, includeAsDefault: checked }
            }
            return report
        })

        props.setLibraryReports(updatedReports)
    }

    const columns = getColumns(
        user!,
        props.dialogMode,
        clickedReportHandler,
        clickedSharingIconHandler,
        updateReportIncludeAsDefault,
    )
    const selectedReports = props.libraryReports.filter((x) => selectedReportIds.includes(x.id))
    const isOwnerOfSelected = selectedReports.find((item) => item.createdById !== user?.id) === undefined
    const getAddFromLibraryRequest = (): AddReportFromLibraryRequest => {
        const options = props.addReportModeOptions

        return {
            ...options.selection,
            overrideDashboardScenarioSelection: options.override,
            overlay: options.combineScenarios === false,
            items: selectedReports.map((rpt) => ({ ...rpt, isLibraryReport: false, id: 0 })),
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        try {
            if (props.dialogMode === 'Library') {
                await reportingApi.saveReportsLibrary(props.libraryReports)
                props.closeCallback(DialogResultEnum.Completed)
                return
            }

            if (props.dialogMode === 'ReportSelection') {
                const addFromLibraryRequest = getAddFromLibraryRequest()
                const newReports = await reportingApi.addLibraryReportsToDashboard(addFromLibraryRequest)
                props.closeCallback(DialogResultEnum.Completed, newReports)
                return
            }

            throw Error('unknown dialog mode')
        } catch (err: any) {
            handleApiError(err)
        }
    }

    const toolbarButtons = (
        <div style={{ textAlign: 'right' }}>
            <IconButton
                tooltip="Copy the Selected Report"
                onClick={() => {
                    const lowestId = getLowestId(props.libraryReports)
                    const original = props.libraryReports.find((x) => x.id === selectedReportIds[0]!)!
                    const copy: ReportDialogConfig = { ...original }
                    copy.id = lowestId - 1
                    copy.name = original.name + ' copy'
                    copy.createdByName = user!.name
                    copy.createdById = user!.id
                    copy.shares = []
                    const updatedReports = [...props.libraryReports, copy]
                    props.setLibraryReports(updatedReports)
                }}
                disabled={selectedReportIds.length !== 1}
                toolbarLeftMargin
                icon="bi-files"
            />
            <IconButton
                tooltip="Delete the Selected Report(s)"
                onClick={() => {
                    const updatedReports = props.libraryReports.filter((x) => !selectedReportIds.includes(x.id))
                    props.setLibraryReports(updatedReports)
                    // nothing selected anymore
                    setSelectedReportIds([])
                    setSelectedRowsState({})
                }}
                disabled={selectedReportIds.length === 0 || !(isOwnerOfSelected || user?.isAdministrator)}
                toolbarLeftMargin
                icon="bi-trash"
            />
            <IconButton
                tooltip="Share the Selected Report(s)"
                onClick={() => {
                    const systemReports = selectedReports.filter((x) => x.createdByName.toLowerCase() === 'system')
                    if (systemReports.length > 0) {
                        // cannot share any system reports
                        const systemReportsList = systemReports.map((x) => `<li>${x.name}</li>`).join('')
                        toast.error(
                            `<p>You do not have permission to share the following reports:</p><ul>${systemReportsList}</ul>`,
                        )
                        return
                    }

                    // show the sharing dialog
                    props.setShowShareReportDialogForReportIds(selectedReportIds)
                }}
                disabled={selectedReportIds.length === 0 || !(isOwnerOfSelected || user?.isAdministrator)}
                toolbarLeftMargin
                icon="bi-people"
            />
            <IconButton
                tooltip="Reset the Report Library Back to Defaults"
                onClick={() => {
                    props.setLibraryReports(props.libraryDefaults)
                    // nothing selected anymore
                    setSelectedReportIds([])
                    setSelectedRowsState({})
                }}
                toolbarLeftMargin
                icon="bi-arrow-counterclockwise"
            />
        </div>
    )

    return (
        <Form onSubmit={submitHandler}>
            <Modal.Header closeButton>
                {props.dialogMode === 'Library' && <Modal.Title>Reports Library</Modal.Title>}
                {props.dialogMode === 'ReportSelection' && <Modal.Title>Add Reports to your Dashboard</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {props.dialogMode === 'Library' && <Form.Group>{toolbarButtons}</Form.Group>}
                {props.dialogMode === 'ReportSelection' && (
                    <Form.Group>
                        <FormLabelCustom>Scenarios</FormLabelCustom>
                        <ScenarioSelectorButton
                            showCombineScenariosOption
                            options={props.addReportModeOptions}
                            selectScenariosClicked={props.setShowScenarioSelectionDialog}
                            updateSelections={(updatedOptions: ScenarioSelectionOptions) => {
                                props.setAddReportModeOptions(updatedOptions)
                            }}
                        />
                    </Form.Group>
                )}

                <Form.Group>
                    {props.dialogMode === 'ReportSelection' && <FormLabelCustom>Report Selection</FormLabelCustom>}
                    <KendoGridCustom
                        height="500px"
                        localStorageKeyForColumnState="reportsLibraryGrid"
                        data={props.libraryReports}
                        pageable={false}
                        filterable={false}
                        defaultSort={{ field: 'name', dir: 'asc' }}
                        columns={columns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => {
                            setSelectedReportIds(getSelectedIds(newState))
                            setSelectedRowsState(newState)
                        }}
                        setColumnVisibility={() => {}}
                    />
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <ButtonCustom
                    isLarge
                    type="submit"
                    variant="primary"
                    disabled={props.dialogMode === 'ReportSelection' && selectedReportIds.length === 0}
                >
                    OK
                </ButtonCustom>
                <ButtonCustom
                    isLarge
                    variant="secondary"
                    onClick={() => props.closeCallback(DialogResultEnum.Cancelled)}
                >
                    Cancel
                </ButtonCustom>
            </Modal.Footer>
        </Form>
    )
}

const ReportsLibraryDialog = (props: {
    dialogMode: DialogMode
    scenarios?: ScenarioDetail[]
    libraryReports: ReportDialogConfig[]
    defaultLibraryReports: ReportDialogConfig[]
    reportingMetadata: ReportingMetadata
    setLibraryReports: (reports: ReportDialogConfig[]) => void
    closeCallback: (status: DialogResultEnum, updatedReports?: Report[]) => void
}) => {
    const dispatch = useDispatch()
    const [showScenarioSelectionDialog, setShowScenarioSelectionDialog] = useState(false)
    const [showShareReportDialogForReportIds, setShowShareReportDialogForReportIds] = useState<number[]>([])
    const defaultAddReportOptions: ScenarioSelectionOptions = {
        selection: {
            includeAllMyScenarios: true,
            includeAllScenariosSharedwMe: false,
            selectedScenarioIds: '',
        },
        override: false,
        separateHazardClassPerScenario: false,
        combineScenarios: false,
    }
    const [addReportModeOptions, setAddReportModeOptions] = useState(defaultAddReportOptions)

    // ReportSelection Mode -- choosing scenarios before adding to dashboard
    if (showScenarioSelectionDialog) {
        return (
            <ScenarioSelectorDialog
                scenarios={props.scenarios!}
                initialSelection={addReportModeOptions.selection}
                closeCallback={(state: DialogResultEnum, updatedSelection?: ScenarioSelection) => {
                    if (state === DialogResultEnum.Completed && updatedSelection) {
                        setAddReportModeOptions((previous) => ({ ...previous, selection: updatedSelection }))
                    }
                    setShowScenarioSelectionDialog(false)
                }}
            />
        )
    }

    if (showShareReportDialogForReportIds.length > 0) {
        return (
            <SharingDialog
                itemType="report"
                itemIds={showShareReportDialogForReportIds}
                closeCallback={(status, shares) => {
                    setShowShareReportDialogForReportIds([])
                    if (status === DialogResultEnum.Completed) {
                        // set the state of the selected library reports to have the shares
                        // provided by the sharing dialog
                        const updatedReports = [...props.libraryReports]
                        showShareReportDialogForReportIds.forEach((id) => {
                            const rpt = updatedReports.find((x) => x.id === id)!
                            rpt.shares = shares
                        })
                        props.setLibraryReports(updatedReports)
                    }
                }}
            />
        )
    }

    return (
        <ModalWrapper
            className="DialogMediumWidth"
            closeCallback={() => props.closeCallback(DialogResultEnum.Cancelled)}
        >
            <ReportsLibraryDialogContent
                {...props}
                addReportModeOptions={addReportModeOptions}
                setAddReportModeOptions={(updatedOptions: ScenarioSelectionOptions) =>
                    setAddReportModeOptions(updatedOptions)
                }
                setShowScenarioSelectionDialog={() => setShowScenarioSelectionDialog(true)}
                setShowShareReportDialogForReportIds={setShowShareReportDialogForReportIds}
                libraryDefaults={props.defaultLibraryReports}
                setEditReport={(rpt) =>
                    dispatch(
                        reportsActions.setEditLibraryReport({
                            report: rpt,
                            isUpdated: false,
                        }),
                    )
                }
                libraryReports={props.libraryReports}
                setLibraryReports={props.setLibraryReports}
            />
        </ModalWrapper>
    )
}

export default ReportsLibraryDialog
