import { GridCellProps, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid'
import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { EventDurationRules, EventFilter } from 'types/ProfileInterfaces'
import { getDefaultEventDurationRules } from 'types/ProfileInterfacesDefaults'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import EventFilterDialog from 'views/Common/EventFilter/EventFilterDialog'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import { CellRender, RowRender } from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

interface EventDurationRulesDialogProps {
    onClose: () => void
}

const EventDurationRulesDialog = (props: EventDurationRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const profile = useAppSelector((s) => s.profile.profileWork)
    const [tempRules, setTempRules] = useState<EventDurationRules[]>(profile?.eventDurationRules || [])
    const [tempRule, setTempRule] = useState<EventDurationRules | undefined>()
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showEventFilter, setShowEventFilter] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)
    const [activeField, setActiveField] = useState<string | undefined>()

    // operations
    const doNewRule = (existRecord?: EventDurationRules) => {
        const newRule = getDefaultEventDurationRules(existRecord, tempRules)
        setTempRule(newRule)
        setShowEventFilter(true)
        setSelectedRowsState({ [newRule.id]: true })
    }
    const doCopyRule = () => {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            doNewRule(item)
        }
    }
    const doDeleteRules = () => {
        setShowDialog(deleteDialog)
    }
    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'

    const enterEditHandler = useCallback(
        (dataItem: EventDurationRules, field: string | undefined) => {
            const newList = tempRules.map((item) => ({
                ...item,
                [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
            }))
            setTempRules(newList)
            setActiveField(field)
            setSelectedRowsState({ [dataItem.id]: true })
        },
        [tempRules],
    )

    const exitEditHandler = () => {
        setTempRules(tempRules.map((item) => ({ ...item, [EDIT_FIELD]: undefined })))
        setActiveField(undefined)
    }
    const itemEditedHandler = (event: GridItemChangeEvent) => {
        const field = event.field || ''
        const newList = tempRules.map((item) => {
            if (item.id === event.dataItem.id) {
                return { ...item, [field]: event.value }
            }
            return item
        })
        setTempRules(newList)
    }

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, propsThe: GridCellProps) => (
        <CellRender originalProps={propsThe} td={td} enterEdit={enterEditHandler} editField={EDIT_FIELD} />
    )

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, propsThe: GridRowProps) => (
        <RowRender originalProps={propsThe} tr={tr} exitEdit={exitEditHandler} editField={EDIT_FIELD} />
    )

    const getColumns = useMemo((): Array<KendoGridColumn> => {
        const columns: Array<KendoGridColumn> = [
            { title: 'Brief', field: 'brief' },
            { title: 'Debrief', field: 'debrief' },
            { title: 'Preparation', field: 'prep' },
            { title: 'Commute', field: 'commute' },
            { title: 'Unwind', field: 'unwind' },
        ].map((item) => {
            return {
                title: item.title,
                headerClassName: 'MultiLineCellHeader',
                children: [
                    { title: 'Home', field: 'Home' },
                    { title: 'Hotel', field: 'Hotel' },
                    { title: 'Rest', field: 'RestFacility' },
                ].map((location) => {
                    return {
                        title: location.title,
                        field: item.field + location.field,
                        width: activeField === item.field + location.field ? '100px' : undefined,
                        editor: 'numeric',
                    }
                }),
            }
        })
        columns.unshift({
            title: 'Name',
            field: 'eventFilter.name',
            width: '100px',
            cell: (cellProps: GridCellProps) => {
                const rule = cellProps.dataItem as EventDurationRules
                const cellContent = (
                    <TransparentButton
                        onClick={() => {
                            setTempRule(rule)
                            setShowEventFilter(true)
                        }}
                    >
                        {rule.eventFilter.name}
                    </TransparentButton>
                )
                return FormattedNameCell(cellProps, cellContent)
            },
        })
        return columns
    }, [activeField])

    // event filter
    const handleEventFilterClose = (result: DialogResultEnum, eventFilter?: EventFilter) => {
        if (result === DialogResultEnum.Completed && eventFilter && tempRule) {
            const itemIndex = tempRules.findIndex((x) => x.id === tempRule.id)
            if (itemIndex >= 0) {
                const newList = tempRules.map((s) => {
                    if (s.id === tempRule.id) return { ...s, eventFilter }
                    return s
                })
                setTempRules(newList)
            } else {
                setTempRules([...tempRules, { ...tempRule, eventFilter }])
            }
        }
        setShowEventFilter(false)
    }

    // header
    const headerContent = 'Event Duration Override Rules'

    // grid
    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={doNewRule}
                                onCopyClick={doCopyRule}
                                onDeleteClick={doDeleteRules}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={tempRules}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemEditedHandler}
                    />
                </Col>
            </Row>
        </Container>
    )

    // dialogs
    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Event Duration Rule"
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, tempRules],
    )

    if (!profile) return <></>

    // footer
    const footerContent = (
        <FormFooter
            onCancel={props.onClose}
            onOk={() => {
                // update profile with temp rules
                const updated = { ...profile, eventDurationRules: tempRules }
                dispatch(profileActions.setProfileByType({ profile: updated, type: 'Work', isModified: true }))
                props.onClose()
            }}
        />
    )

    // layout components
    return (
        <>
            {showEventFilter ? (
                <EventFilterDialog eventFilter={tempRule?.eventFilter} onClose={handleEventFilterClose} />
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={props.onClose}>
                    {gridContent}
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default EventDurationRulesDialog
