import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import dateFormatting from 'services/formatting/dateTimeFormatting'
import { FileProcessing, FileProcessingType, LogEntry } from 'types/interfaces'
import classes from 'views/FileProcessing/Components/FileProcessingCard.module.css'

interface Status {
    css: string
    description: string
}

/**
 * Possible states of the File Processing entry
 */
const Statuses = {
    Error: {
        css: 'danger',
        description: 'Failed Due To Errors',
    },
    Success: {
        css: 'success',
        description: 'Completed Successfully',
    },
    SuccessError: {
        css: 'info',
        description: 'Completed With Errors',
    },
    InProgress: {
        css: 'primary',
        description: 'In Progress',
    },
    Expired: {
        css: 'danger',
        description: 'Expired',
    },
    Queued: {
        css: 'primary',
        description: 'Queued',
    },
}

interface FormattedLogEntry {
    id: number
    localLogTime: string
    scheduleId: string | null
    level: string
    logDetail: string
}

interface FormattedFileProcessing {
    status: Status
    durationInProgress: string | null
    durationCompleted: string | null
    localTimeStart: string | null
    localTimeEnd: string | null
    logEntries: Array<FormattedLogEntry>
}

/**
 * Is the job complete?
 */
const isComplete = (entry: FileProcessing) => entry.timeCompleted !== null

/**
 * Get any errors that would have prevented the import from completing
 * @param logEntries
 * @returns
 */
const jobFatalErrors = (logEntries: Array<LogEntry>): Array<LogEntry> => {
    if (!logEntries.length) {
        return []
    }
    return logEntries.filter((msg) => msg.level === 'Error' && msg.logDetail === 'Import failed')
}

/**
 * Are there any errors? [note: import may still complete with errors like overlaps]
 * @param record
 * @returns
 */
const jobHasAnyError = (record: FileProcessing) => {
    const logEntries = record.logEntries
    if (!logEntries) {
        return false
    }
    return (
        logEntries.filter((msg) => {
            return msg.level === 'Error'
        }).length > 0
    )
}

const fileHasBeenDeleted = (unusedRecord: FileProcessing) => {
    // Has file has been purged
    // Only applies to Exports so filter out Import on type field
    if (
        unusedRecord.type === FileProcessingType.Export &&
        unusedRecord.exportFileResult == null &&
        unusedRecord.filename != null
    ) {
        return true
    }
    return false
}

const startedImport = (record: FileProcessing) => {
    const started = record.logEntries.find((item) => {
        return item.logDetail === 'Starting import'
    })
    return started !== undefined
}

/**
 * Get a "status" object used for display
 */
const getStatusObject = (record: FileProcessing): Status => {
    if (jobFatalErrors(record.logEntries).length) {
        return Statuses.Error
    }
    if (fileHasBeenDeleted(record)) {
        return Statuses.Expired
    }
    if (isComplete(record)) {
        return jobHasAnyError(record) ? Statuses.SuccessError : Statuses.Success
    }
    return startedImport(record) ? Statuses.InProgress : Statuses.Queued
}

/**
 * Adjust the raw data so that it is more useful to the displayed view
 * Ie, format dates, calculate status, etc.
 */
const formatData = (fileProcessing: FileProcessing): FormattedFileProcessing => {
    const msToSeconds = (ms: number) => Math.round(ms / 1000)
    const maxDurationInMinutesToShow = 60
    const getDurationString = (seconds: number) => {
        // can get negative seconds when the server clock is ahead
        // of the client clock
        const localSeconds = Math.max(0, seconds)

        if (localSeconds < 60) {
            return '(' + seconds + ' seconds)'
        }

        const minutes = Math.round(localSeconds / 60)
        if (minutes < maxDurationInMinutesToShow) {
            return minutes === 1 ? '(' + minutes + ' minute)' : '(' + minutes + ' minutes)'
        }

        return '(' + maxDurationInMinutesToShow + ' minutes)'
    }

    const status = getStatusObject(fileProcessing)
    let durationInProgress: string | null = null
    let durationCompleted: string | null = null
    let localTimeEnd: string | null = null

    if (isComplete(fileProcessing)) {
        const ms = fileProcessing.timeCompleted!.getTime() - fileProcessing.timeStarted.getTime()
        durationCompleted = getDurationString(msToSeconds(ms))
        const timeEnd = new Date().setTime(fileProcessing.timeStarted.getTime() + ms)
        localTimeEnd = dateFormatting.formatDateTimeLongLocal(timeEnd)
    } else if (status !== Statuses.Error && status !== Statuses.Queued) {
        // in progress
        const ms = new Date().getTime() - fileProcessing.timeStarted.getTime()
        durationInProgress = getDurationString(msToSeconds(ms))
    } else if (status === Statuses.Error && jobFatalErrors(fileProcessing.logEntries).length) {
        // failed
        const fatalErrorTime = jobFatalErrors(fileProcessing.logEntries)[0].time
        const fatalErrorDate = new Date(fatalErrorTime)
        const ms = fatalErrorDate.getTime() - fileProcessing.timeStarted.getTime()
        durationCompleted = getDurationString(msToSeconds(ms))

        const timeEnd = new Date().setTime(fileProcessing.timeStarted.getTime() + ms)
        localTimeEnd = dateFormatting.formatDateTimeLongLocal(timeEnd)
    }

    let localTimeStart = null
    if (fileProcessing.timeStarted) {
        localTimeStart = dateFormatting.formatDateTimeLongLocal(fileProcessing.timeStarted.getTime())
    }

    const formattedFp: FormattedFileProcessing = {
        status,
        durationCompleted,
        durationInProgress,
        localTimeStart,
        localTimeEnd,
        logEntries: fileProcessing.logEntries.map((x) => {
            return {
                id: x.id,
                level: x.level,
                logDetail: x.logDetail,
                scheduleId: x.scheduleId ? `[${x.scheduleId}]` : '',
                localLogTime: dateFormatting.formatDateTimeLongLocal(x.time.getTime()),
            }
        }),
    }
    return formattedFp
}

const getLinkDescriptionForFileExport = (record: FileProcessing) => {
    const fileRemovedDescription = 'File no longer available for download.  You can export again.'
    const fileNotYetAvailableDescription = 'Not yet available'

    let standardDescription = record.description || record.filename
    if (fileHasBeenDeleted(record) === true) {
        standardDescription = fileRemovedDescription
    } else {
        standardDescription = standardDescription || fileNotYetAvailableDescription
    }
    return standardDescription
}

/**
 * Get the title for the card, which is a hyperlink to the scenario or export file
 * @param fp
 * @returns
 */
const getCardTitle = (fp: FileProcessing) => {
    const fpTypeDescription = fp.type === FileProcessingType.Import ? 'Scenario Import:' : 'File Export:'
    const description = fp.type === FileProcessingType.Import ? fp.description : getLinkDescriptionForFileExport(fp)
    const name = (
        <>
            <span>{fpTypeDescription} </span> <em>{description}</em>
        </>
    )

    let url = ''
    // Create Url if the file has not been deleted
    if (fp.timeCompleted && !fileHasBeenDeleted(fp)) {
        url =
            fp.type === FileProcessingType.Import
                ? `/scenario/${fp.scenarioId}/schedules`
                : `/Api/FileProcessing/Download/${fp.id}`
        return fp.type === FileProcessingType.Import ? <Link to={url}>{name}</Link> : <a href={url}>{name}</a>
    }

    return <p style={{ display: 'inline' }}>{name}</p>
}

const FileProcessingCard = (props: { fileProcessingInfo: FileProcessing }) => {
    const fpInfo = props.fileProcessingInfo
    const status = getStatusObject(fpInfo)
    const [showLogs, setShowLogs] = useState(false)

    const cardTitle = (
        <>
            {getCardTitle(fpInfo)}
            <span className={`small text-${status.css}`}> ({status.description})</span>
        </>
    )
    const formattedData = formatData(props.fileProcessingInfo)

    return (
        <div className={`card border-${status.css} mb-3`} style={{ maxWidth: '80rem' }}>
            <div className="card-body cs-card-body">
                <h5 className="card-title">{cardTitle}</h5>
                <div className={`card-text ${classes['cs-card-text']}`}>
                    {formattedData.durationInProgress && (
                        <div>
                            <strong className={classes['cs-card-text-item']}>Started:</strong>
                            <span>{formattedData.localTimeStart}</span>
                            <span> {formattedData.durationInProgress}</span>
                        </div>
                    )}
                    {formattedData.durationCompleted && (
                        <div>
                            <strong className={classes['cs-card-text-item']}>Completed:</strong>
                            <span>{formattedData.localTimeEnd}</span>
                            <span> {formattedData.durationCompleted}</span>
                        </div>
                    )}

                    <div>
                        <strong className={classes['cs-card-text-item']}>File:</strong>
                        <span id="txtFilename">{fpInfo.filename}</span> ({fpInfo.fileSizeInKb} kb)
                    </div>
                    <Button variant="link" onClick={() => setShowLogs(!showLogs)}>
                        Log Messages ({fpInfo.logEntries.length})
                    </Button>
                    {showLogs &&
                        formattedData.logEntries.map((x) => (
                            <div key={x.id}>
                                <small className={x.level === 'Error' ? 'text-danger' : 'text-secondary'}>
                                    {x.localLogTime} ({x.level}) : {x.scheduleId} {x.logDetail}
                                </small>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default FileProcessingCard
