import splashLogin from 'assets/LogInImage.svg'
import sfLogo from 'assets/SFLogoColorWithTag.svg'
import { FormEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import classes from './LoginPage.module.css'

/**
 * Get browser specs.
 * Courtesy of:
 * https://stackoverflow.com/a/38080051
 */
const getBrowserSpecs = () => {
    const ua = navigator.userAgent
    let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return { name: 'IE', version: tem[1] || '' }
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    // eslint-disable-next-line no-cond-assign
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return { name: M[0], version: M[1] }
}

const supportedBrowsers = [
    {
        name: 'Chrome',
        minVersion: '71',
    },
    {
        name: 'Edge',
        minVersion: '79',
    },
    {
        name: 'Firefox',
        minVersion: '79',
    },
]

/**
 * Indicates if the current browser is supported.
 */
const isSupportedBrowser = () => {
    try {
        const browserSpecs = getBrowserSpecs()
        return supportedBrowsers.some(
            (browser) =>
                browserSpecs.name === browser.name && parseInt(browserSpecs.version) >= parseInt(browser.minVersion),
        )
    } catch (error) {
        return false
    }
}

const showPopupForUnsupportedBrowser = () => {
    if (!isSupportedBrowser()) {
        toast.warning(
            <>
                Your browser is not fully supported and some functionality may not work as expected. Supported browsers
                are:
                {supportedBrowsers.map((browser) => (
                    <ul key={browser.name}>
                        <li>{browser.name}</li>
                    </ul>
                ))}
            </>,
            {
                position: 'top-left',
            },
        )
    }
}

function Login(props: { onLogin: (emailAddress: string, password: string, keepMeLoggedIn: boolean) => void }) {
    useEffect(() => {
        showPopupForUnsupportedBrowser()
        document.title = 'SAFTE-FAST - Sign In'
    }, [])

    const [emailAddress, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false)

    const [validated, setValidated] = useState(false)

    const submitHandler = (event: FormEvent<HTMLFormElement>) => {
        // const form = event as HTMLFormElement

        event.preventDefault()
        event.stopPropagation()
        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidated(true)
            return
        }

        props.onLogin(emailAddress, password, keepMeLoggedIn)
    }

    return (
        <div className={classes.loginpage}>
            <div className={classes.loginformcontainer}>
                <div className={`${classes.logo}`}>
                    <img className="d-block img-fluid ml-auto mr-auto mt-5 mb-4" alt="logoc" src={sfLogo} />
                </div>
                <Form className={classes['login-form']} noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Control
                        id="txtLogin"
                        type="text"
                        placeholder="Enter your email address"
                        required
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <div className="invalid-feedback text-left"> Please enter your email address </div>
                    <Form.Control
                        id="txtPassword"
                        type="password"
                        autoComplete="off"
                        placeholder="Enter your password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="invalid-feedback text-left"> Please enter your password </div>
                    <Form.Check
                        id="chkRememberMe"
                        label="Keep me signed in"
                        type="checkbox"
                        className="position-relative mt-3"
                        onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
                    />
                    <Button id="btnLogin" type="submit" variant="primary" className={`${classes.btnLogin} mt-3 mb-3`}>
                        Sign In
                    </Button>

                    <Link to="/setpasswordrequest" className={classes.links}>
                        I forgot my password
                    </Link>
                    <br />
                    <a
                        className={`${classes.links} static-url d-block mb-2`}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.saftefast.com/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    <a
                        className={`${classes.links} static-url d-block mb-2`}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.saftefast.com/terms-of-use"
                    >
                        Terms of Use
                    </a>
                    <p className="text-muted" style={{ fontSize: '13px' }}>
                        © Copyright {new Date().getFullYear()} Institutes for Behavior Resources, Inc.
                    </p>
                </Form>
            </div>

            <div className={classes['login-right-img']}>
                <div className="splash-background" style={{ backgroundImage: `url(${splashLogin})` }} />
                <div className={classes['login-right-img-text-container']} />
            </div>
        </div>
    )
}

export default Login
