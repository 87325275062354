import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import globals from 'services/global/globals'
import { RootState } from 'store/store'
import MetaData from 'types/Metadata'
import { TagKeyValue } from 'types/ScheduleEvent'

import Schedule from 'types/Schedule'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import TagsTable from 'views/Common/Widget/TagsTable'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateExpansionPanel from 'views/Settings/Templates/TemplateExpansionPanel'

const ScheduleDetailPage = () => {
    const { id, operationMode } = useDetailPageParams()
    const api = globals.getApi()
    const [validatedForm, setValidatedForm] = useState(false)
    // const { setEditSchedule, onCloseComponent } = props
    const [scheduleModified, setScheduleModified] = useState(false)
    const [schedule, setSchedule] = useState<any>({ name: '', baseLocation: '', tagCollection: [] })
    const [scheduleTagDefinitions, setScheduleTagDefinitions] = useState<string[]>([])
    const metadata = useSelector<RootState, MetaData>((x) => x.app.metadata!)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onCancelClick = () => {
        if (operationMode === 'Edit') {
            navigate(`/schedule/${id}/view`, { replace: true })
            return
        }
        navigate('/schedules')
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }
    }

    const title = `${operationMode} Schedule`
    const footer = <FormFooter disabledSave={false} onCancel={() => onCancelClick()} />

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            <FormSection title="Details">
                <InputTextFormRow
                    labelText="Name"
                    fieldId="name"
                    subText="A unique name for this schedule"
                    value={schedule?.name}
                    onChange={(event) => {
                        // later
                    }}
                    required
                    invalidText="Enter a unique name"
                />
                <InputTextAreaFormRow
                    labelText="Description"
                    fieldId="description"
                    subText="A description for this schedule"
                    value={schedule?.name}
                    onChange={(event) => {
                        // later
                    }}
                />
                <InputTextFormRow
                    labelText="Base Station"
                    fieldId="baseStation"
                    subText="The station used for initial location and pre-conditioning"
                    value={schedule?.baseLocation}
                    onChange={(event) => {
                        // later
                    }}
                    required
                    invalidText="Enter a valid base station"
                />
            </FormSection>
            <FormSection title="Tags">
                <TagsTable
                    tags={schedule?.tagCollection!}
                    allTagNames={scheduleTagDefinitions}
                    showValidationErrors={validatedForm}
                    updateTags={(tags: TagKeyValue[]) => {
                        setScheduleModified(true)
                        setSchedule((previousSchedule: Schedule | undefined) => {
                            if (!previousSchedule) return previousSchedule
                            return {
                                ...previousSchedule,
                                scheduleTags: tags,
                            }
                        })
                    }}
                />
            </FormSection>
            <FormSection title="Template" padding={0}>
                <TemplateExpansionPanel onChange={() => setScheduleModified(true)}>
                    <ProfilesSelectorPanel />
                </TemplateExpansionPanel>
            </FormSection>
        </FormPage>
    )
}

export default ScheduleDetailPage
