import { ReactNode } from 'react'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'

interface FormFooterProps {
    contentLeft?: ReactNode
    disabledSave?: boolean
    onCancel: () => void
    onOk?: () => void
}

const FormFooter = (props: FormFooterProps) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: '1200px',
                margin: 'auto',
                paddingRight: '20px',
            }}
        >
            <div style={{ flex: 1, paddingRight: '20px' }}>{props.contentLeft && <span>{props.contentLeft}</span>}</div>

            <div style={{ display: 'flex', gap: '5px' }}>
                <ButtonCustom
                    isLarge
                    type="submit"
                    variant="primary"
                    disabled={props.disabledSave}
                    onClick={() => props.onOk?.()}
                >
                    OK
                </ButtonCustom>
                <ButtonCustom isLarge toolbarLeftMargin variant="secondary" onClick={() => props.onCancel?.()}>
                    Cancel
                </ButtonCustom>
            </div>
        </div>
    )
}

export default FormFooter
