import { Grid, GridRowProps } from '@progress/kendo-react-grid'
import useGridLayout from 'hooks/useGridLayout'
import usePalettes from 'hooks/usePalettes'
import { every, flatten, uniq } from 'lodash'
import React, {
    CSSProperties,
    memo,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getFilterFields, scrollToRow } from 'services/utilities/kendoGridUtils'
import gridLayoutActions from 'store/actions/gridLayoutActions'
import ScheduleDetailsContext from 'store/scheduleDetailsContext'
import { RootState, useAppDispatch } from 'store/store'
import { KendoGridColumn } from 'types/GridLayout'
import { ScheduleDetailsViewMode, SleepQualityEnum } from 'types/interfaces'
import Schedule from 'types/Schedule'
import ScheduleEvent, { ScheduleEventType } from 'types/ScheduleEvent'
import ButtonCustom from 'views/Common/Buttons/ButtonCustom'
import IconButtonColumns from 'views/Common/Buttons/IconButtonColumns'
import IconButtonFilter from 'views/Common/Buttons/IconButtonFilter'
import SeperatorVertical from 'views/Common/Buttons/SeparatorVertical'
import KendoGridCustom, { SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import {
    ClickEditTimeParams,
    CrewingFilterFalse,
    CrewingFilterTrue,
    CriticalFilterFalse,
    CriticalFilterTrue,
    getColumns,
} from 'views/Schedules/ScheduleDetails/Components/ScheduleEventsTableColumns'
import classes from './ScheduleEventsTable.module.css'
import {
    AddScheduleEventArgs,
    DragActionDuty,
    DragActionMarker,
    DragActionSleep,
    DragActionWork,
    getDutyEndRowCssClassName,
    getDutyStartRowCssClassName,
    onDragLeaveScheduleEventHandler,
    onDragOverScheduleEventHandler,
    onDropOverScheduleEvent,
} from './ScheduleEventsTableDragDrop'

export const DraggableEventsTableButton = 'EventTableButton'

const getAllTagNames = (events: ScheduleEvent[]) => {
    return uniq(flatten(events.map((x) => x.eventTags)).reduce<string[]>((prev, cur) => [...prev, cur.name], []))
}

const ScheduleEventsTable = (props: {
    schedule: Schedule
    events: ScheduleEvent[]
    gridHeight: number
    readonly: boolean
    clickedEvent: (scheduleEvent: ScheduleEvent) => void
    clickedEditTime: (args: ClickEditTimeParams) => void
    zoomStart?: Date
}) => {
    const gridRef = useRef<Grid>(null)
    const toolBarRef = useRef<HTMLDivElement>(null)
    const [zoomedToDate, setZoomedToDate] = useState<Date | undefined>(undefined)
    const [collapsedDutyIds, setCollapsedDutyIds] = useState<string[]>([])
    const palettes = usePalettes()

    const eventsAllTagFields = useMemo(() => {
        return getAllTagNames(props.events)
    }, [props.events])

    const [activeGridLayoutWithTags] = useGridLayout('events', eventsAllTagFields)
    const enableFiltering = activeGridLayoutWithTags?.configurationJson.filtersEnabled || false
    const filteredFields = getFilterFields(
        activeGridLayoutWithTags?.configurationJson?.dataState?.filter?.filters || [],
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.zoomStart && props.zoomStart !== zoomedToDate) {
            const eventsAfterZoomStart = props.events.filter((x) => x.getStartMs() >= props.zoomStart!.getTime())
            if (eventsAfterZoomStart.length) {
                scrollToRow(gridRef, props.events.indexOf(eventsAfterZoomStart[0]) + 1)
            } else if (every(props.events, (evt: ScheduleEvent) => evt.getStartMs() < props.zoomStart!.getTime())) {
                // zoom was set after all events
                scrollToRow(gridRef, props.events.length)
            }
            setZoomedToDate(props.zoomStart)
        }
    }, [props.zoomStart, props.events, zoomedToDate])

    const getColumnsHelper = useCallback(
        () => {
            if (!palettes) {
                return []
            }
            return getColumns(
                props.schedule,
                props.events,
                palettes,
                collapsedDutyIds,
                props.clickedEvent,
                setDutyCollapsedState,
                props.clickedEditTime,
                activeGridLayoutWithTags,
                props.readonly,
            )
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [activeGridLayoutWithTags, props.clickedEvent, props.events, props.schedule, JSON.stringify(collapsedDutyIds)],
        /* eslint-enable react-hooks/exhaustive-deps */
    )

    const scheduleDetailsContext = useContext(ScheduleDetailsContext)
    const setDutyCollapsedState = (state: 'expand' | 'collapse', dutyId: string) => {
        setCollapsedDutyIds((originalSet) => {
            let cpy = [...originalSet]
            if (state === 'expand') {
                // remove if exists in the set
                if (cpy.includes(dutyId)) {
                    cpy = cpy.filter((x) => x !== dutyId)
                }
            } else {
                // add to the set
                cpy.push(dutyId)
            }
            return cpy
        })
    }

    const isPrintPreview = useSelector<RootState, boolean>((x) => x.app.isPrintPreview)
    const [stateColumns, setStateColumns] = useState<Array<KendoGridColumn>>(() => getColumnsHelper())
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const [showGridColumnPicker, setShowGridColumnPicker] = useState(false)

    // the currently selected event ids
    // filter event ids that no longer exist in the dataset
    const getSelectedEventIds = (state: SelectionState) =>
        Object.keys(state).filter((key) => state[key] === true && props.events.some((ev) => ev.id.toString() === key))

    const getEventsFromIds = (ids: string[]) => props.events.filter((evt) => ids.includes(evt.id.toString()))

    /**
     * Run logic for selecting/de-selecting rows.
     * @param state
     */
    const setSelectedRowsStateCustom = (state: SelectionState) => {
        setSelectedRowsState((previousState) => {
            const previouslySelectedEventIds = getSelectedEventIds(previousState)
            const currentlySelectedIds = getSelectedEventIds(state)
            const newlySelectedIds = currentlySelectedIds.filter((x) => !previouslySelectedEventIds.includes(x))
            const newlySelectedEvents = getEventsFromIds(newlySelectedIds)
            const newlySelectedDuties = newlySelectedEvents.filter((x) => x.isDutyRollup())
            const newlySelectedNonEditableEvents = newlySelectedEvents.filter(
                (x) => !x.isEditable() && !x.isDutyRollup(),
            )
            const newlyDeselectedIds = previouslySelectedEventIds.filter((x) => !currentlySelectedIds.includes(x))
            const newlyDeselectedDutyEvents = getEventsFromIds(newlyDeselectedIds).filter((x) =>
                x.isPartOfDuty(props.events),
            )
            const newlyDeselectedDutyRollups = getEventsFromIds(newlyDeselectedIds).filter((x) => x.isDutyRollup())

            // if a duty rollup event is deselected, deselect the duty events
            newlyDeselectedDutyRollups.forEach((duty) => {
                const allDutyEvents = props.events.filter((x) => x.dutyUuid === duty.dutyUuid)
                allDutyEvents.forEach((dutyEvt) => {
                    state[dutyEvt.id] = false
                })
            })

            // if a duty event is  deselected, deselect the duty
            newlyDeselectedDutyEvents.forEach((dutyEvt) => {
                const dutyRollupEvent = props.events.find((x) => x.dutyUuid === dutyEvt.dutyUuid && x.isDutyRollup())
                if (dutyRollupEvent && !newlySelectedIds.includes(dutyRollupEvent.id.toString())) {
                    state[dutyRollupEvent.id.toString()] = false
                }
            })

            // if a duty is selected now, select all duty events
            newlySelectedDuties.forEach((duty) => {
                const allDutyEvents = props.events.filter((x) => x.dutyUuid === duty.dutyUuid)
                allDutyEvents.forEach((dutyEvt) => {
                    if (!currentlySelectedIds.includes(dutyEvt.id.toString())) {
                        state[dutyEvt.id] = true
                    }
                })
            })

            // non-editable events (other than duties) cannot be selected
            newlySelectedNonEditableEvents.forEach((nonEditableEvent) => {
                state[nonEditableEvent.id.toString()] = false
            })

            return state
        })
    }

    // update columns when things change
    useEffect(() => {
        setStateColumns(getColumnsHelper())
    }, [
        enableFiltering,
        activeGridLayoutWithTags,
        collapsedDutyIds,
        props.clickedEvent,
        props.schedule,
        props.events,
        getColumnsHelper,
    ])

    // calculate height based on view both (both has less vertical space), Both and Table
    // need to leave space for buttons below
    let calculatedGridHeight = 0

    // prep the data for binding to the grid.
    // Filter and add any computed fields as necessary to make binding easier.
    const preparedData = props.events
        .filter((x) => !collapsedDutyIds.includes(x.dutyUuid || '') || x.isDutyRollup())
        .map((x) => {
            return {
                ...x,
                crewing_description: x.crewing ? CrewingFilterTrue : CrewingFilterFalse,
                critical_description: x.critical ? CriticalFilterTrue : CriticalFilterFalse,
                quality_description: x.quality ? SleepQualityEnum[x.quality].toString() : '',
                plannedWorkSleepQuality_description: x.plannedWorkSleepQuality
                    ? SleepQualityEnum[x.plannedWorkSleepQuality].toString()
                    : '',
                start_formatted: new Date(x.getStartMsForDisplay(props.schedule.viewSettings.timeMode)),
                end_formatted: new Date(x.getEndMsForDisplay(props.schedule.viewSettings.timeMode)),
                dutyEASANightType_description:
                    x.dutyEASANightType === null || x.dutyEASANightType === 'None'
                        ? ''
                        : x.dutyEASANightType!.toString(),
                dutyCircadianSwap_description: x.dutyCircadianSwap !== null && x.dutyCircadianSwap ? 'Yes' : '',
            }
        })

    const gridClass = `${classes.eventsTable} mt-2 mb-2`
    if (!isPrintPreview) {
        const toolbarHeight = toolBarRef.current ? toolBarRef.current.clientHeight : 47
        calculatedGridHeight =
            props.schedule.viewSettings.viewMode === ScheduleDetailsViewMode.Both
                ? props.gridHeight - (268 + toolbarHeight)
                : props.gridHeight - 50
    }

    const selectedEventIds = getSelectedEventIds(selectedRowsState)
    const selectedEvents = props.events.filter((evt) => selectedEventIds.includes(evt.id.toString()))
    const buttonVariant = 'secondary'

    const dragTimerEl = useRef<HTMLInputElement>(null)
    // normally in drag/drop you use evt.dataTransfer.setData, but it doesn't work right in ie11
    const dragActionEl = useRef<HTMLInputElement>(null)
    // tracks the state of the drag lines
    const dragLinesShownEl = useRef<HTMLInputElement>(null)

    const getScheduleEventTypeFromDragAction = (dragAction: string) => {
        let eventDataType: ScheduleEventType = 'Duty'
        if (dragAction !== DragActionDuty) {
            if (dragAction === DragActionMarker) {
                eventDataType = 'Marker'
            } else if (dragAction === DragActionWork) {
                eventDataType = 'Work'
            } else if (dragAction === DragActionSleep) {
                eventDataType = 'ExplicitSleep'
            }
        }
        return eventDataType
    }

    const getNewScheduleEvent = (
        eventType: ScheduleEventType,
        dutyUuid?: string,
        timeLocation?: {
            from: string
            to: string
            time: Date
            tzToMinutes: number
            tzFromMinutes: number
            tzBaseMinutes: number
        },
    ) => {
        return ScheduleEvent.newScheduleEventByType(
            dutyUuid,
            eventType,
            props.events,
            props.schedule.id,
            props.schedule.modified,
            props.schedule.scenarioParameters.eventLabel,
            timeLocation,
        )
    }

    /**
     * Handle "drop" of an item on a row
     */
    const dragDropHandler = (scheduleEvent: ScheduleEvent, allEvents: ScheduleEvent[], rowIndex: number) => {
        const dragAction = dragActionEl.current!.value
        /**
         * respond to the drag/drop logic to actually show the new event dialog
         */
        const addScheduleEventHandler = (args: AddScheduleEventArgs) => {
            const scheduleEventType = getScheduleEventTypeFromDragAction(dragAction)
            const newEvent = getNewScheduleEvent(scheduleEventType, args.dutyUuid, { ...args })
            newEvent.dutyLabel = args.dutyLabel
            scheduleDetailsContext.setChangedGridRowIndex(rowIndex)
            scheduleDetailsContext.addNewEventViaDialog(newEvent)
        }

        let referenceEvent = scheduleEvent
        if (referenceEvent.isDutyRollup()) {
            // user dropped event after a collapsed duty; so we need to use
            // the last event in the duty as the point of reference, not the duty row.
            const dutyEvents = referenceEvent.getDutyEvents(allEvents)
            referenceEvent = dutyEvents[dutyEvents.length - 1]
        }
        onDropOverScheduleEvent(dragAction, dragLinesShownEl, referenceEvent, props.events, addScheduleEventHandler)
    }

    /**
     * Generate custom kendo row that supports our drag/drop system
     */
    const gridRowRenderHandler = (trElement: ReactElement<HTMLTableRowElement>, rowProps: GridRowProps) => {
        const scheduleEvent = rowProps.dataItem as ScheduleEvent
        // drag over rows in the table is handled in here.
        const onDragOver = (evt: React.DragEvent) =>
            onDragOverScheduleEventHandler(
                evt,
                dragActionEl,
                dragLinesShownEl,
                collapsedDutyIds,
                parseInt(dragTimerEl.current!.value),
                scheduleEvent,
                props.events,
            )

        let trProps: {
            id: string
            'data-uuid': string
            style: CSSProperties
            onDragOver: (evt: React.DragEvent) => void
            onDragLeave: (evt: React.DragEvent) => void
            onDrop: (evt: React.DragEvent) => void
            className: string
        } = {
            // Assign the id which is used by drag and drop
            id: scheduleEvent.id.toString(),
            'data-uuid': scheduleEvent.uuid!.toString(),
            style: {},
            onDragOver,
            onDrop: () => dragDropHandler(scheduleEvent, props.events, rowProps.dataIndex),
            onDragLeave: () => onDragLeaveScheduleEventHandler(dragTimerEl, dragLinesShownEl),
            className: trElement.props.className,
        }

        // special rendering for the Duty row
        if (scheduleEvent.isDutyRollup()) {
            trProps = {
                ...trProps,
                style: {
                    backgroundColor: '#ddd',
                },
                // special css class to identify rows of the duty for drag/drop
                className: trProps.className + ` ${getDutyStartRowCssClassName(scheduleEvent)}`,
            }
        }
        if (scheduleEvent.isLastEventInDuty(props.events)) {
            trProps = {
                ...trProps,
                // special css class to identify rows of the duty for drag/drop
                className: trProps.className + ` ${getDutyEndRowCssClassName(scheduleEvent)}`,
            }
        }
        return React.cloneElement(trElement, { ...(trProps as any) }, <>{trElement.props.children}</>)
    }

    return (
        <>
            <input ref={dragTimerEl} type="hidden" />
            <input ref={dragActionEl} type="hidden" />
            <input ref={dragLinesShownEl} type="hidden" />

            {!isPrintPreview && (
                <>
                    <Container fluid ref={toolBarRef}>
                        <Row className="aboveMainSection">
                            <Col sm={12} className="mt-3">
                                <Row className="justify-content-between">
                                    <Col className="col-auto" />
                                    <Col className="auto text-end">
                                        {!props.readonly && (
                                            <>
                                                <ButtonCustom
                                                    toolbarLeftMargin
                                                    draggable
                                                    size="sm"
                                                    className={classes.tableButtonGrab}
                                                    variant={buttonVariant}
                                                    onDragStart={() => {
                                                        dragActionEl.current!.value = DragActionDuty
                                                    }}
                                                    onClick={() => {
                                                        scheduleDetailsContext.addNewEventViaDialog(
                                                            getNewScheduleEvent('Duty'),
                                                        )
                                                    }}
                                                    disabled={props.readonly}
                                                    tooltip="Click or Drag into the Table to Add a Duty Period"
                                                >
                                                    Add Duty
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    toolbarLeftMargin
                                                    draggable
                                                    size="sm"
                                                    className={classes.tableButtonGrab}
                                                    variant={buttonVariant}
                                                    onDragStart={() => {
                                                        dragActionEl.current!.value = DragActionWork
                                                    }}
                                                    // onClick={() => {
                                                    //     scheduleDetailsContext.addNewEventViaDialog(getNewScheduleEvent('Work'))
                                                    // }}
                                                    disabled={props.readonly}
                                                    tooltip="Drag into the Table to Add a Work Event"
                                                >
                                                    Add Work
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    toolbarLeftMargin
                                                    draggable
                                                    size="sm"
                                                    className={classes.tableButtonGrab}
                                                    variant={buttonVariant}
                                                    onDragStart={() => {
                                                        dragActionEl.current!.value = DragActionMarker
                                                    }}
                                                    // onClick={() => {
                                                    //     scheduleDetailsContext.addNewEventViaDialog(getNewScheduleEvent('Marker'))
                                                    // }}
                                                    disabled={props.readonly}
                                                    tooltip="Drag into the Table to Add a Marker"
                                                >
                                                    Add Marker
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    toolbarLeftMargin
                                                    draggable
                                                    size="sm"
                                                    className={classes.tableButtonGrab}
                                                    variant={buttonVariant}
                                                    onDragStart={() => {
                                                        dragActionEl.current!.value = DragActionSleep
                                                    }}
                                                    // onClick={() => {
                                                    //     scheduleDetailsContext.addNewEventViaDialog(getNewScheduleEvent('ExplicitSleep'))
                                                    // }}
                                                    disabled={props.readonly}
                                                    tooltip="Drag into the Table to Add an Explicit Sleep"
                                                >
                                                    Add Sleep
                                                </ButtonCustom>

                                                <ButtonCustom
                                                    toolbarLeftMargin
                                                    size="sm"
                                                    className={classes.tableButton}
                                                    style={{
                                                        cursor:
                                                            selectedEventIds.length === 0 || props.readonly
                                                                ? ''
                                                                : 'pointer',
                                                    }}
                                                    variant="danger"
                                                    onClick={() => {
                                                        scheduleDetailsContext.setDeleteEvents(selectedEvents)
                                                    }}
                                                    disabled={selectedEventIds.length === 0 || props.readonly}
                                                    tooltip={
                                                        selectedEventIds.length === 0
                                                            ? 'Select Event(s) from the Table to Delete'
                                                            : 'Delete Selected Event(s)'
                                                    }
                                                >
                                                    Delete
                                                </ButtonCustom>
                                                <SeperatorVertical />
                                            </>
                                        )}
                                        <IconButtonColumns onClick={() => setShowGridColumnPicker(true)} />
                                        <IconButtonFilter
                                            filterEnabled={enableFiltering}
                                            filterIsActive={enableFiltering && filteredFields.length > 0}
                                            onClick={() =>
                                                dispatch(
                                                    gridLayoutActions.setGridFilteringEnabled(
                                                        'events',
                                                        !enableFiltering,
                                                    ),
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}

            <KendoGridCustom
                gridRef={gridRef}
                gridLayoutName="events"
                activeGridLayoutCustomized={activeGridLayoutWithTags}
                noSelection={isPrintPreview || props.readonly}
                scrollable={isPrintPreview ? 'none' : 'scrollable'}
                className={gridClass}
                centeredContent
                sortable={false}
                pageable={false}
                columnMenuFiltering={enableFiltering}
                height={calculatedGridHeight > 0 ? `${calculatedGridHeight}px` : undefined}
                data={preparedData}
                dataState={activeGridLayoutWithTags?.configurationJson.dataState}
                rowRender={gridRowRenderHandler}
                expandField="expanded"
                columns={stateColumns}
                alwaysShowColumns={props.schedule.viewSettings.columnOverrides
                    .filter((x) => x.isChecked && !x.isShown)
                    .map((x) => x.field)}
                selectedRowsState={selectedRowsState}
                onSetSelectedRowsState={setSelectedRowsStateCustom}
                setColumnVisibility={(newColumnState: KendoGridColumn[]) => setStateColumns(newColumnState)}
                showColumnPicker={showGridColumnPicker}
                onColumnPickerHide={() => setShowGridColumnPicker(false)}
                onRowDoubleClick={(e) => props.clickedEvent(e.dataItem as ScheduleEvent)}
            />
        </>
    )
}

export default memo(ScheduleEventsTable)
