import { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid'
import { Link } from 'react-router-dom'
import dateTimeFormatting from 'services/formatting/dateTimeFormatting'
import { GridLayout, KendoGridColumn, KendoGridColumnCustomAttributes } from 'types/GridLayout'
import { Duty } from 'types/HazardClassCase'
import { ColorPalettes, Station, User } from 'types/interfaces'
import TemplateDetail from 'types/TemplateDetail'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import { ColumnMenuWithFilter } from 'views/Common/Kendo/CustomColumnMenu'
import {
    FormattedHazardClassPatternDutyMetricCell,
    FormattedScenarioMetricCell,
    FormattedScheduleMetricCell,
} from 'views/Common/Kendo/FormattedMetricCell'
import {
    createFormattedMetricHeaderCell,
    FormattedNameCell,
    FormattedScenarioNameCell,
    FormattedScheduleNameCell,
    getLink,
} from 'views/Common/Kendo/FormattedNameCell'
import StationsNavigationLink, {
    StationsNavigationLinkProps,
} from 'views/Common/Kendo/GridCellComponents/StationsNavLink'
import { getFilterFields } from './kendoGridUtils'

export const isTagColumn = (column: KendoGridColumn) => column.field!.startsWith('tagValues.')

export const getHeaderCellClass = (fieldName: string, filteredFields: string[], defaultClass?: string) => {
    const defaultVal = defaultClass ?? ''
    return filteredFields.includes(fieldName) ? `activeFilter ${defaultVal}` : defaultClass
}

interface StationsGridOptions {
    stationClicked: (station: Station, dataIndex: number) => void
}

interface ScenariosGridOptions {
    palettes: ColorPalettes
    sharingIconClicked: (scenarioId: number) => void
}

interface HazardClassGridOptions {
    palettes: ColorPalettes
}

interface SchedulesGridOptions {
    isForScenarioExport?: boolean
    palettes: ColorPalettes
    scheduleErrorClicked?: () => void
    scheduleShareIconClicked?: (scheduleId: number) => void
}

interface UserGridOptions {
    userClicked: (user: User) => void
    customFields: string | null
}

export interface GetColumnsFromGridLayoutArgs {
    gridLayout: GridLayout | undefined
    stationsGridOptions?: StationsGridOptions
    scenariosGridOptions?: ScenariosGridOptions
    schedulesGridOptions?: SchedulesGridOptions
    hazardClassGridOptions?: HazardClassGridOptions
    usersGridOptions?: UserGridOptions
}

const applyStationsGridOptions = (
    column: KendoGridColumn,
    options: StationsGridOptions | undefined,
    customizations: KendoGridColumnCustomAttributes,
) => {
    if (!options) {
        throw new Error('stationsGridOptions is required for stations grid')
    }
    const { stationClicked } = options
    const { hasStationNavLink } = customizations
    if (hasStationNavLink) {
        column.cell = (props: GridCellProps) => {
            const stationsNavProps: StationsNavigationLinkProps = {
                ...props,
                stationClicked,
            }
            return <StationsNavigationLink {...stationsNavProps} />
        }
    }
}

const applyScenarioGridOptions = (
    column: KendoGridColumn,
    options: ScenariosGridOptions | undefined,
    customizations: KendoGridColumnCustomAttributes,
) => {
    if (!options) {
        throw new Error('scenariosGridOptions is required for scenarios grid')
    }
    const { palettes, sharingIconClicked } = options
    const { customCellType } = customizations
    if (customCellType === 'scenarioName') {
        column.cell = (props: GridCellProps) => FormattedScenarioNameCell(props, sharingIconClicked)
    } else if (customCellType === 'formattedScenarioMetric') {
        column.cell = (props: GridCellProps) => FormattedScenarioMetricCell(props, palettes)
    }
}

const applyScheduleGridOptions = (
    column: KendoGridColumn,
    options: SchedulesGridOptions | undefined,
    customizations: KendoGridColumnCustomAttributes,
) => {
    if (!options) {
        throw new Error('SchedulesGridOptions is required for scenarios grid')
    }
    const { customCellType } = customizations
    const { palettes, scheduleErrorClicked = () => {}, scheduleShareIconClicked = () => {} } = options

    if (customCellType === 'formattedScenarioMetric') {
        column.cell = (props: GridCellProps) => FormattedScenarioMetricCell(props, palettes)
    } else if (customCellType === 'formattedScheduleMetric') {
        column.cell = (props: GridCellProps) => FormattedScheduleMetricCell(props, palettes)
    } else if (customCellType === 'scheduleName') {
        column.cell = (props: GridCellProps) =>
            FormattedScheduleNameCell(props, scheduleErrorClicked, scheduleShareIconClicked)
    }
}

const applyIsForScenarioExportToSchedulesGrid = (columns: KendoGridColumn[]) => {
    if (!columns.find((col) => col.field === 'name')) {
        // the user hid the name on the ui but it should be exported, so add it back in
        columns.unshift({ field: 'name', title: 'Name' })
    }

    columns.forEach((col, index) => {
        let format = null
        let displayText = col.title
        if (col.filter === 'date') {
            // server will always export in UTC, so we need to put that into the
            // header of the CSV to make it clear that it isn't the same as what is
            // show in the UI, which is local
            displayText += ' (UTC)'
            format = dateTimeFormatting.getKendoDateTimeFormat()
        }

        return { ...col, index, displayText, format }
    })
}

const applyHazardClassCasActualDutiesGridOptions = (
    column: KendoGridColumn,
    options: HazardClassGridOptions | undefined,
    customizations: KendoGridColumnCustomAttributes,
) => {
    const { customCellType, hazardClassMetricPaletteType } = customizations
    if (customCellType === 'hazardClassDutiesScheduleName') {
        column.cell = (cellProps: GridCellProps) => {
            const duty = cellProps.dataItem as Duty
            return (
                <td>
                    <Link className="linkStyle" to={`/schedule/${duty.scheduleId}/view`}>
                        {duty.scheduleName}
                    </Link>
                </td>
            )
        }
    } else if (customCellType === 'hazardClassDutiesFormattedMetric') {
        if (!hazardClassMetricPaletteType) {
            throw Error('hazardClassMetricPaletteType is required for hazardClassDutiesFormattedMetric')
        }
        if (!options) {
            throw Error('HazardClassGridOptions is required for hazardClassDutiesFormattedMetric')
        }
        const { palettes } = options
        column.cell = (cellProps: GridCellProps) => {
            return FormattedHazardClassPatternDutyMetricCell(cellProps, hazardClassMetricPaletteType, palettes)
        }
    }
}

const applyUsersGridOptions = (
    column: KendoGridColumn,
    options: UserGridOptions | undefined,
    customizations: KendoGridColumnCustomAttributes,
) => {
    if (!options) {
        throw new Error('UserGridOptions is required for users grid')
    }
    const { customCellType } = customizations
    const { userClicked } = options

    if (customCellType === 'usersListName') {
        column.cell = (props: GridCellProps) => {
            const user = props.dataItem as User
            return (
                <td>
                    <TransparentButton
                        onClick={() => {
                            userClicked(user)
                        }}
                    >
                        {user.name}
                    </TransparentButton>
                </td>
            )
        }
    }
}

const applyUsersGridCustomFields = (columns: KendoGridColumn[], customFields: string, filteredFields: string[]) => {
    customFields.split(',').forEach((field, index) => {
        const fieldName = field.split(':')[0]
        const fieldLabel = field.split(':')[1]
        columns.splice(3 + index, 0, {
            field: fieldName,
            title: fieldLabel,
            filter: 'text',
            headerClassName: getHeaderCellClass(fieldLabel, filteredFields),
            columnMenu: ColumnMenuWithFilter,
        })
    })
}

const applyTemplatesGridOptions = (column: KendoGridColumn, customizations: KendoGridColumnCustomAttributes) => {
    const { hasTemplateDetailLink } = customizations
    if (hasTemplateDetailLink) {
        column.cell = (props: GridCellProps) => {
            const template = props.dataItem as TemplateDetail
            const url = `/settings/templates/${template.id}`
            const cellContent = (
                <>
                    {getLink(url, template.name)}
                    {template.isDefault && (
                        <i className="grid-button bi-check-square-fill" style={{ marginLeft: '8px' }} />
                    )}
                </>
            )
            return FormattedNameCell(props, cellContent)
        }
    }
}

/**
 * main function to get columns from grid layout.
 * @param options
 * @returns
 */
const getColumnsFromGridLayout = (options: GetColumnsFromGridLayoutArgs) => {
    const { gridLayout } = options
    if (!gridLayout) {
        return []
    }

    const enableFiltering = gridLayout?.configurationJson.filtersEnabled || false
    const filteredFields = getFilterFields(gridLayout.configurationJson.dataState?.filter?.filters || [])

    const columns = gridLayout.configurationJson.columns.map((column) => {
        const customizedColumn = { ...column }
        const { customizations } = column

        customizedColumn.headerClassName = getHeaderCellClass(column.field!, filteredFields)

        if (customizations) {
            const { hasCustomHeaderCell, dataContext, hasColumnMenuWithFilter, customFormat } = customizations

            if (hasCustomHeaderCell) {
                customizedColumn.headerCell = (props: GridHeaderCellProps) =>
                    createFormattedMetricHeaderCell(props, customizedColumn.title!, dataContext, !enableFiltering)
            }

            if (hasColumnMenuWithFilter) {
                customizedColumn.columnMenu = ColumnMenuWithFilter
            }

            if (customFormat === 'date') {
                customizedColumn.format = dateTimeFormatting.getKendoGridDateFormat()
            }

            if (gridLayout.gridId === 'stations') {
                applyStationsGridOptions(customizedColumn, options.stationsGridOptions, customizations)
            } else if (gridLayout.gridId === 'scenarios') {
                applyScenarioGridOptions(customizedColumn, options.scenariosGridOptions, customizations)
            } else if (gridLayout.gridId === 'schedules') {
                applyScheduleGridOptions(customizedColumn, options.schedulesGridOptions, customizations)
            } else if (gridLayout.gridId === 'hazardClassDuties') {
                applyHazardClassCasActualDutiesGridOptions(
                    customizedColumn,
                    options.hazardClassGridOptions,
                    customizations,
                )
            } else if (gridLayout.gridId === 'users') {
                applyUsersGridOptions(customizedColumn, options.usersGridOptions, customizations)
            } else if (gridLayout.gridId === 'templates') {
                applyTemplatesGridOptions(customizedColumn, customizations)
            }
        }

        return customizedColumn
    })

    if (gridLayout.gridId === 'schedules' && options.schedulesGridOptions?.isForScenarioExport) {
        applyIsForScenarioExportToSchedulesGrid(columns)
    }

    if (gridLayout.gridId === 'users' && options.usersGridOptions?.customFields) {
        applyUsersGridCustomFields(columns, options.usersGridOptions.customFields, filteredFields)
    }

    return columns
}

export { getColumnsFromGridLayout }
