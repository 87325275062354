import { useSystemSettings } from 'hooks/useSettingValue'
import { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { SettingValue, SystemSetting } from 'types/SystemSetting'
import { FormControlChangeEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import FormSection from 'views/Common/Form/FormSection'
import GeneralSettingList from './GeneralSettingConfig'
import Settings from './Settings'

const GeneralSetting = () => {
    const [isChanged, setIsChanged] = useState<Boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)

    const [settings, setSettings] = useSystemSettings('General')

    const api = globals.getApi()

    useEffect(() => {
        document.title = 'SAFTE-FAST - General Settings'
    })

    const handleOnChange = (e: FormControlChangeEvent) => {
        const list = [...settings]
        const index = list.findIndex((s) => s.name === e.target.name)
        if (index >= 0) {
            list[index].value = e.target.value
            setSettings(list)
            setIsChanged(true)
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            return
        }

        const settingValues: SettingValue[] = []
        settings.forEach((item) => {
            settingValues.push({ name: item.name, value: item.value })
        })
        await api.getSystemSettingApi().updateSystemSettings(settingValues)

        setIsChanged(false)
        toast.success('General Settings have been Updated.')
    }
    const cancelHandler = () => {
        // todo
    }

    const displaySettings = GeneralSettingList.map((item) => {
        const list: SystemSetting[] = []
        item.items.forEach((element) => {
            const setting = settings.find((s) => s.name === element)
            if (setting) list.push(setting)
        })
        return (
            <FormSection title={item.section} key={item.section}>
                <Settings items={list} onChange={handleOnChange} />
            </FormSection>
        )
    })

    const title = 'General'
    const footer = <FormFooter disabledSave={!isChanged} onCancel={() => cancelHandler()} />

    return (
        <FormPage headingContent={title} footerContent={footer} validatedForm={validatedForm} onSubmit={submitHandler}>
            {displaySettings}
        </FormPage>
    )
}

export default GeneralSetting
