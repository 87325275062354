import { GridCellProps } from '@progress/kendo-react-grid'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MoveDirection, reorderItemsList } from 'services/utilities/arrayUtils'
import { profileActions } from 'store/profileStore'
import { useAppDispatch } from 'store/store'
import { EventFilter, EventRules, ProfileAutoSleep } from 'types/ProfileInterfaces'
import { getDefaulEventRules } from 'types/ProfileInterfacesDefaults'
import TransparentButton from 'views/Common/Buttons/TransparentButton'
import EventFilterDialog from 'views/Common/EventFilter/EventFilterDialog'
import FormFooter from 'views/Common/Form/FormFooter'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import { KendoGridColumn } from 'views/Common/Kendo/CustomColumnMenu'
import { FormattedNameCell } from 'views/Common/Kendo/FormattedNameCell'
import { convertToDropdownDataFormat } from 'views/Common/Kendo/GridCellComponents/GridDropDownCell'
import KendoGridCustom, { getSelectedIds, SelectionState } from 'views/Common/Kendo/KendoGridCustom'
import {
    getCustomCellRenderer,
    getCustomRowRenderer,
    getEditableGridDropdownCellRenderer,
    getItemEditedHandler,
} from 'views/Common/Kendo/KendoGridEditInPlaceRenderers'
import DialogLayout from 'views/Common/Layout/DialogLayout'
import DeleteConfirmationWithStateUpdateDialog from 'views/Settings/Components/DeleteConfirmationWithStateUpdateDialog'
import GridToolbarButton from 'views/Settings/Components/GridToolbarButton'

// profile instead of id?
export interface PWSEventRulesDialogProps {
    profile: ProfileAutoSleep
    onClose: () => void
}

const PWSEventRulesDialog = ({ profile, onClose }: PWSEventRulesDialogProps) => {
    const dispatch = useAppDispatch()

    // data state
    const [tempRules, setTempRules] = useState<EventRules[]>(() => cloneDeep(profile.eventRules))
    const [tempRule, setTempRule] = useState<EventRules | undefined>(undefined)
    // ui state
    const [showDialog, setShowDialog] = useState(<></>)
    const [showEventFilter, setShowEventFilter] = useState(false)
    // grid state
    const [selectedRowsState, setSelectedRowsState] = useState<SelectionState>({})
    const selectedIds = getSelectedIds<string>(selectedRowsState)

    useEffect(() => {
        setTempRules(profile.eventRules)
    }, [profile.eventRules])

    // operations
    const addNewRecord = (existRecord?: EventRules) => {
        const newRule = getDefaulEventRules(existRecord, tempRules, profile.sleepRules)
        setTempRule(newRule)
        setShowEventFilter(true)
        setSelectedRowsState({ [newRule.id]: true })
    }
    const addCopyRecord = () => {
        if (selectedIds.length !== 1) return

        // get selected rule to copy as new
        const item = tempRules.find((i) => i.id === selectedIds[0])
        if (item) {
            addNewRecord(item)
        }
    }
    const deleteRules = () => {
        setShowDialog(deleteDialog)
    }
    function changeOrder(direction: MoveDirection) {
        setTempRules(reorderItemsList(selectedIds, tempRules, direction))
    }

    // grid
    const EDIT_FIELD = 'inEdit'

    const getColumns = useMemo((): Array<KendoGridColumn> => {
        const columns: Array<KendoGridColumn> = [
            {
                title: 'Sleep Rule',
                field: 'sleepRuleId',
                cell: (c) =>
                    getEditableGridDropdownCellRenderer(
                        EDIT_FIELD,
                        tempRules,
                        setTempRules,
                        setSelectedRowsState,
                    )(c, convertToDropdownDataFormat(profile.sleepRules)),
            },
        ]
        columns.unshift({
            title: 'Name',
            field: 'name',
            cell: (cellProps: GridCellProps) => {
                const rule = cellProps.dataItem as EventRules
                const cellContent = (
                    <TransparentButton
                        onClick={() => {
                            setTempRule(rule)
                            setShowEventFilter(true)
                        }}
                    >
                        {rule.eventFilter.name}
                    </TransparentButton>
                )
                return FormattedNameCell(cellProps, cellContent)
            },
        })
        return columns
    }, [tempRules, profile.sleepRules])

    // event filter
    const handleEventFilterClose = (result: DialogResultEnum, eventFilter?: EventFilter) => {
        if (result === DialogResultEnum.Completed && eventFilter && tempRule) {
            const itemIndex = tempRules.findIndex((x) => x.id === tempRule.id)
            if (itemIndex >= 0) {
                const newList = tempRules.map((s) => {
                    if (s.id === tempRule.id) return { ...s, eventFilter }
                    return s
                })
                setTempRules(newList)
            } else {
                setTempRules([...tempRules, { ...tempRule, eventFilter }])
            }
        }
        setShowEventFilter(false)
    }

    // header
    const headerContent = 'Planned Work Sleep - Event Rules'

    // grid content
    const gridContent = (
        <Container fluid>
            <Row className="aboveMainSection">
                <Col sm={12}>
                    <Row className="justify-content-between">
                        <Col className="col-auto"></Col>
                        <Col className="auto text-end">
                            <GridToolbarButton
                                items={tempRules}
                                selectedIds={selectedIds}
                                onAddClick={addNewRecord}
                                onCopyClick={addCopyRecord}
                                onDeleteClick={deleteRules}
                                onMoveClick={changeOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm="12">
                    <KendoGridCustom
                        sortable={false}
                        data={tempRules}
                        columns={getColumns}
                        selectedRowsState={selectedRowsState}
                        onSetSelectedRowsState={(newState: SelectionState) => setSelectedRowsState(newState)}
                        pageable={false}
                        editField={EDIT_FIELD}
                        cellRender={getCustomCellRenderer(EDIT_FIELD, tempRules, setTempRules, setSelectedRowsState)}
                        rowRender={getCustomRowRenderer(EDIT_FIELD, tempRules, setTempRules)}
                        onItemChange={getItemEditedHandler(tempRules, setTempRules)}
                        defaultEmptyGridText="No Rules Configured"
                    />
                </Col>
            </Row>
        </Container>
    )

    // footerContent
    const footerContent = (
        <FormFooter
            onCancel={onClose}
            onOk={() => {
                // update profile with temp rules
                const updated = { ...profile, eventRules: tempRules }
                dispatch(profileActions.setProfileByType({ profile: updated, type: 'Sleep', isModified: true }))
                onClose()
            }}
        />
    )

    // dialogs
    const deleteDialog = useMemo(
        () => (
            <DeleteConfirmationWithStateUpdateDialog
                topic="Planned Work Sleep - Event Rule"
                setTempItems={setTempRules}
                tempItems={tempRules}
                selectedIdsForDelete={selectedIds}
                onClose={() => {
                    setShowDialog(<></>)
                    setSelectedRowsState({})
                }}
            />
        ),
        [selectedIds, tempRules],
    )

    return (
        <>
            {showEventFilter ? (
                <EventFilterDialog eventFilter={tempRule?.eventFilter} onClose={handleEventFilterClose} />
            ) : (
                <DialogLayout headerContent={headerContent} footerContent={footerContent} onClose={onClose}>
                    {gridContent}
                </DialogLayout>
            )}
            {showDialog}
        </>
    )
}

export default PWSEventRulesDialog
