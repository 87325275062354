import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import { ChangeEvent, CSSProperties } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { TagKeyValue } from 'types/ScheduleEvent'
import IconButtonDelete from 'views/Common/Buttons/IconButtonDelete'
import IconButtonAdd from '../Buttons/IconButtonAdd'

const TagsTable = (props: {
    readonly?: boolean
    tags: TagKeyValue[]
    allTagNames: string[]
    showValidationErrors?: boolean
    updateTags: (tags: TagKeyValue[]) => void
}) => {
    const invalidCellStyle: CSSProperties = { border: '1px solid red' }
    const otherTagHasSameName = (name: string) => {
        return props.tags.filter((x) => x.name === name).length > 1
    }

    const tagRows = props.tags.map((tag, tagIndex) => {
        const isInvalid = props.showValidationErrors && (tag.name === '' || otherTagHasSameName(tag.name))

        const nameCellStyling = isInvalid ? invalidCellStyle : {}
        return (
            <Row key={tagIndex.toString()} className="mb-2">
                <Col xs={6} style={nameCellStyling}>
                    <ComboBox
                        required
                        disabled={props.readonly}
                        style={{ width: '100%' }}
                        suggest
                        value={tag.name}
                        data={props.allTagNames}
                        allowCustom
                        placeholder="Provide a unique tag name"
                        onChange={(e: ComboBoxChangeEvent) => {
                            // remove multiple consecutive spaces, and trim
                            const tagNameCleaned = e.value?.replace(/\s\s+/g, ' ').trim()
                            const updatedTags = props.tags.map((x, updatedTagIndex) => {
                                if (updatedTagIndex === tagIndex) {
                                    return { ...x, name: tagNameCleaned || '' }
                                }
                                return x
                            })
                            props.updateTags(updatedTags)
                        }}
                    />
                    {isInvalid && <small style={{ color: 'red' }}>Please provide a unique Tag Name</small>}
                </Col>
                <Col xs={5}>
                    <Form.Control
                        required
                        disabled={props.readonly}
                        type="text"
                        placeholder="Provide a tag value"
                        value={tag.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const updatedTags = props.tags.map((x, updatedTagIndex) => {
                                if (updatedTagIndex === tagIndex) {
                                    return { ...x, value: e.target.value }
                                }
                                return x
                            })
                            props.updateTags(updatedTags)
                        }}
                    />
                </Col>
                <Col xs={1} className="pt-1">
                    {!props.readonly && (
                        <IconButtonDelete
                            onClick={() => {
                                const tagsCopy = [...props.tags]
                                tagsCopy.splice(tagIndex, 1)
                                props.updateTags(tagsCopy)
                            }}
                        />
                    )}
                </Col>
            </Row>
        )
    })

    return (
        <>
            {tagRows && tagRows.length > 0 && <>{tagRows}</>}
            {!props.readonly && (
                <IconButtonAdd
                    text="Add Tag"
                    style={{ display: 'block', marginTop: 10 }}
                    onClick={() => {
                        props.updateTags([...props.tags, { name: '', value: '', index: 0 }])
                    }}
                />
            )}
        </>
    )
}

export default TagsTable
