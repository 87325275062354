import '@progress/kendo-theme-default/dist/all.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// We use react-bootstrap, but it doesn't provide its own copy of BS.  As per
// docs, we install BS separately and add reference like so.
import 'bootstrap/dist/css/bootstrap.min.css'
import 'hammerjs' // needed for Kendo charts
import useSessionStatus from 'hooks/useSessionStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthenticatedRoutes from 'routing/AuthenticatedRoutes'
import PublicRoutes from 'routing/PublicRoutes'
import checkExistingRecalculationRequests from 'services/api/checkExistingRecalculationRequests'
import * as sessionToken from 'services/api/sessionToken'
import SFApi from 'services/api/sfApi'
import Axios from 'services/axios/axios-sfc'
import SessionControl from 'services/axios/SessionControl'
import globals from 'services/global/globals'
import { handleApiError } from 'services/utilities/toastrUtils'
import globalThunkActions from 'store/actions/globalActions'
import gridLayoutActions from 'store/actions/gridLayoutActions'
import { globalActions } from 'store/globalStore'
import { RootState, useAppDispatch } from 'store/store'
import MetaData from 'types/Metadata'
import LoadingSpinner from 'views/Common/GenericDialogs/LoadingSpinner'
import Navbar from 'views/Common/Layout/Navbar'
import ToastCustom from 'views/Common/Widget/ToastCustom'
import 'views/Common/Widget/ToastifyOverrides.css'
import ErrorDialog from './views/Common/GenericDialogs/ErrorDialog'

// react lazy loading
// const Scenarios = React.lazy(() => import('views/Scenarios/ScenariosPage'))
// const SchedulesPage = React.lazy(() => import('views/Schedules/SchedulesPage'))
// const ScheduleDetailsPage = React.lazy(() => import('views/Schedules/ScheduleDetails/ScheduleDetailsPage'))

const App = () => {
    const navigate = useNavigate()
    const showLoadingModal = useSelector<RootState>((x) => x.app.showLoadingModal)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [, setNeedsRerender] = useState<Date | null>(null)
    const [sfMetadata, setSfMetadata] = useState<MetaData>()
    const userState = useSessionStatus()
    const dispatch = useAppDispatch()

    const sessionControl: SessionControl = useMemo(() => {
        const sessionExpiredHandler = (sessionExpiredError: string) => {
            if (sessionToken.getSessionToken()) {
                dispatch(globalActions.setIsSessionExpired())
                sessionToken.deleteSessionToken()
                toast.error(sessionExpiredError)
            }
        }

        const isLoggedInHandler = (loggedIn: boolean) => {
            if (loggedIn) {
                dispatch(globalActions.setIsLoggedIn())
                dispatch(gridLayoutActions.getGridLayoutData())
                dispatch(globalThunkActions.fetchReportMetaData({ forceRefresh: false }))
            } else {
                dispatch(globalActions.setIsLoggedOut())
            }
        }

        const passwordExpiredHandler = () => {
            navigate('/setpasswordrequest')
            setNeedsRerender(new Date())
        }

        const sessionControlObj = new SessionControl(isLoggedInHandler, sessionExpiredHandler, passwordExpiredHandler)

        return sessionControlObj
    }, [dispatch, setNeedsRerender, navigate])

    const api = useMemo(() => {
        return new SFApi(new Axios(sessionControl))
    }, [sessionControl])
    globals.setApi(api)

    const getMetadata = useCallback(() => {
        async function fetchData() {
            const metatadata = await api.getMetadata()
            setSfMetadata(metatadata)
            dispatch(globalActions.setMetadata(metatadata))
            checkExistingRecalculationRequests(dispatch)
        }
        fetchData()
    }, [dispatch, api])

    const loginHandler = async (emailAddress: string, password: string, keepMeLoggedIn: boolean) => {
        try {
            const authToken = await api.authenticateCredentials(emailAddress, password, keepMeLoggedIn)
            dispatch(globalActions.setUser(authToken.user))
            await getMetadata()
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    const logoutHandler = async () => {
        try {
            await api.signOut()
            setNeedsRerender(new Date())
        } catch (error: any) {
            setErrorMessage(error.message)
        }
    }

    useEffect(() => {
        const authenticateSession = async () => {
            try {
                const session = sessionToken.getSessionToken()
                if (!session) {
                    dispatch(globalActions.setIsLoggedOut())
                } else {
                    const token = await api.authenticateToken(session)
                    if (token) {
                        await getMetadata()
                        dispatch(globalActions.setUser(token.user))
                    } else {
                        dispatch(globalActions.setIsLoggedOut())
                    }
                }
            } catch (err: any) {
                handleApiError(err)
            }
        }

        authenticateSession()
    }, [api, sessionControl, setNeedsRerender, getMetadata, dispatch])

    const errorDialog = errorMessage && (
        <ErrorDialog closeCallback={() => setErrorMessage(null)} message={errorMessage} />
    )
    if (userState === 'Unknown') {
        // when the user first hits the site and we haven't yet determined if they
        // have a session cookie still; don't want to show the login page for a brief second as
        // it looks flickery.  So return nothing.
        return <></>
    }

    // if (userState === '' !isLoggedIn || isSessionExpired) {
    if (userState === 'LoggedOut' || userState === 'SessionExpired') {
        return (
            <>
                {errorDialog}
                <PublicRoutes isLoggedIn={false} loginHandler={loginHandler} />
                <ToastCustom />
            </>
        )
    }

    if (!sfMetadata) {
        // just show the loading modal, the app hasn't loaded yet
        return <LoadingSpinner />
    }

    return (
        <>
            {showLoadingModal && <LoadingSpinner />}
            <Navbar />
            <AuthenticatedRoutes logoutHandler={logoutHandler} />
            <ToastCustom />
        </>
    )
}

export default App
