import Axios from 'services/axios/axios-sfc'
import SFApiBase from './sfApiBase'

class SFTagsApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getTagDefinitions = async (): Promise<string[]> => {
        return (await this.axios.get(this.baseUrl + '/Api/Scenarios/TagDefinitions', this.getConfig())).data as any[]
    }
}

export default SFTagsApi
