import { AxiosProgressEvent } from 'axios'
import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { fetchAndSetTemplateFromId, saveScenarioAndUpdatedTemplate } from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { ProfileDataSource } from 'types/ProfileInterfaces'
import { Scenario, ScenarioDataSourceOverride } from 'types/Scenario'
import { TagKeyValue } from 'types/ScheduleEvent'
import { TemplateSaveOptions } from 'types/TemplateDetail'
import { v4 as uuidv4 } from 'uuid'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import ProgressBar from 'views/Common/Widget/ProgressBar'
import TagsTable from 'views/Common/Widget/TagsTable'
import DataSourcesSelectorPanel from 'views/Settings/Profiles/DataSourcesSelectorPanel'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateExpansionPanel from 'views/Settings/Templates/TemplateExpansionPanel'
import ScenarioSaveConfirmationDialog from './ScenarioSaveConfirmationDialog'

const ScenarioDetailPage = () => {
    const { id, operationMode } = useDetailPageParams()

    const scenarioApi = globals.getApi().getScenarioApi()
    const tagsApi = globals.getApi().getTagsApi()
    const apiProfile = globals.getApi().getProfileApi()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [scenario, setScenario] = useState<Scenario>()
    const [scenarioTagDefinitions, setScenarioTagDefinitions] = useState<string[]>([])
    const [scenarioModified, setScenarioModified] = useState(false)
    const [profilesList, setProfilesList] = useState<ProfileDataSource[]>([])
    const [uploadProgress, setUploadProgress] = useState(0)

    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)

    const template = useAppSelector((rs) => rs.profile.template)
    const templateChanged = useAppSelector((rs) => rs.profile.templateModified)

    const areTagsInValid = () => {
        const scenarioTags = scenario?.scenarioTags || []
        const anyNoNameTags = scenarioTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = scenarioTags.length !== new Set(scenarioTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    const isDataSourceUpdating = scenario?.scenarioDataSourceOverrides?.some(
        (override) => override.file1 || override.file2,
    )

    useEffect(() => {
        const loadData = async () => {
            setProfilesList((await apiProfile.getProfileList('DataSource')) as ProfileDataSource[])
        }
        loadData()
    }, [apiProfile])

    // Set the scenario state based on the operation mode
    useEffect(() => {
        const init = async () => {
            dispatch(profileActions.setScenarioMode(true))
            try {
                setScenarioTagDefinitions(await tagsApi.getTagDefinitions())
                if (operationMode === 'Edit') {
                    const res = await scenarioApi.getScenario(Number(id))
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                } else if (operationMode === 'Copy') {
                    const res = await scenarioApi.copyScenario(id)
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                    setScenarioModified(true) // copying a scenario results in it being 'modified'
                } else {
                    const res = await scenarioApi.getDefaultScenario()
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        init()
    }, [scenarioApi, tagsApi, dispatch, id, operationMode])

    // update overrides
    useEffect(() => {
        if (!scenario) return

        const updatedScenario = { ...scenario } as Scenario

        // set up the overrides state
        if (!template || !template.profileDataSourceIds) {
            updatedScenario.scenarioDataSourceOverrides = []
            setScenario(updatedScenario)
            return
        }

        const templateDataSourceIds = template?.profileDataSourceIds

        // check to see if override exists for each data source
        templateDataSourceIds.forEach((dataSourceId) => {
            const existingOverride = scenario?.scenarioDataSourceOverrides.find(
                (override) => override.profileDataSourceId === dataSourceId,
            )
            if (!existingOverride) {
                // if no override exists, create a new one
                const profileDataSource = profilesList.find((profile) => profile?.id === dataSourceId)
                if (!profileDataSource) return

                const newOverride: ScenarioDataSourceOverride = {
                    id: uuidv4(),
                    scenarioId: updatedScenario.id,
                    profileDataSourceId: dataSourceId,
                    fileName1: profileDataSource?.dataFieldPath1,
                    fileName2: profileDataSource?.dataFieldPath2,
                    file1: null,
                    file2: null,
                    scheduleYear: profileDataSource?.scheduleYear,
                    dateStartFilter: profileDataSource?.dateStartFilter,
                    dateEndFilter: profileDataSource?.dateStartFilter,
                    fleet: profileDataSource?.fleet,
                }

                updatedScenario.scenarioDataSourceOverrides.push(newOverride) // Add the new profile to the overrides
                setScenario(updatedScenario)
            }
        })

        // check to see if any overrides can be removed
        updatedScenario?.scenarioDataSourceOverrides?.forEach((override) => {
            if (!templateDataSourceIds.includes(override.profileDataSourceId)) {
                updatedScenario.scenarioDataSourceOverrides = updatedScenario.scenarioDataSourceOverrides.filter(
                    (o) => o.profileDataSourceId !== override.profileDataSourceId,
                )
                setScenario(updatedScenario)
            }
        })
    }, [profilesList, scenario, template])

    const onFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        profileDataSourceId: string,
        fileIndex: number,
    ) => {
        const files = event.target.files
        if (!files || files.length <= 0) return

        // update the scenarioOverrides
        const updatedScenario = { ...scenario } as Scenario
        const existingOverride = scenario?.scenarioDataSourceOverrides.find(
            (override) => override.profileDataSourceId === profileDataSourceId,
        )
        if (!existingOverride) return

        // todo - setting these properties dynamically is awkward - we should consider having an array of files instead
        if (fileIndex === 1) {
            existingOverride.fileName1 = files[0].name
            existingOverride.file1 = files[0]
        }
        if (fileIndex === 2) {
            existingOverride.fileName2 = files[0].name
            existingOverride.file2 = files[0]
        }

        setScenario(updatedScenario)
        setScenarioModified(true)
    }

    const updateScenario = (e: FormControlChangeEvent) => {
        setScenario(updateObjectWithFormEvent(scenario, e))
        setScenarioModified(true)
    }

    // Update the upload progress
    const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
            const percentCompleted = progressEvent.loaded / progressEvent.total
            setUploadProgress(percentCompleted)
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        if (!scenario) {
            throw Error('Scenario is not defined')
        }
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        // validate
        setValidatedForm(true)
        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            return
        }

        if (areTagsInValid()) {
            setValidatedForm(true)
            return
        }

        if (operationMode === 'Create' || operationMode === 'Copy') {
            await performOperation('new')
        } else {
            setShowConfirm(true)
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum) => {
        if (!scenario) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            await performOperation('save')
        }

        setShowConfirm(false)
    }

    const performOperation = async (mode: TemplateSaveOptions) => {
        if (!scenario) {
            return
        }
        try {
            await dispatch(saveScenarioAndUpdatedTemplate(scenario, mode, handleUploadProgress))
            if (isDataSourceUpdating) {
                // upload will result in import - fwd to File Processing
                navigate('/fileprocessing')
            } else {
                // just updating scenario details - fwd to schedule list
                navigate(`/scenario/${scenario.id}/schedules`)
            }
        } catch (error: any) {
            toast.error(error.message)
            return
        }
        toast.success(`Scenario has been ${mode === 'save' ? 'Updated' : 'Created'}`)
    }

    const title = operationMode === 'Edit' ? 'Edit Scenario' : 'Add Scenario'
    const footer = (
        <FormFooter
            contentLeft={
                validatedForm &&
                isDataSourceUpdating && <ProgressBar textDescription="" progressValue={uploadProgress} />
            }
            disabledSave={!scenarioModified && !templateChanged}
            onCancel={() => {
                if (operationMode === 'Edit') {
                    navigate(`/scenario/${scenario?.id}/schedules`)
                    return
                }

                navigate('/scenarios')
            }}
        />
    )

    if (!scenario) {
        return <></>
    }

    return (
        <>
            <FormPage
                headingContent={title}
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <FormSection title="Details">
                    <InputTextFormRow
                        labelText="Name"
                        fieldId="name"
                        subText="A unique name for this scenario"
                        value={scenario.name}
                        onChange={updateScenario}
                        required
                        invalidText="Enter a unique name"
                    />
                    <InputTextAreaFormRow
                        labelText="Description"
                        fieldId="description"
                        subText="A description for this scenario"
                        value={scenario.description}
                        onChange={updateScenario}
                    />
                </FormSection>

                <FormSection title="Tags">
                    <TagsTable
                        tags={scenario.scenarioTags}
                        allTagNames={scenarioTagDefinitions}
                        showValidationErrors={validatedForm}
                        updateTags={(tags: TagKeyValue[]) => {
                            setScenarioModified(true)
                            setScenario((previousScenario: Scenario | undefined) => {
                                if (!previousScenario) return previousScenario
                                return {
                                    ...previousScenario,
                                    scenarioTags: tags,
                                }
                            })
                        }}
                    />
                </FormSection>
                <FormSection title="Template" padding={0}>
                    <TemplateExpansionPanel onChange={updateScenario}>
                        <ProfilesSelectorPanel />
                    </TemplateExpansionPanel>
                </FormSection>
                <DataSourcesSelectorPanel scenario={scenario} validated={validatedForm} onFileChange={onFileChange} />
            </FormPage>
            {showConfirm && <ScenarioSaveConfirmationDialog closeCallback={handleModifyConfirmResult} />}
        </>
    )
}

export default ScenarioDetailPage
