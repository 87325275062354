import { cloneDeep } from 'lodash'
import { getNewItemName } from 'services/utilities/arrayUtils'
import { v4 as uuidv4 } from 'uuid'
import {
    AutoMarkerRules,
    EventDurationRules,
    EventFilter,
    EventRules,
    ProfileAutoMarker,
    ProfileAutoSleep,
    ProfileDataSource,
    ProfileMetricsConfiguration,
    ProfileWork,
    ProfileWorkload,
    SleepNapRule,
    SleepQualityRules,
    SleepRules,
    WorkloadFactor,
    WorkloadRule,
} from './ProfileInterfaces'

// Profiles

export const defaultProfileDataSource: ProfileDataSource = {
    id: 'default',
    name: 'default',
    description: '',
    isDefault: false,
    profileType: 'DataSource',
    templatesUsing: 0,
    scenariosUsing: 0,
    owner: '',
    dataSourceType: 'SAFTEFASTCSV',
    determineBaseStationFrom: 'StartStation',
    combineSchedulesWithOperatingDates: false,
    combineEventsIntoDutiesWithin: 0,
    labelFilterType: 'IncludeAll',
    labelFilterList: '',
    durationFilterEnable: false,
    durationFilterRangeStart: 0,
    durationFilterRangeEnd: 0,
    eventTypeCreationType: 'AsMarkers',
    eventTypeCreationList: '',
    complementCodes: '',
    dateStartFilterEnable: false,
    dateStartFilter: new Date(),
    dateEndFilterEnable: false,
    dateEndFilter: new Date(),
    baseFilterType: 'IncludeAll',
    baseFilterList: '',
    locationFilterType: 'IncludeAll',
    locationFilterList: '',
    equipmentFilterType: 'IncludeAll',
    equipmentFilterList: '',
    dataFieldPath1: '',
    dataFieldPath2: '',
    customDateFormat: '',
    crewingCodes: '',
    ignorePlannedWorkSleepRules: false,
    timeReference: 'Local',
    rosterPlanningStage: 'ActualTimes',
    periodStart: new Date(),
    scheduleType: 'PairingsAndRosters',
    scheduleYear: 0,
    fleet: '',
    hubTurnsMaximumGap: 0,
    hubTurnsLocations: '',
    timezoneOffset: 0,
}

export const defaultProfileWork: ProfileWork = {
    id: 'default',
    name: 'default',
    isDefault: false,
    description: '',
    profileType: 'Work',
    autoDuties: true,
    defaultBaseStation: '',
    industry: 'Aviation',
    briefDuration: 0,
    debriefDuration: 0,
    dutyBreakType: 'Sit',
    minBreakTimeAtHotel: 0,
    minBreakTimeAtRestFacility: 0,
    sleepPreference: 'Hotel',
    sleepDuringStart: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
    sleepDuringEnd: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
    minSleepDuringBreak: 0,
    bufferType: 'VariableEvents',
    prepTimeAtHome: 0,
    prepTimeAtHotel: 0,
    prepTimeAtRestFacility: 0,
    commuteTimeAtHome: 0,
    commuteTimeAtHotel: 0,
    commuteTimeAtRestFacility: 0,
    unwindTimeAtHome: 0,
    unwindTimeAtHotel: 0,
    unwindTimeAtRestFacility: 0,
    bufferDuration: 0,
    eventDurationRules: [],
}

export const defaultProfileAutoSleep: ProfileAutoSleep = {
    id: 'default',
    name: 'default',
    isDefault: false,
    description: '',
    profileType: 'Sleep',
    normalBedtime: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
    minSleepDuration: 0,
    awakeZoneStart: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
    awakeZoneEnd: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
    sleepQualityHome: 'Excellent',
    sleepQualityHotel: 'Excellent',
    sleepQualityRestFacility: 'Excellent',
    sleepQualityRules: [],
    transmeridianSleepMode: 'Local',
    explicitSleepBufferBefore: 0,
    explicitSleepBufferAfter: 0,
    explicitSleepBufferMax: 0,
    regularSleepEnable: false,
    maxWorkDaySleep: 0,
    maxRestDaySleep: 0,
    advancedBedtimeEnable: false,
    postWorkSleepEnable: false,
    maxRecoverySleep: 0,
    preWorkSleepEnable: false,
    napRules: [],
    napSleepQuality: 'Good',
    plannedWorkSleepEnable: false,
    sleepRules: [],
    eventRules: [],
}

export const defaultProfileAutoMarker: ProfileAutoMarker = {
    id: 'default',
    name: 'default',
    isDefault: false,
    description: '',
    profileType: 'Markers',
    autoMarkerRules: [],
}

export const defaultProfileMetricsConfiguration: ProfileMetricsConfiguration = {
    id: 'default',
    name: 'default',
    isDefault: false,
    description: '',
    profileType: 'Metrics',
    effectivenessCriterionLevel: 0,
    belowCriterionThreshold: 0,
    criticalTimeDetermination: 'EventStartEnd',
    dutyDurationBrief: false,
    dutyDurationDebrief: false,
    wocl: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
    night: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
    redeye: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
    daySleep: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
    earlyStart: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
    lateEnd: {
        start: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        end: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
        timeReference: 'Local',
    },
}

export const defaultProfileWorkload: ProfileWorkload = {
    id: 'default',
    name: 'default',
    isDefault: false,
    description: '',
    profileType: 'Workload',
    templatesUsing: 0,
    scenariosUsing: 0,
    owner: '',
    workloadFactors: [],
    workloadRules: [],
    scaleMaximum: 0,
    decayPeriod: 0,
}

// Event Filter

export function getDefaultEventFilter(copy?: EventFilter): EventFilter {
    const newFilter = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              name: 'New Event Filter',
              description: '',
              eventQuery: undefined,
              crewingEnable: true,
              crewingType: 'Crewing',
              durationEnable: false,
              durationFrom: 0,
              durationTo: 0,
              locationEnable: false,
              locationCode: '',
              routeEnable: false,
              routeFrom: '',
              routeTo: '',
              eventsEnable: false,
              events: '',
              equipmentTypesEnable: false,
              equipmentTypes: '',
              crewComplementEnable: false,
              crewComplementFrom: 0,
              crewComplementTo: 0,
              crewPositionsEnable: false,
              crewPositions: '',
              timeOfDayEnable: false,
              timeOfDayFrom: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
              timeOfDayTo: new Date(Date.UTC(2000, 0, 1, 0, 0, 0)),
              timeOfDayZoneType: 'Local',
              overlapType: 'Any',
          }

    newFilter.id = uuidv4()

    return newFilter as EventFilter
}
// Rules

export function getDefaultEventDurationRules(
    copy?: EventDurationRules,
    rules?: EventDurationRules[],
): EventDurationRules {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              eventFilter: getDefaultEventFilter(),
              briefHome: 0,
              briefHotel: 0,
              briefRestFacility: 0,
              debriefHome: 0,
              debriefHotel: 0,
              debriefRestFacility: 0,
              commuteHome: 0,
              commuteHotel: 0,
              commuteRestFacility: 0,
              prepHome: 0,
              prepHotel: 0,
              prepRestFacility: 0,
              unwindHome: 0,
              unwindHotel: 0,
              unwindRestFacility: 0,
              order: 0,
          }

    newRule.id = uuidv4()
    newRule.eventFilter.id = uuidv4()
    newRule.eventFilter.name = copy ? copy.eventFilter.name + ' - Copy' : 'New Event Duration Rule'

    if (rules && rules.length > 0) {
        const eventFilters = rules.map((rule) => rule.eventFilter)
        newRule.eventFilter.name = getNewItemName(eventFilters, 'name', newRule.eventFilter.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as EventDurationRules
}

export function getDefaultSleepQualityRules(copy?: SleepQualityRules, rules?: SleepQualityRules[]): SleepQualityRules {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              eventFilter: getDefaultEventFilter(),
              order: 0,
              home: 'Default',
              hotel: 'Default',
              restFacility: 'Default',
          }

    newRule.id = uuidv4()
    newRule.eventFilter.id = uuidv4()
    newRule.eventFilter.name = copy ? copy.eventFilter.name + ' - Copy' : 'New Sleep Quality Rule'

    if (rules && rules.length > 0) {
        const eventFilters = rules.map((rule) => rule.eventFilter)
        newRule.eventFilter.name = getNewItemName(eventFilters, 'name', newRule.eventFilter.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as SleepQualityRules
}

export function getDefaultSleepNapRule(copy?: SleepNapRule, rules?: SleepNapRule[]): SleepNapRule {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              priorRestMin: 60,
              priorRestMax: 120,
              napDuration: 30,
              priorWorkType: 'AnyWork',
              order: 0,
          }

    newRule.id = uuidv4()

    if (rules && rules.length > 0) {
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as SleepNapRule
}

export function getDefaultAutoMarkerRules(copy?: AutoMarkerRules, rules?: AutoMarkerRules[]): AutoMarkerRules {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'new',
              eventFilter: getDefaultEventFilter(),
              eventOffset: 15,
              eventOffsetUnit: 'Minutes',
              eventOffsetReference: 'AfterStart',
              markerName: 'NEW Auto-Marker',
              markerDuration: 15,
              markerDurationUnit: 'Minutes',
              markerIsCritical: false,
              order: 0,
          }

    newRule.id = uuidv4()
    newRule.eventFilter.id = uuidv4()
    newRule.eventFilter.name = copy ? copy.eventFilter.name + ' - Copy' : 'New Auto-Marker Rule'

    if (rules && rules.length > 0) {
        const eventFilters = rules.map((rule) => rule.eventFilter)
        newRule.eventFilter.name = getNewItemName(eventFilters, 'name', newRule.eventFilter.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as AutoMarkerRules
}

export function getDefaulSleepRules(copy?: SleepRules, rules?: SleepRules[]): SleepRules {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              name: 'default',
              order: 0,
              quality: 'Fair',
              durationType: 'FormulaCDP',
              durationS: 30,
              durationE: 90,
              durationP: 0.75,
              durationN: 2,
              durationFixed: 60,
              sleepPeriods: [{ id: 'default', position: 1, percentOfSleep: 100 }],
          }

    newRule.id = uuidv4()
    newRule.name = copy ? copy.name + ' - Copy' : 'New Sleep Rule'

    newRule.sleepPeriods.forEach((sleepPeriod) => {
        sleepPeriod.id = uuidv4()
    })

    if (rules && rules.length > 0) {
        newRule.name = getNewItemName(rules, 'name', newRule.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as SleepRules
}

export function getDefaulEventRules(
    copy?: EventRules,
    eventRules?: EventRules[],
    sleepRules?: SleepRules[],
): EventRules {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              sleepRuleId: sleepRules ? sleepRules[0].id : '',
              eventFilter: getDefaultEventFilter(),
              order: 0,
          }
    newRule.id = uuidv4()
    newRule.eventFilter.id = uuidv4()
    newRule.eventFilter.name = copy ? copy.eventFilter.name + ' - Copy' : 'New Event Rule'

    if (eventRules && eventRules.length > 0) {
        const eventFilters = eventRules.map((rule) => rule.eventFilter)
        newRule.eventFilter.name = getNewItemName(eventFilters, 'name', newRule.eventFilter.name)
        newRule.order = eventRules[eventRules.length - 1].order + 1
    }

    return newRule as EventRules
}

export function getDefaulWorkloadFactor(copy?: WorkloadFactor, rules?: WorkloadFactor[]): WorkloadFactor {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              name: 'default',
              workloadFactorType: 'Custom',
              weight: 'Low',
              order: 0,
          }

    newRule.id = uuidv4()
    newRule.name = copy ? newRule.name + ' - Copy' : 'New Workload Factor'

    if (rules && rules.length > 0) {
        newRule.name = getNewItemName(rules, 'name', newRule.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as WorkloadFactor
}

export function getDefaultWorkloadRule(copy?: WorkloadRule, rules?: WorkloadRule[]): WorkloadRule {
    const newRule = copy
        ? cloneDeep(copy)
        : {
              id: 'default',
              eventFilter: getDefaultEventFilter(),
              triggerOnEventStart: true,
              triggerInEventPeriodicallyDuration: 0,
              triggerOnEventEnd: true,
              workloadFactors: [],
              order: 0,
          }

    newRule.id = uuidv4()
    newRule.eventFilter.id = uuidv4()
    newRule.eventFilter.name = copy ? copy.eventFilter.name + ' - Copy' : 'New Workload Rule'

    if (rules && rules.length > 0) {
        const eventFilters = rules.map((rule) => rule.eventFilter)
        newRule.eventFilter.name = getNewItemName(eventFilters, 'name', newRule.eventFilter.name)
        newRule.order = rules[rules.length - 1].order + 1
    }

    return newRule as WorkloadRule
}
