import { useSystemSettings } from 'hooks/useSettingValue'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { palettesActions } from 'store/palettesStore'
import { useAppDispatch } from 'store/store'
import { SettingValue, SystemSetting } from 'types/SystemSetting'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import PalettesForm from './PalettesForm'

const Palettes = () => {
    const dispatch = useAppDispatch()
    const [settings, setSettings] = useSystemSettings('Palettes')
    const [isChanged, setIsChanged] = useState<Boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const navigate = useNavigate()

    const api = globals.getApi()

    useEffect(() => {
        document.title = 'SAFTE-FAST - Palettes'
    })

    if (!settings.length) {
        return null
    }

    const handleOnChange = (setting: SystemSetting) => {
        const list = [...settings]
        const index = list.findIndex((s) => s.name === setting.name)
        if (index >= 0) {
            list[index].value = setting.value
            setSettings(list)
            setIsChanged(true)
        }
    }

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        let invalid = false
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            invalid = true
        }

        if (invalid) {
            return
        }

        const settingValues: SettingValue[] = []
        settings.forEach((item) => {
            settingValues.push({ name: item.name, value: item.value })
        })
        await api.getSystemSettingApi().updateSystemSettings(settingValues)
        dispatch(palettesActions.clearPalettes())

        setIsChanged(false)
        toast.success('Palette Settings have been Updated.')
    }

    const onCancelClick = () => {
        navigate('/settings')
    }
    const footer = <FormFooter disabledSave={!isChanged} onCancel={onCancelClick} />

    return (
        <>
            <FormPage
                headingContent="Palettes"
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <PalettesForm settings={settings} setSettings={setSettings} onChange={handleOnChange} />
            </FormPage>
        </>
    )
}

export default Palettes
