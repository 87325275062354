import { DrawerItemProps } from '@progress/kendo-react-layout'
import {
    aggregateFieldsIcon,
    gearIcon,
    parametersIcon,
    setColumnPositionIcon,
    userIcon,
} from '@progress/kendo-svg-icons'
import useUser from 'hooks/useUser'
import { useEffect } from 'react'
import { profileActions } from 'store/profileStore'
import { useAppDispatch } from 'store/store'
import StationsPage from 'views/Stations/StationsPage/StationsPage'
import Users from 'views/Users/Page/UsersPage'
import GeneralSetting from '../General/GeneralSetting'
import Palettes from '../Palettes/PalettesPage'
import ProfileList from '../Profiles/ProfileList'
import TemplateList from '../Templates/TemplateList'
import SidePanel from './SidePanel'

const SettingsLayout = () => {
    const user = useUser()
    const dispatch = useAppDispatch()
    useEffect(() => {
        // reset details out of redux while on this page
        dispatch(profileActions.clearProfilesAndTemplate())
    }, [dispatch])
    const SettingPanelItems: DrawerItemProps[] = [
        {
            text: 'Settings',
            id: 0, // When id is 0, it will be the side panel title
        },
        { separator: true },
        {
            text: 'General',
            id: 1,
            svgIcon: gearIcon,
            selected: true,
            expanded: false,
            element: <GeneralSetting />,
            route: '/settings',
        },
        {
            text: 'Setup',
            id: 11,
            parentId: 1,
            element: <div>General - Setup</div>,
        },
        {
            text: 'Templates',
            id: 2,
            svgIcon: aggregateFieldsIcon,
            expanded: false,
            element: <TemplateList />,
            route: '/settings/templates',
        },
        {
            text: 'Data Source',
            id: 21,
            parentId: 2,
            element: <ProfileList displayName="Data Source" profileType="DataSource" />,
            route: '/settings/profiles/datasource',
        },
        {
            text: 'Work',
            id: 22,
            parentId: 2,
            element: <ProfileList displayName="Work" profileType="Work" />,
            route: '/settings/profiles/work',
        },
        {
            text: 'Auto-Sleep',
            id: 23,
            parentId: 2,
            element: <ProfileList displayName="Auto-Sleep" profileType="Sleep" />,
            route: '/settings/profiles/sleep',
        },
        {
            text: 'Auto-Markers',
            id: 24,
            parentId: 2,
            element: <ProfileList displayName="Auto-Markers" profileType="Markers" />,
            route: '/settings/profiles/markers',
        },
        {
            text: 'Metrics Configuration',
            id: 25,
            parentId: 2,
            element: <ProfileList displayName="Metrics Configuration" profileType="Metrics" />,
            route: '/settings/profiles/metrics',
        },
        {
            text: 'Workload',
            id: 26,
            parentId: 2,
            element: <ProfileList displayName="Workload" profileType="Workload" />,
            route: '/settings/profiles/workload',
        },
        {
            text: 'Palettes',
            id: 4,
            svgIcon: parametersIcon,
            element: <Palettes />,
            route: '/settings/palettes',
        },
        {
            text: 'Stations',
            id: 5,
            svgIcon: setColumnPositionIcon,
            element: <StationsPage />,
            route: '/settings/stations',
        },
        {
            text: 'Users',
            id: 6,
            svgIcon: userIcon,
            visible: user?.isAdministrator && user.name !== 'DesktopAdmin',
            element: <Users />,
            route: '/settings/users',
        },
        { separator: true },
    ]

    return <SidePanel items={SettingPanelItems} />
}

export default SettingsLayout
