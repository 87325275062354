import { useAppSelector } from 'store/store'
import { Scenario } from 'types/Scenario'
import { InputFileFormRow } from 'views/Common/Form/FormRows/CustomFormRows'

interface ScenarioDataSourceOverridesPanelProps {
    scenario: Scenario
    validated?: boolean
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>, profileDataSourceId: string, fileIndex: number) => void
}

const ScenarioDataSourceOverridesPanel = ({
    scenario,
    validated,
    onFileChange,
}: ScenarioDataSourceOverridesPanelProps) => {
    const template = useAppSelector((rs) => rs.profile.template)
    const dataSourceProfileIds = template?.profileDataSourceIds || []
    const scenarioOverrides = scenario.scenarioDataSourceOverrides || []

    return (
        <>
            {scenarioOverrides.map((override, index) => {
                const profileId = override.profileDataSourceId
                return (
                    <div key={profileId}>
                        <InputFileFormRow
                            labelText="SAFTE-FAST CSV FILE"
                            subText="Local path to a schedules file (.csv, .xls, .xlsx)"
                            fieldId={`${profileId}:1`}
                            value={override.fileName1}
                            onChange={(e) => onFileChange(e, profileId, 1)}
                            invalidText="Enter a valid path"
                            validated={validated}
                            required
                        />

                        {index !== dataSourceProfileIds.length - 1 && <hr />}
                    </div>
                )
            })}
        </>
    )
}

export default ScenarioDataSourceOverridesPanel
