import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import globals from 'services/global/globals'
import { useAppSelector } from 'store/store'
import TemplateDetail from 'types/TemplateDetail'
import { EllipsisDropdownItem, IndentedDropdownItem } from 'views/Common/Buttons/EllipsisDropdown'
import { ActionMenuItem } from 'views/Common/GenericDialogs/ColumnPickerDialog/ColumnPickerLayoutMenu'
import CustomDropdown from 'views/Common/Inputs/RichDropdown'

export interface TemplateSelectorToolProps {
    onSelected: (templateId: string, oldId: string) => void
    onSave: () => void
}
const TemplateSelectorTool = ({ onSelected, onSave }: TemplateSelectorToolProps) => {
    const [templatesList, setTemplatesList] = useState<TemplateDetail[]>([])
    const template = useAppSelector((rs) => rs.profile.template)
    const navigate = useNavigate()
    useEffect(() => {
        const loadData = async () => {
            setTemplatesList(await globals.getApi().getTemplateApi().getTemplateList())
        }
        loadData()
    }, [])

    if (!template) {
        return null
    }

    return (
        <CustomDropdown
            displayText={template.name}
            scrollableContent={
                <>
                    {templatesList.map((templateOption) => (
                        <EllipsisDropdownItem
                            key={templateOption.id}
                            onClick={() => onSelected(templateOption.id, template.id)}
                        >
                            <IndentedDropdownItem checked={templateOption.name === template.name}>
                                {templateOption.name}
                            </IndentedDropdownItem>
                        </EllipsisDropdownItem>
                    ))}
                </>
            }
            fixedFooterContent={
                <div style={{ fontSize: '0.85em' }}>
                    <ActionMenuItem
                        text="Save Custom Template to Library..."
                        iconClass="bi-save"
                        onClick={() => onSave()}
                    />
                    <ActionMenuItem
                        text="Manage Templates"
                        iconClass="bi-pencil"
                        onClick={() => navigate('/settings/templates/')}
                    />
                </div>
            }
        />
    )
}

export default TemplateSelectorTool
