import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import {
    fetchAndSetProfileByType,
    getProfileModifiedByType,
    selectProfileByType,
    setProfileFromApiCopy,
} from 'store/actions/profileActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { getProfileTitleByType, Profile } from 'types/ProfileInterfaces'
import { TemplateSaveOptions } from 'types/TemplateDetail'
import { OperationMode } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import TemplateSaveConfirmationDialog from '../Templates/TemplateSaveConfirmationDialog'

export interface ProfileDetailsPageProps {
    type: ProfileModuleTypes
    detailsPanel: React.ComponentType<{
        operationMode: OperationMode
        enableCommonFields: boolean
        validatedForm: boolean
        id?: string
    }>
}

const getUrlPath = (type: ProfileModuleTypes) => {
    switch (type) {
        case 'Sleep':
            return '/settings/profiles/sleep/'
        case 'Metrics':
            return '/settings/profiles/metrics/'
        case 'Workload':
            return '/settings/profiles/workload/'
        case 'Work':
            return '/settings/profiles/work/'
        case 'Markers':
            return '/settings/profiles/markers/'
        case 'DataSource':
            return '/settings/profiles/datasource/'
        default:
            throw new Error('Invalid Profile Type')
    }
}

const ProfileDetailsPage = ({ type, detailsPanel: DetailsPanel }: ProfileDetailsPageProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { id, operationMode } = useDetailPageParams()
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const idForGetProfile = operationMode === 'Copy' ? 'new' : id
    const profile = useAppSelector((state) => selectProfileByType(state, type, idForGetProfile)) as Profile
    const profileChanged = useAppSelector((rs) => getProfileModifiedByType(rs, type, idForGetProfile))

    // Set the profile state based on the operation mode
    useEffect(() => {
        const asyncCall = async () => {
            try {
                if (operationMode === 'Copy') {
                    await dispatch(setProfileFromApiCopy(id, type))
                } else {
                    const idToUse = operationMode === 'Create' ? 'new' : id
                    await dispatch(fetchAndSetProfileByType({ id: idToUse, type, forceReset: true }))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        asyncCall()
    }, [operationMode, dispatch, id, type])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            return
        }

        if (operationMode === 'Edit') {
            setShowConfirm(true)
        } else {
            try {
                await globals.getApi().getProfileApi().createProfile(profile, type)
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            toast.success('Profile has been Created.')
            navigate(getUrlPath(type))
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum, mode?: TemplateSaveOptions) => {
        if (!profile) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            try {
                await globals.getApi().getProfileApi().updateProfile(profile, type)
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            if (mode === 'save') {
                toast.success('Profile has been Updated.')
            } else {
                toast.success('Profile has been Created.')
            }

            navigate(getUrlPath(type))
        }
        setShowConfirm(false)
    }

    if (!profile) {
        return <></>
    }

    const title =
        operationMode === 'Edit'
            ? `Edit ${getProfileTitleByType(type)} Profile`
            : `Add ${getProfileTitleByType(type)} Profile`
    const footer = <FormFooter disabledSave={!profileChanged} onCancel={() => navigate(getUrlPath(type))} />

    return (
        <>
            <FormPage
                headingContent={title}
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <DetailsPanel id={id} operationMode={operationMode} enableCommonFields validatedForm={validatedForm} />
            </FormPage>

            {/* TODO - replace TemplateSaveConfirmationDialog with a profile-specific version */}
            {showConfirm && <TemplateSaveConfirmationDialog closeCallback={handleModifyConfirmResult} />}
        </>
    )
}

export default ProfileDetailsPage
