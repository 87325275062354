import Axios from 'services/axios/axios-sfc'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { Profile } from 'types/ProfileInterfaces'
import { NIL } from 'uuid'
import SFApiBase from './sfApiBase'

const profileTypeToApiMap = {
    Work: '/Api/ProfileWork',
    Sleep: '/Api/ProfileAutoSleep',
    Markers: '/Api/ProfileAutoMarker',
    Metrics: '/Api/ProfileMetricsConfiguration',
    Workload: '/Api/ProfileWorkload',
    DataSource: '/Api/ProfileDataSource',
}

class SFProfileApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getProfileList = async (profileType: ProfileModuleTypes): Promise<Array<Profile>> =>
        (await this.axios.get(this.baseUrl + `${profileTypeToApiMap[profileType]}`, this.getConfig()))
            .data as Array<Profile>

    deleteProfile = async (profileType: ProfileModuleTypes, profileIds: string[]) => {
        await this.axios.delete(this.baseUrl + `${profileTypeToApiMap[profileType]}`, profileIds, this.getConfig())
    }

    getProfile = async (id: string, profileType: ProfileModuleTypes): Promise<Profile> => {
        return (await this.axios.get(this.baseUrl + `${profileTypeToApiMap[profileType]}/${id}`, this.getConfig())).data
    }

    createProfile = async (profile: Profile, profileType: ProfileModuleTypes) => {
        const profileForCreation = { ...profile, id: NIL }
        await this.axios.post(
            `${this.baseUrl}${profileTypeToApiMap[profileType]}`,
            profileForCreation,
            this.getConfig(),
        )
    }

    updateProfile = async (profile: Profile, profileType: ProfileModuleTypes) => {
        return (await this.axios.put(`${this.baseUrl}${profileTypeToApiMap[profileType]}`, profile, this.getConfig()))
            .data
    }

    copyProfile = async (id: string, profileType: ProfileModuleTypes) => {
        return (
            await this.axios.get(
                `${this.baseUrl}${profileTypeToApiMap[profileType]}/CreateEntity/${id}`,
                this.getConfig(),
            )
        ).data
    }

    getDefaultProfile = async (profileType: ProfileModuleTypes): Promise<Profile> => {
        return (
            await this.axios.get(`${this.baseUrl}${profileTypeToApiMap[profileType]}/CreateEntity/`, this.getConfig())
        ).data
    }

    setDefaultProfile = async (id: string, profileType: ProfileModuleTypes) => {
        await this.axios.put(
            `${this.baseUrl}${profileTypeToApiMap[profileType]}/SetDefault/${id}`,
            {},
            this.getConfig(),
        )
    }
}

export default SFProfileApi
