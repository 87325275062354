/**
 * Responsible for talking to the back-end API (web or SFC)
 */

import SessionControl from 'services/axios/SessionControl'
import { AuthToken } from 'types/AuthToken'
import { EventFilterConfig } from 'types/EventFilterConfig'
import { GridLayout, GridLayoutJson } from 'types/GridLayout'
import {
    AccountLicense,
    FileProcessing,
    parseFileProcessing,
    parseUser,
    ScheduleDetailsViewMode,
    SleepQualityEnum,
    TimeModeEnum,
    User,
    UserEditable,
    ViewSettings,
} from 'types/interfaces'
import MetaData, { parseMetadata } from 'types/Metadata'
import { ReportItemDefinitionDataType } from 'types/ReportingMetadata'
import { ResponseMessage } from 'types/ResponseMessage'
import { parseScenarioParameters, ScenarioParameters } from 'types/Scenario'
import Schedule, {
    CreateEventsSchedule,
    CreateUpdateShiftsSchedule,
    parseSchedule,
    ScheduleWithEvents,
} from 'types/Schedule'
import ScheduleEvent, { ScheduleEventRecord, TagKeyValue } from 'types/ScheduleEvent'
import { ShareType, ShareUsersConfiguration } from 'types/ShareUsersConfiguration'
import { parseShift, PatternsShifts } from 'types/Shifts'
import { UpdateDutyRequest } from 'types/UpdateDutyRequest'
import { UpdateDutyTimeRequest } from 'types/UpdateDutyTimeRequest'
import Axios from '../axios/axios-sfc'
import * as sessionToken from './sessionToken'
import SFApiBase from './sfApiBase'
import SFProcessingApi from './sfProcessingApi'
import SFProfileApi from './sfProfileApi'
import SFReportingApi from './sfReportingApi'
import SFScenarioApi from './sfScenarioApi'
import SFStationsApi from './sfStationsApi'
import SFSystemSettingApi from './sfSystemSettingApi'
import SFTagsApi from './sfTagsApi'
import SFTemplateApi from './sfTemplateApi'

const convertEventForServer = (eventRecord: ScheduleEventRecord): any => {
    const getSleepQualityStringOrNull = (value: SleepQualityEnum | null): string | null => {
        if (!value) {
            return null
        }

        return SleepQualityEnum[value].toString()
    }

    return {
        ...eventRecord,
        quality: getSleepQualityStringOrNull(eventRecord.quality),
        plannedWorkSleepQuality: getSleepQualityStringOrNull(eventRecord.plannedWorkSleepQuality),
    }
}

const convertScheduleForServer = (schedule: ScheduleWithEvents): any => {
    // this gets rid of some content from the schedule object
    // filter out autosleep, duty rollupts, etc.
    const scheduleForServer = { ...schedule }
    scheduleForServer.events = [...scheduleForServer.events]
        .filter((x) => !x.isGeneratedEvent())
        .map(convertEventForServer)
    return scheduleForServer
}

type UpdatePatternsShifts = Omit<PatternsShifts, 'shiftTypeList'>

class SFApi extends SFApiBase {
    private readonly reportingApi: SFReportingApi
    private readonly stationsApi: SFStationsApi
    private readonly processingApi: SFProcessingApi
    private readonly systemSettingsApi: SFSystemSettingApi
    private readonly profileApi: SFProfileApi
    private readonly templateApi: SFTemplateApi
    private readonly sessionControl: SessionControl
    private readonly scenarioApi: SFScenarioApi
    private readonly tagsApi: SFTagsApi

    constructor(private axios: Axios) {
        super()
        this.axios = axios
        this.sessionControl = axios.getSessionControl()
        this.reportingApi = new SFReportingApi(this.axios)
        this.stationsApi = new SFStationsApi(this.axios)
        this.processingApi = new SFProcessingApi(this.axios)
        this.systemSettingsApi = new SFSystemSettingApi(this.axios)
        this.profileApi = new SFProfileApi(this.axios)
        this.templateApi = new SFTemplateApi(this.axios)
        this.scenarioApi = new SFScenarioApi(this.axios)
        this.tagsApi = new SFTagsApi(this.axios)
    }

    getReportingApi = () => this.reportingApi
    getStationsApi = () => this.stationsApi
    getProcessingApi = () => this.processingApi
    getSystemSettingApi = () => this.systemSettingsApi
    getProfileApi = () => this.profileApi
    getTemplateApi = () => this.templateApi
    getScenarioApi = () => this.scenarioApi
    getTagsApi = () => this.tagsApi

    updateUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.put(this.baseUrl + '/Api/Users/', user, this.getConfig())).data
        return parseUser(data)
    }

    createUser = async (user: UserEditable): Promise<User> => {
        const data = (await this.axios.post(this.baseUrl + '/Api/Users/', user, this.getConfig())).data
        return parseUser(data)
    }

    passwordResetRequest = async (loginId: string): Promise<void> => {
        const formData = new FormData()
        formData.append('loginId', loginId)
        await this.axios.post(this.baseUrl + '/Api/Users/PasswordResetRequest', formData, this.getConfig())
    }

    validatePasswordResetToken = async (token: string): Promise<void> => {
        await this.axios.get(this.baseUrl + `/Api/Users/PasswordReset?tokenValue=${token}`, this.getConfig())
    }

    setNewPassword = async (token: string, password1: string, password2: string): Promise<void> => {
        const formData = new FormData()
        formData.append('token', token)
        formData.append('pass1', password1)
        formData.append('pass2', password2)
        await this.axios.post(this.baseUrl + '/Api/Users/PasswordReset', formData, this.getConfig())
    }

    /**
     * Get PatternsShifts for the dialog.
     * @returns
     */
    getPatternsShifts = async (): Promise<PatternsShifts> => {
        const patternsShifts = (await this.axios.get(this.baseUrl + '/Api/Shifts/GetPatternsShifts/', this.getConfig()))
            .data as PatternsShifts
        patternsShifts.patterns.forEach((pattern) => {
            pattern.shifts = pattern.shifts.map((shift) => {
                return parseShift(shift)
            })
        })
        patternsShifts.shiftSegments = patternsShifts.shiftSegments.map((shiftSeg) => parseShift(shiftSeg))
        return patternsShifts
    }

    deleteEvent = async (
        schedule: Schedule,
        scheduleEvents: ScheduleEvent[],
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/DeleteEvent',
                {
                    scheduleId: schedule.id,
                    eventUuids: scheduleEvents.map((x) => x.uuid),
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    updateScheduleWithEvents = async (schedule: ScheduleWithEvents): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/UpdateAndReanalyzeSchedule',
                {
                    schedule: convertScheduleForServer(schedule),
                    hasNewDuty: false,
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    getScheduleParameters = async (scheduleId: number): Promise<ScenarioParameters> => {
        const responseData = (
            await this.axios.get(this.baseUrl + `/Api/Schedules/${scheduleId}/Parameters`, this.getConfig())
        ).data
        return parseScenarioParameters(responseData)
    }

    updateScheduleParameters = async (
        scheduleId: number,
        parameters: ScenarioParameters,
    ): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (
            await this.axios.put(this.baseUrl + `/Api/Schedules/${scheduleId}/Parameters`, parameters, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    resetEditedSleep = async (scheduleId: number): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(
                this.baseUrl + `/Api/Schedules/${scheduleId}/ResetExplicitSleep`,
                {},
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    addEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[Schedule, ResponseMessage?]> => {
        // server doesn't accept "Duty" as an event type, so we need to switch it here before sending.
        const isDuty = scheduleEvent.type === 'Duty'
        if (isDuty) {
            scheduleEvent.type = 'Work'
        }

        const autoEventsState = isDuty ? 'NotCreated' : 'Deleted'
        const postData = {
            dutyItemState: [
                {
                    state: autoEventsState,
                    workType: 'Preparation',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteToWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Brief',
                },
                {
                    state: autoEventsState,
                    workType: 'Debrief',
                },
                {
                    state: autoEventsState,
                    workType: 'CommuteFromWork',
                },
                {
                    state: autoEventsState,
                    workType: 'Unwind',
                },
            ],
            event: convertEventForServer(scheduleEvent),
        }
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/CreateEvent', postData, this.getConfig())
        ).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editEvent = async (scheduleEvent: ScheduleEventRecord): Promise<[Schedule, ResponseMessage?]> => {
        // need to change enum values to strings
        const postData = convertEventForServer(scheduleEvent)
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditEvent/', postData, this.getConfig())
        ).data as any
        const parsedSchedule = parseSchedule(responseData.schedule)
        return [parsedSchedule, responseData.message]
    }

    editDuty = async (request: UpdateDutyRequest): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditDuty', request, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    setDutyTime = async (request: UpdateDutyTimeRequest): Promise<[Schedule, ResponseMessage?]> => {
        const responseData = (
            await this.axios.post(this.baseUrl + '/Api/Schedules/EditDutyDates', request, this.getConfig())
        ).data as any
        return [parseSchedule(responseData.schedule), responseData.message]
    }

    deleteSchedules = async (scenarioId: number, scheduleIds: number[]): Promise<void> => {
        await this.axios.delete(
            this.baseUrl + '/Api/Scenarios/Schedules/',
            { scenarioId, scheduleIds },
            this.getConfig(),
        )
    }

    duplicateSchedule = async (
        scheduleId: number,
        isSaveAs: boolean,
        name: string,
        newScenarioId: number,
        newScenarioName: string,
    ): Promise<number> => {
        const response = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/DuplicateSchedule/',
                {
                    scheduleId,
                    isSaveAs,
                    name,
                    newScenarioName,
                },
                this.getConfig(),
            )
        ).data
        return response.id as number
    }

    saveDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/SaveDraft/',
                {
                    draftScheduleId: scheduleId,
                    scheduleModified: lastModified,
                },
                this.getConfig(),
            )
        ).data as any

        return [parseSchedule(data.schedule), data.message]
    }

    discardDraftSchedule = async (scheduleId: number, lastModified: Date): Promise<void> => {
        await this.axios.post(
            this.baseUrl + '/Api/Schedules/DiscardDraft/',
            {
                draftScheduleId: scheduleId,
                scheduleModified: lastModified,
            },
            this.getConfig(),
        )
    }

    getScheduleWithDetails = async (scheduleId: number): Promise<[Schedule, ResponseMessage?]> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Schedules/${scheduleId}`, this.getConfig())).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    saveScheduleSettingsForAnalysis = async (
        scheduleId: number,
        scheduleName: string,
        scheduleBase: string,
        scheduleTags: TagKeyValue[],
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.put(
                this.baseUrl + '/Api/Schedules/UpdateScheduleSettingsForAnalysis',
                {
                    scheduleId,
                    scheduleName,
                    scheduleBase,
                    scheduleTags,
                },
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    saveScheduleViewSettings = async (scheduleId: number, scheduleViewSettings: ViewSettings) => {
        const viewSettings = {
            ...scheduleViewSettings,
            timeMode: TimeModeEnum[scheduleViewSettings.timeMode].toString(),
            viewMode: ScheduleDetailsViewMode[scheduleViewSettings.viewMode].toString(),
        }
        await this.axios.put(
            this.baseUrl + '/Api/Schedules/UpdateScheduleViewSettings',
            {
                scheduleId,
                scheduleViewSettings: viewSettings,
            },
            this.getConfig(),
        )
    }

    updateLastViewed = async (id: number): Promise<void> => {
        await this.axios.put(
            this.baseUrl + '/Api/Schedules/UpdateLastViewed/',
            {
                id,
            },
            this.getConfig(),
        )
    }

    createNewEventsSchedule = async (createSchedule: CreateEventsSchedule): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Schedules/CreateNewEventsSchedule',
                createSchedule,
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    createNewShiftsSchedule = async (
        createSchedule: CreateUpdateShiftsSchedule,
    ): Promise<[Schedule, ResponseMessage?]> => {
        const data = (
            await this.axios.post(
                this.baseUrl + '/Api/Shifts/CreateOrUpdateShiftsSchedule',
                createSchedule,
                this.getConfig(),
            )
        ).data as any
        return [parseSchedule(data.schedule), data.message]
    }

    updatePatternsShifts = async (patternsShifts: UpdatePatternsShifts): Promise<PatternsShifts> => {
        await this.axios.post(this.baseUrl + '/Api/Shifts/UpdatePatternsShifts', patternsShifts, this.getConfig())
        // api should be fixed to actually return the updated object with proper ids, but since it doesn't, just call again.
        return this.getPatternsShifts()
    }

    getUsers = async (): Promise<Array<User>> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Users', this.getConfig())).data as Array<any>
        return data.map<User>(parseUser)
    }

    getUserSharing = async (itemIds: number[], itemType: ShareType): Promise<ShareUsersConfiguration> => {
        const data = (
            await this.axios.get(
                this.baseUrl + `/Api/Sharing/ItemShareUsers?itemIds=${itemIds.join(',')}&itemType=${itemType}`,
                this.getConfig(),
            )
        ).data
        return data
    }

    updateUserSharing = async (request: ShareUsersConfiguration): Promise<void> => {
        await this.axios.post(this.baseUrl + '/Api/Sharing/UpdateUserSharing/', request, this.getConfig())
    }

    getFileProcessingRecords = async (scenarioId?: number): Promise<Array<FileProcessing>> => {
        let url = this.baseUrl + '/Api/FileProcessing/'
        if (scenarioId) {
            url += scenarioId.toString()
        }
        const data = (await this.axios.get(url, this.getConfig())).data as Array<any>
        return data.map<FileProcessing>(parseFileProcessing)
    }

    getMetadata = async (): Promise<MetaData> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Metadata', this.getConfig())).data
        return parseMetadata(data)
    }

    updateGridLayout = async (gridLayoutId: string, gridLayoutJson: string): Promise<void> => {
        await this.axios.put(
            `${this.baseUrl}/Api/GridLayouts/Update/${gridLayoutId}`,
            { gridLayoutJson },
            this.getConfig(),
        )
    }

    saveGridLayoutAs = async (gridLayoutId: string, newName: string): Promise<void> => {
        await this.axios.post(
            `${this.baseUrl}/Api/GridLayouts/SaveAs`,
            { originalId: gridLayoutId, name: newName },
            this.getConfig(),
        )
    }

    setActiveGridLayout = async (gridLayoutId: string): Promise<void> => {
        await this.axios.post(`${this.baseUrl}/Api/GridLayouts/Activate/${gridLayoutId}`, {}, this.getConfig())
    }

    deleteGridLayout = async (gridLayoutId: string): Promise<void> => {
        await this.axios.delete(`${this.baseUrl}/Api/GridLayouts/Delete/${gridLayoutId}`, {}, this.getConfig())
    }

    getGridLayouts = async (): Promise<GridLayout[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/GridLayouts', this.getConfig())).data

        const gridLayouts = (data as any[]).map((x: any) => {
            const config = JSON.parse(x.configurationJson) as GridLayoutJson
            return {
                ...x,
                configurationJson: config,
            } as GridLayout
        })

        return gridLayouts
    }

    getAppVersion = async (): Promise<string> => {
        try {
            return (await this.axios.get(this.baseUrl + '/Api/Metadata/AppVersion', this.getConfig())).data
        } catch {
            return ''
        }
    }

    getActiveUsersAndLicenseLimit = async (): Promise<AccountLicense> => {
        return (await this.axios.get(this.baseUrl + '/Api/Users/ActiveUsersAndLicenseLimit', this.getConfig())).data
    }

    signOut = async (): Promise<void> => {
        try {
            const toke = sessionToken.getSessionToken()
            this.sessionControl.setIsLoggedIn(false)
            sessionToken.deleteSessionToken()
            if (toke) {
                await this.axios.post(
                    this.baseUrl + '/Api/Users/LoggedOut',
                    {},
                    {
                        headers: {
                            Authorization: toke,
                        },
                    },
                )
            }
        } catch (err) {
            if (!String(err).includes('Your session in SAFTE-FAST has expired')) {
                throw err
            }
        }
    }

    authenticateCredentials = async (
        emailAddress: string,
        password: string,
        keepMeLoggedIn: boolean,
    ): Promise<AuthToken> => {
        try {
            const formData = new FormData()
            formData.append('email', emailAddress)
            formData.append('pass', password)
            formData.append('keepMeLoggedIn', keepMeLoggedIn.toString())
            const response = await this.axios.post(
                this.baseUrl + '/Api/Users/AuthenticateCredentials',
                formData,
                this.getConfig(),
            )

            const authToken = response.data as AuthToken
            sessionToken.setSessionToken(authToken.tokenValue)
            this.sessionControl.setIsLoggedIn(true)
            return authToken
        } catch (err) {
            sessionToken.deleteSessionToken()
            throw err
        }
    }

    authenticateToken = async (token: string): Promise<AuthToken | false> => {
        try {
            const tokenObject = await (
                await this.axios.get(this.baseUrl + `/Api/Users/AuthenticateToken?token=${token}`, this.getConfig())
            ).data
            this.sessionControl.setIsLoggedIn(true)
            return tokenObject
        } catch (error) {
            sessionToken.deleteSessionToken()
            return false
        }
    }

    getEventsFilterConfig = async (): Promise<EventFilterConfig> => {
        const config = (await this.axios.get(this.baseUrl + '/Api/Parameters/EventsFilterConfig', this.getConfig()))
            .data as EventFilterConfig
        config.configItems.forEach((x) => {
            x.type = x.type.toLowerCase() as ReportItemDefinitionDataType
        })
        return config
    }
}

export default SFApi
